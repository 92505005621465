import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tuercacoronae',
  templateUrl: './tuercacoronae.component.html',
  styleUrls: ['./tuercacoronae.component.scss']
})
export class TuercacoronaeComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Tuerca De Corona Rosca Extrafina - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'tuerca de corona rosca extrafina'}     
    ])

  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
