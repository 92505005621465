import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms'
import { MatDialog } from '@angular/material/dialog';
import { DialogoComponent } from './../dialogo/dialogo.component';
import { MenuComponent } from './../menu/menu.component';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.scss']
})
export class ProductosComponent implements OnInit{

  
  
  numero: any;
  public productosnombre = [];
  a2 : boolean;
  a3 : boolean;
  a4 : boolean;
  a5 : boolean;
  a6 : boolean;
  a7 : boolean;
  

  producto: boolean;
  productoe: boolean;
  buscador: boolean;
  public buscar: FormGroup;
  name:string;
  elemtos : string;
  bs : any;
  i: any;
  a: any;
  cont: any;
  sum: any;
  cards: any;
  sheets: boolean;
  sheets1: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public dialogo: MatDialog,
    public chat: MenuComponent,
    private router : Router,
    private meta: Meta, 
    private title: Title

  ) { 

    this.title.setTitle('Productos - La Casa Del Tornillo S.A.');
   
    this.meta.addTags([
      {name: 'keywords', content: 'productos la casa del tornillo'}
    ]);


    this.producto = true;
    this.productoe = false;
    this.buscador = true;
    this.sum = 0;
    this.sheets = true;
    this.sheets1 = false;
    this.numero = 0;
    router.events.subscribe((val) => {
      this.paginaproductos();
    });
    this.a2 = true;
    this.a3 = true;
    this.a4 = true;
    this.a5 = true;
    this.a6 = true;
    this.a7 = true;
  }

  mostrarpages(){
    if(isPlatformBrowser(this.platformId)){
    this.sheets1 = true;
    if (this.sheets1){
      document.getElementById("pestañas").style.display= "none";
    }
    }
  }

  mostrarpages1(){
    this.sheets = true;
    this.sheets1 = false;
  }

  vistaamericano(){
    this.router.navigate(['/tornillo/productos/tornillo-americano']);
    this.producto = false;
    this.productoe = true;
  }

  vistametrico(){
    this.router.navigate(['/tornillo/productos/tornillo-metrico']);
    this.producto = false;
    this.productoe = true;
  }

  vistae(){
    this.producto = true;
    this.productoe = false;
    this.mostrartodo();
    
  }
  

  borrar(){
    if(isPlatformBrowser(this.platformId)){
    //compara si el input de texto esta vacio 
    if (this.name != null){
      const insert = document.getElementById("busqueda");
      
      //La variable i contiene el numero de productos devueltos
      if (this.i != 0){
        const ins = document.getElementById("resultado")
        ins.innerHTML = "";
        //ciclo que quita todos los elementos de la pantalla resultado
        for (this.a=0; this.a<=this.i; this.a++){
          const all = document.getElementById("all");
          insert.appendChild(all);
        }
        ins.innerHTML = "";
      
      }else{
        
        if (this.sum == 0){
          const ins = document.getElementById("resultado");
          ins.innerHTML = "Sin Resultados...";
        }
      }
      
        this.Buscar();
      }else{
        alert('Debe ingresar un texto a buscar');
      }
      }

    }



  Buscar(){
    if(isPlatformBrowser(this.platformId)){
    //referencia al div resultado
     const insert = document.getElementById("resultado");
    //oculta el div todo 
     
     
     console.log(this.name);
     
    //comvierte a minusculas la cadena de texto del input 
     this.elemtos = this.name.toLowerCase();
     this.name = null;

    //referencia a todos los documentos con nombre all 
     const move = document.getElementsByName("all");
     
    //separa la cadena en palabras
     const prod = this.elemtos;
     const prue = prod.split(' ');
     const nump = prue.length;
     console.log(nump);
     
    if (nump==2){
      
      move.forEach(element => {
        
        const comp = element.textContent.toLowerCase();
        const ll = comp.indexOf(prue[0]);
        const tt = comp.indexOf(prue[1]);
        if (ll != -1 && tt != -1){
          insert.appendChild(element);
          this.sum = this.sum + 1;
        }
        
      });
    }else{
    
     //recorrido de los elementos recuperodos con nombre all 
      move.forEach(element => {
        //comvierte a minuscula el texto de los elementos y 
        //luego busca en el texto las palabras ingresadas en el input
        const comp = element.textContent.toLowerCase();       
        const ll = comp.indexOf(this.elemtos);
        
        //si encuentra una palabra en el texto,
        //inserta el elemento en el div "resultados"
        if (ll != -1){
          insert.appendChild(element);
          this.sum = this.sum + 1;
        }
       
      })

    }
      if (this.sum == 0){
        insert.innerText = "Sin resultados...";
      }
     
     this.i = move.length;
    }
  }


  mostrarDialogo(): void {
    if(isPlatformBrowser(this.platformId)){
    this.dialogo
      .open(DialogoComponent, {
        data: `¿Quieres consultar sobre esté producto?`
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.chat.mostrarform();
        } else {
          console.log("ok");
        }
      });
    }
  }

  buscarprod(){
    if(isPlatformBrowser(this.platformId)){
    if (this.name==null){
      alert("Debe ingresar un texto a buscar")
    }else{
    this.sheets = false;
    this.sheets1 = true;
    this.vistabusqueda();
    }
    }
  }

  vistabusqueda(){
    this.router.navigate(['tornillo/productos/buscar'])
  }

  mostrartodo(){
    if(isPlatformBrowser(this.platformId)){
    this.router.navigate(['/tornillo/productos']);

      this.sheets = true;
      this.sheets1 = false;
      document.getElementById("pestañas").style.display = "block";
      this.name= null;
    }
  }

  prueba1(){
    
    if(isPlatformBrowser(this.platformId)){

    const productos = document.getElementsByName("all");
    const tamaño = productos.length;
    productos.forEach(nprod => {

      this.productosnombre[this.numero] = nprod.textContent
      this.numero = this.numero + 1;
    });
    this.productosnombre.sort();
    for (this.a=-1; this.a<=tamaño; this.a++){
      productos.forEach(element => {
        if (this.a>-1 && this.a<=59){
          const lista1 = document.getElementById("uno");
          const nodo = element.textContent.indexOf(this.productosnombre[this.a]);
          if (nodo != -1){
            //this.productospag1[this.a] = element;
            lista1.appendChild(element);
                   
          }
        }

        if (this.a>59 && this.a<=155){
          const lista2 = document.getElementById("dos");
          const nodo = element.textContent.indexOf(this.productosnombre[this.a]);
          if (nodo != -1){
            //this.productospag2[this.a] = element;
            lista2.appendChild(element);
          }
          if (this.a == 155){
            this.a2 = false;
          }
        }

        if (this.a>155 && this.a<=251){
          const lista3 = document.getElementById("tres");
          const nodo = element.textContent.indexOf(this.productosnombre[this.a]);
          if (nodo != -1){
            //this.productospag3[this.a] = element;
            lista3.appendChild(element);
          }
          if (this.a == 2521){
            this.a3 = false;
          }
        }

        if (this.a>251 && this.a<=347){
          const lista4 = document.getElementById("cuatro");
          const nodo = element.textContent.indexOf(this.productosnombre[this.a]);
          if (nodo != -1){
            //this.productospag4[this.a] = element;
            lista4.appendChild(element);
          }
          if (this.a == 3487){
            this.a4 = false;
          }
        }

        if (this.a>347 && this.a<=443){
          const lista5 = document.getElementById("cinco");
          const nodo = element.textContent.indexOf(this.productosnombre[this.a]);
          if (nodo != -1){
            //this.productospag5[this.a] = element;
            lista5.appendChild(element);
          }
          if (this.a == 443){
            this.a5 = false;
          }
        }

        if (this.a>443 && this.a<=539){
          const lista6 = document.getElementById("seis");
          const nodo = element.textContent.indexOf(this.productosnombre[this.a]);
          if (nodo != -1){
            //this.productospag6[this.a] = element;
            lista6.appendChild(element);
          }
          if (this.a == 539){
            this.a6 = false;
          }
        }

        if (this.a>539 && this.a<=635){
          const lista7 = document.getElementById("siete");
          const nodo = element.textContent.indexOf(this.productosnombre[this.a]);
          if (nodo != -1){
            //this.productospag7[this.a] = element;
            lista7.appendChild(element);
            
          }
          if (this.a == 635){
            this.a7 = false;
          }
        }
      });
    }
  }
  }

  pag2(){
    if(isPlatformBrowser(this.platformId)){
    document.getElementById("dos").style.display = "block";
    this.a2=true;
    }
  }

  pag3(){
    if(isPlatformBrowser(this.platformId)){
    document.getElementById("tres").style.display = "block";
    this.a3=true;
    }
  }
  pag4(){
    if(isPlatformBrowser(this.platformId)){
    document.getElementById("cuatro").style.display = "block";
    this.a4=true;
    }
  }
  pag5(){
    if(isPlatformBrowser(this.platformId)){
    document.getElementById("cinco").style.display = "block";
    this.a5=true;
    }
  }
  pag6(){
    if(isPlatformBrowser(this.platformId)){
    document.getElementById("seis").style.display = "block";
    this.a6=true;
    }
  }
  pag7(){
    if(isPlatformBrowser(this.platformId)){
    document.getElementById("siete").style.display = "block";
    this.a7=true;
    }
  }

  ngAfterViewInit():void{

    this.prueba1();
  }

  ngOnInit(): void {
    
  }

  paginaproductos(){
    if(isPlatformBrowser(this.platformId)){
    const ruta = this.router.url;
    if (ruta == '/tornillo/productos') {
      this.sheets = true;
      this.sheets1 = false;
      document.getElementById("pestañas").style.display = "block";
    }else{
      this.mostrarpages();
    };
  }
  }

  Detvista(){
    if(isPlatformBrowser(this.platformId)){
    const resolucion = screen.width;
    console.log(screen.height);
    if (resolucion < 600){
      window.scroll({
        top: 800
      })
    }
  }
  }

  mostrarpagest(){
    if(isPlatformBrowser(this.platformId)){
    this.sheets1 = true;
    if (this.sheets1){
      document.getElementById("pestañas").style.display= "none";
    }
    this.Detvista();
    }
  }

}
