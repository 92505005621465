import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-segurosujecionanillo',
  templateUrl: './segurosujecionanillo.component.html',
  styleUrls: ['./segurosujecionanillo.component.scss']
})
export class SegurosujecionanilloComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) {

    this.title.setTitle('Seguro De Sujecion Tipo Anillo - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'seguro de sujecion tipo anillo'}     
    ]);

   }

   mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
