import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-roldanacobre',
  templateUrl: './roldanacobre.component.html',
  styleUrls: ['./roldanacobre.component.scss']
})
export class RoldanacobreComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) {

    this.title.setTitle('Roldana De Cobre Para Sellar - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'roldana de cobre para sellar'}     
    ]);

   }

   mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
