import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductosService {

  constructor(private _http: HttpClient) { }

  getproductos(body) {
    return this._http.post('http://localhost/casator/productos.php', JSON.stringify(body), {responseType: 'text'})
  }
}
