import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tuercaseguridadgc',
  templateUrl: './tuercaseguridadgc.component.html',
  styleUrls: ['./tuercaseguridadgc.component.scss']
})
export class TuercaseguridadgcComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Tuerca De Seguro De Golpe Corona- La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'tuerca de seguro de golpe corona'}     
    ])

  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
