import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { MenuComponent } from './../menu/menu.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogoComponent } from './../dialogo/dialogo.component';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-nproducto',
  templateUrl: './nproducto.component.html',
  styleUrls: ['./nproducto.component.scss']
})
export class NproductoComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public dialogo: MatDialog,
    public chat: MenuComponent,
    private meta: Meta,
    private title: Title
  ) {


    this.title.setTitle('Nuevos Productos - La Casa Del Tornillo S.A.');

    this.meta.addTags([
      {name: 'keywords', content: 'nuevos productos la casa del tornillo'}
    ]);

    this.subir();
  }

  mostrarDialogo(): void {
    if(isPlatformBrowser(this.platformId)){
    this.dialogo
      .open(DialogoComponent, {
        data: `¿Quieres consultar sobre esté producto?`
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.chat.mostrarform();
        } else {
          //console.log(("ok");
        }
      });
    }
  }

  subir(){
    if(isPlatformBrowser(this.platformId)){
    window.scroll({
      top: 0
    })
    }
  }

  ngOnInit(): void {
  }

}
