import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-roldanainoxidable316',
  templateUrl: './roldanainoxidable316.component.html',
  styleUrls: ['./roldanainoxidable316.component.scss']
})
export class Roldanainoxidable316Component implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) {

    this.title.setTitle('Roldana Inoxidable 316 - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'Roldana inoxidable 316'}     
    ]);

   }

   mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
