import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-hexagonalinoxa4rc',
  templateUrl: './hexagonalinoxa4rc.component.html',
  styleUrls: ['./hexagonalinoxa4rc.component.scss']
})
export class Hexagonalinoxa4rcComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Tornillo Hexagonal Inoxidable A4 Rosca Corrida - La Casa Del Tornillo S.A.');
   
    this.meta.addTags([
      {name: 'keywords', content: 'tornillo hexagonal inoxidable a4 rosca corrida'}
    ]);

  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
