import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-esparragoexdpsi',
  templateUrl: './esparragoexdpsi.component.html',
  styleUrls: ['./esparragoexdpsi.component.scss']
})
export class EsparragoexdpsiComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Esparrago Extrapesado Doble Punta Sobre Medida Izquierdo - La Casa Del Tornillo S.A.');
   
    this.meta.addTags([
      {name: 'keywords', content: 'esparrago extrapesado doble punta sobre medida izquierdo'}
    ]);

  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
