import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-roldanainoxidablea4',
  templateUrl: './roldanainoxidablea4.component.html',
  styleUrls: ['./roldanainoxidablea4.component.scss']
})
export class Roldanainoxidablea4Component implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Roldana Inoxidable A4 - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'Roldana inoxidable A4'}     
    ]);

  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
