import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { ProductosComponent } from './../../productos.component';
import { isPlatformBrowser } from '@angular/common';
import { ProductosService } from '../../../../services/productos.service';

@Component({
  selector: 'app-resortes',
  templateUrl: './resortes.component.html',
  styleUrls: ['./resortes.component.scss']
})
export class ResortesComponent implements OnInit {

  public productosnombre = [];
  numero: any;
  a: any;
  dataproductos: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public dialogo : ProductosComponent,
    private productos : ProductosService
  ) { 
    this.numero = 0;
  }

  //llama al metodo que muestra el dialogo emergente
  Mostrar(){
    this.dialogo.mostrarDialogo();
  }

  //ordena los elementos por orden alfabetico
  prueba(){
    if(isPlatformBrowser(this.platformId)){
    const productos = document.getElementsByName("resorte");
    const lista = document.getElementById("todoresortes");
    const tamaño = productos.length;
    productos.forEach(nprod => {

      this.productosnombre[this.numero] = nprod.textContent;
      this.numero = this.numero + 1;
    });
    this.productosnombre.sort();
    for (this.a=0; this.a<=tamaño; this.a++){
      productos.forEach(element => {
        const nodo = element.textContent.indexOf(this.productosnombre[this.a]);
        if (nodo != -1){
          lista.appendChild(element);
        }
      });
    }
  }
  }

  ngAfterViewInit(): void {
    //this.prueba();
  }

  ngOnInit(): void {
    /*const body = {
      especificacion : 'j'
    }
    this.productos.getproductos(body).subscribe(data => {
      this.dataproductos = JSON.parse(data) 
      console.log(this.dataproductos)
    });*/

  }

}
