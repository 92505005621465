import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-cementoopba',
  templateUrl: './cementoopba.component.html',
  styleUrls: ['./cementoopba.component.scss']
})
export class CementoopbaComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) {

    this.title.setTitle('Tornillo Para Cemento Cabea Oblea Punta De Broca Con Alas - La Casa Del Tornillo S.A.');
    
    this.meta.addTags([
      {name: 'keywords', content: 'tornillo para cemento cabeza oblea punta de broca con alas'}
    ]);
   }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
