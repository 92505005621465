import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-segurosujecionranura',
  templateUrl: './segurosujecionranura.component.html',
  styleUrls: ['./segurosujecionranura.component.scss']
})
export class SegurosujecionranuraComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Seguro De Sujecion Sin Ranura - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'seguro de sujecion sin ranura'}     
    ]);

  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
