import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tablayesorpbg',
  templateUrl: './tablayesorpbg.component.html',
  styleUrls: ['./tablayesorpbg.component.scss']
})
export class TablayesorpbgComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Tornillo Tabla Yeso Redonda Punta De Broca Galvanizado - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'tornillo tabla yeso redonda punta de broca galvanizado'}     
    ]);

  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
