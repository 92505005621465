import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-allenci',
  templateUrl: './allenci.component.html',
  styleUrls: ['./allenci.component.scss']
})
export class AllenciComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 
    
    this.title.setTitle('Tornillo Allen Cilindrico Inoxidable - La Casa Del Tornillo S.A.');

    this.meta.addTags([
      {name: 'keywords', content: 'tornillo allen cilindrico Inoxidable'}
    ]);

  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
