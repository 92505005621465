import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-soldadurafrio',
  templateUrl: './soldadurafrio.component.html',
  styleUrls: ['./soldadurafrio.component.scss']
})
export class SoldadurafrioComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Soldadura en Frio - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'soldadura en frio'}     
    ]);
  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
