import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-cabezaplana-b',
  templateUrl: './cabezaplana-b.component.html',
  styleUrls: ['./cabezaplana-b.component.scss']
})
export class CabezaplanaBComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) {

    this.title.setTitle('Tornillo Cabeza Plana De Broce - La Casa Del Tornillo S.A.');
   
    this.meta.addTags([
      {name: 'keywords', content: 'tornillo cabeza plana de bronce'}
    ]);
   }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
