import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-roldanabiselada',
  templateUrl: './roldanabiselada.component.html',
  styleUrls: ['./roldanabiselada.component.scss']
})
export class RoldanabiseladaComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Roldana Biselada - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'roldana biselada'}     
    ]);

  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
