import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tuercacbre',
  templateUrl: './tuercacbre.component.html',
  styleUrls: ['./tuercacbre.component.scss']
})
export class TuercacbreComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) {

    this.title.setTitle('Tuerca Chucho Bolita Rosca Extrafina - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'tuerca chucho bolita rosca extrafina'}     
    ])

   }

    mostrar(){
    this.dialogo.mostrarDialogo();
  }
  

  ngOnInit(): void {
  }

}
