import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule, NoPreloading } from '@angular/router';
import { MenuComponent } from './pages/menu/menu.component';
import { SucursalesComponent } from './pages/sucursales/sucursales.component';
import { ContactenosComponent } from './pages/contactenos/contactenos.component'
import { InicioComponent } from './pages/inicio/inicio.component'
import { QuienesSomosComponent } from './pages/quienes-somos/quienes-somos.component';
import { ProductosModule } from './pages/productos/productos.module';
import { AllproductsComponent } from './pages/productos/sheets/allproducts/allproducts.component';
import { ServiciosComponent } from './pages/servicios/servicios.component'
import { NtiendaComponent } from './pages/ntienda/ntienda.component';
import { VideosComponent} from './pages/videos/videos.component';
import { NproductoComponent } from './pages/nproducto/nproducto.component';
import { CarreraComponent } from './pages/carrera/carrera.component';
import { AllenmComponent } from './pages/productos/sheets/allenm/allenm.component';
import { AutomotrizComponent } from './pages/productos/sheets/automotriz/automotriz.component';
import { AutomotrizmComponent } from './pages/productos/sheets/automotrizm/automotrizm.component';
import { BuscaRoscaComponent } from './pages/productos/sheets/busca-rosca/busca-rosca.component';
import { BuscaRoscamComponent } from './pages/productos/sheets/busca-roscam/busca-roscam.component';
import { DesarmadorComponent } from './pages/productos/sheets/desarmador/desarmador.component';
import { DesarmadormComponent } from './pages/productos/sheets/desarmadorm/desarmadorm.component';
import { EspecialedadesmComponent } from './pages/productos/sheets/especialedadesm/especialedadesm.component';
import { EspecialidadesComponent } from './pages/productos/sheets/especialidades/especialidades.component';
import { FijacionComponent } from './pages/productos/sheets/fijacion/fijacion.component';
import { HerramientasComponent } from './pages/productos/sheets/herramientas/herramientas.component';
import { PegamentosComponent } from './pages/productos/sheets/pegamentos/pegamentos.component';
import { PinesComponent } from './pages/productos/sheets/pines/pines.component';
import { ResortesComponent } from './pages/productos/sheets/resortes/resortes.component';
import { RoldanasComponent } from './pages/productos/sheets/roldanas/roldanas.component';
import { RoldanasmComponent } from './pages/productos/sheets/roldanasm/roldanasm.component';
import { SegurosComponent } from './pages/productos/sheets/seguros/seguros.component';
import { TorxComponent } from './pages/productos/sheets/torx/torx.component';
import { TuercasComponent } from './pages/productos/sheets/tuercas/tuercas.component';
import { TuercasmComponent } from './pages/productos/sheets/tuercasm/tuercasm.component';
import { WashasComponent } from './pages/productos/sheets/washas/washas.component';
import { WashasmComponent } from './pages/productos/sheets/washasm/washasm.component';
import { PaginacionComponent } from './pages/productos/sheets/paginacion/paginacion.component';
import { BuscarComponent } from './pages/productos/buscar/buscar.component';
import { AllencpComponent } from './pages/productos/pages/allencp/allencp.component';
import { AllencbComponent } from './pages/productos/pages/allencb/allencb.component';
import { AllencbrComponent } from './pages/productos/pages/allencbr/allencbr.component';
import { AllencbiComponent } from './pages/productos/pages/allencbi/allencbi.component';
import { AllenpacheComponent } from './pages/productos/pages/allenpache/allenpache.component';
import { AllenpacheiComponent } from './pages/productos/pages/allenpachei/allenpachei.component';
import { AllenpacherComponent } from './pages/productos/pages/allenpacher/allenpacher.component';
import { AllencComponent } from './pages/productos/pages/allenc/allenc.component';
import { Allenc12Component } from './pages/productos/pages/allenc12/allenc12.component';
import { AllenplanoComponent } from './pages/productos/pages/allenplano/allenplano.component';
import { Allenc8Component } from './pages/productos/pages/allenc8.8/allenc8.8.component';
import { Allencrc12Component } from './pages/productos/pages/allencrc12/allencrc12.component';
import { Allencrc8Component } from './pages/productos/pages/allencrc8/allencrc8.component';
import { AllenciComponent } from './pages/productos/pages/allenci/allenci.component';
import { AllencrciComponent } from './pages/productos/pages/allencrci/allencrci.component';
import { AllencfComponent } from './pages/productos/pages/allencf/allencf.component';
import { Allencf12Component } from './pages/productos/pages/allencf12/allencf12.component';
import { Allencf8Component } from './pages/productos/pages/allencf8/allencf8.component';
import { AllencfiComponent } from './pages/productos/pages/allencfi/allencfi.component';
import { AllenchComponent } from './pages/productos/pages/allench/allench.component';
import { AllenchfComponent } from './pages/productos/pages/allenchf/allenchf.component';
import { AllenmpComponent } from './pages/productos/pages/allenmp/allenmp.component';
import { AnclajecComponent } from './pages/productos/pages/anclajec/anclajec.component';
import { AnclajegComponent } from './pages/productos/pages/anclajeg/anclajeg.component';
import { AnclajeiComponent } from './pages/productos/pages/anclajei/anclajei.component';
import { AnilloajusteComponent } from './pages/productos/pages/anilloajuste/anilloajuste.component';
import { Barrab7Component } from './pages/productos/pages/barrab7/barrab7.component';
import { BarragComponent } from './pages/productos/pages/barrag/barrag.component';
import { BarragcComponent } from './pages/productos/pages/barragc/barragc.component';
import { BarraiComponent } from './pages/productos/pages/barrai/barrai.component';
import { Barram8Component } from './pages/productos/pages/barram8/barram8.component';
import { Barram10Component } from './pages/productos/pages/barram10/barram10.component';
import { BarramgComponent } from './pages/productos/pages/barramg/barramg.component';
import { BarramiComponent } from './pages/productos/pages/barrami/barrami.component';
import { BernierComponent } from './pages/productos/pages/bernier/bernier.component';
import { BerniereComponent } from './pages/productos/pages/berniere/berniere.component';
import { BirloComponent } from './pages/productos/pages/birlo/birlo.component';
import { AllencbsComponent } from './pages/productos/pages/allencbs/allencbs.component';
import { BrocacComponent } from './pages/productos/pages/brocac/brocac.component';
import { BrocalComponent } from './pages/productos/pages/brocal/brocal.component';
import { BrocalrComponent } from './pages/productos/pages/brocalr/brocalr.component';
import { BrocarComponent } from './pages/productos/pages/brocar/brocar.component';
import { BrocarcComponent } from './pages/productos/pages/brocarc/brocarc.component';
import { BrocarrComponent } from './pages/productos/pages/brocarr/brocarr.component';
import { BrocaUComponent } from './pages/productos/pages/broca-u/broca-u.component';
import { BrocacoComponent } from './pages/productos/pages/brocaco/brocaco.component';
import { CabezamariposaComponent } from './pages/productos/pages/cabezamariposa/cabezamariposa.component';
import { CabezaquesoComponent } from './pages/productos/pages/cabezaqueso/cabezaqueso.component';
import { CabezaquesoiComponent } from './pages/productos/pages/cabezaquesoi/cabezaquesoi.component';
import { Allenplano8Component } from './pages/productos/pages/allenplano8/allenplano8.component';
import { AllenplanoaComponent } from './pages/productos/pages/allenplanoa/allenplanoa.component';
import { CabezaplanaComponent } from './pages/productos/pages/cabezaplana/cabezaplana.component';
import { CabezaplanaBComponent } from './pages/productos/pages/cabezaplana-b/cabezaplana-b.component';
import { CabezaplanapComponent } from './pages/productos/pages/cabezaplanap/cabezaplanap.component';
import { CabezaplanapiComponent } from './pages/productos/pages/cabezaplanapi/cabezaplanapi.component';
import { AllenplanoinoxComponent } from './pages/productos/pages/allenplanoinox/allenplanoinox.component';
import { CabezaplanaifComponent } from './pages/productos/pages/cabezaplanaif/cabezaplanaif.component';
import { CabezaredondaComponent } from './pages/productos/pages/cabezaredonda/cabezaredonda.component';
import { CabezaredondabComponent } from './pages/productos/pages/cabezaredondab/cabezaredondab.component';
import { CabezaredondaiComponent } from './pages/productos/pages/cabezaredondai/cabezaredondai.component';
import { CabezaredondaifComponent } from './pages/productos/pages/cabezaredondaif/cabezaredondaif.component';
import { CajaplasticaComponent } from './pages/productos/pages/cajaplastica/cajaplastica.component';
import { CalibradorroscaComponent } from './pages/productos/pages/calibradorrosca/calibradorrosca.component';
import { CapiruchoComponent } from './pages/productos/pages/capirucho/capirucho.component';
import { CapiruchoiComponent } from './pages/productos/pages/capiruchoi/capiruchoi.component';
import { CastigadorallenComponent } from './pages/productos/pages/castigadorallen/castigadorallen.component';
import { CastigadoralleniComponent } from './pages/productos/pages/castigadoralleni/castigadoralleni.component';
import { CastigadorallenfComponent } from './pages/productos/pages/castigadorallenf/castigadorallenf.component';
import { CementooaComponent } from './pages/productos/pages/cementooa/cementooa.component';
import { CementoopaComponent } from './pages/productos/pages/cementoopa/cementoopa.component';
import { CementoopbComponent } from './pages/productos/pages/cementoopb/cementoopb.component';
import { CementoopbaComponent } from './pages/productos/pages/cementoopba/cementoopba.component';
import { CementopalaComponent } from './pages/productos/pages/cementopala/cementopala.component';
import { CementopasqComponent } from './pages/productos/pages/cementopasq/cementopasq.component';
import { ChavetaComponent } from './pages/productos/pages/chaveta/chaveta.component';
import { ChavetamComponent } from './pages/productos/pages/chavetam/chavetam.component';
import { ChavetamiComponent } from './pages/productos/pages/chavetami/chavetami.component';
import { ChuchocablComponent } from './pages/productos/pages/chuchocabl/chuchocabl.component';
import { ChuchocabpComponent } from './pages/productos/pages/chuchocabp/chuchocabp.component';
import { CincelesComponent } from './pages/productos/pages/cinceles/cinceles.component';
import { ConicoaComponent } from './pages/productos/pages/conicoa/conicoa.component';
import { ConoaComponent } from './pages/productos/pages/conoa/conoa.component';
import { ConomComponent } from './pages/productos/pages/conom/conom.component';
import { CopaesComponent } from './pages/productos/pages/copaes/copaes.component';
import { CopapbComponent } from './pages/productos/pages/copapb/copapb.component';
import { CunacComponent } from './pages/productos/pages/cunac/cunac.component';
import { CunaiComponent } from './pages/productos/pages/cunai/cunai.component';
import { CunamComponent } from './pages/productos/pages/cunam/cunam.component';
import { CunamlComponent } from './pages/productos/pages/cunaml/cunaml.component';
import { EmpaquenComponent } from './pages/productos/pages/empaquen/empaquen.component';
import { EmpaqueniComponent } from './pages/productos/pages/empaqueni/empaqueni.component';
import { EmpaquepComponent } from './pages/productos/pages/empaquep/empaquep.component';
import { Esparragoa2Component } from './pages/productos/pages/esparragoa2/esparragoa2.component';
import { Esparragoa4Component } from './pages/productos/pages/esparragoa4/esparragoa4.component';
import { Esparragoa8Component } from './pages/productos/pages/esparragoa8/esparragoa8.component';
import { EsparragoasComponent } from './pages/productos/pages/esparragoas/esparragoas.component';
import { EsparragoasfComponent } from './pages/productos/pages/esparragoasf/esparragoasf.component';
import { EsparragoasiComponent } from './pages/productos/pages/esparragoasi/esparragoasi.component';
import { EsparragoasoComponent } from './pages/productos/pages/esparragoaso/esparragoaso.component';
import { EsparragocaneroComponent } from './pages/productos/pages/esparragocanero/esparragocanero.component';
import { EsparragocbComponent } from './pages/productos/pages/esparragocb/esparragocb.component';
import { EsparragoclComponent } from './pages/productos/pages/esparragocl/esparragocl.component';
import { EsparragocsComponent } from './pages/productos/pages/esparragocs/esparragocs.component';
import { EsparragocsfComponent } from './pages/productos/pages/esparragocsf/esparragocsf.component';
import { EsparragoceComponent } from './pages/productos/pages/esparragoce/esparragoce.component';
import { EsparragocfComponent } from './pages/productos/pages/esparragocf/esparragocf.component';
import { EsparragodpComponent } from './pages/productos/pages/esparragodp/esparragodp.component';
import { EsparragodpfoComponent } from './pages/productos/pages/esparragodpfo/esparragodpfo.component';
import { EsparragodpooComponent } from './pages/productos/pages/esparragodpoo/esparragodpoo.component';
import { EsparragoexComponent } from './pages/productos/pages/esparragoex/esparragoex.component';
import { EsparragoexasComponent } from './pages/productos/pages/esparragoexas/esparragoexas.component';
import { EsparragoexasiComponent } from './pages/productos/pages/esparragoexasi/esparragoexasi.component';
import { EsparragoexdComponent } from './pages/productos/pages/esparragoexd/esparragoexd.component';
import { EsparragoexdpdComponent } from './pages/productos/pages/esparragoexdpd/esparragoexdpd.component';
import { EsparragoexdpdaComponent } from './pages/productos/pages/esparragoexdpda/esparragoexdpda.component';
import { EsparragoexdpiComponent } from './pages/productos/pages/esparragoexdpi/esparragoexdpi.component';
import { EsparragoexdpsiComponent } from './pages/productos/pages/esparragoexdpsi/esparragoexdpsi.component';
import { EsparragoexsdComponent } from './pages/productos/pages/esparragoexsd/esparragoexsd.component';
import { EsparragoexsmdComponent } from './pages/productos/pages/esparragoexsmd/esparragoexsmd.component';
import { EsparragodpffComponent } from './pages/productos/pages/esparragodpff/esparragodpff.component';
import { EsparragomdpfoComponent } from './pages/productos/pages/esparragomdpfo/esparragomdpfo.component';
import { EsparragomreComponent } from './pages/productos/pages/esparragomre/esparragomre.component';
import { EsparragomsmComponent } from './pages/productos/pages/esparragomsm/esparragomsm.component';
import { EsparragosmComponent } from './pages/productos/pages/esparragosm/esparragosm.component';
import { EsparragoasexComponent } from './pages/productos/pages/esparragoasex/esparragoasex.component';
import { EstufacpComponent } from './pages/productos/pages/estufacp/estufacp.component';
import { Estufacp12Component } from './pages/productos/pages/estufacp12/estufacp12.component';
import { EstufacprfComponent } from './pages/productos/pages/estufacprf/estufacprf.component';
import { EstufarComponent } from './pages/productos/pages/estufar/estufar.component';
import { EstufarcComponent } from './pages/productos/pages/estufarc/estufarc.component';
import { EstufarnComponent } from './pages/productos/pages/estufarn/estufarn.component';
import { EstufarrfComponent } from './pages/productos/pages/estufarrf/estufarrf.component';
import { ExtractorameComponent } from './pages/productos/pages/extractorame/extractorame.component';
import { ExtrctoruComponent } from './pages/productos/pages/extrctoru/extrctoru.component';
import { FerruleameComponent } from './pages/productos/pages/ferruleame/ferruleame.component';
import { FijadorespComponent } from './pages/productos/pages/fijadoresp/fijadoresp.component';
import { FijadormrComponent } from './pages/productos/pages/fijadormr/fijadormr.component';
import { FijadortpComponent } from './pages/productos/pages/fijadortp/fijadortp.component';
import { GrapasComponent } from './pages/productos/pages/grapas/grapas.component';
import { GrapascuadradasComponent } from './pages/productos/pages/grapascuadradas/grapascuadradas.component';
import { GraseramComponent } from './pages/productos/pages/graseram/graseram.component';
import { GraseramiComponent } from './pages/productos/pages/graserami/graserami.component';
import { GraseramreComponent } from './pages/productos/pages/graseramre/graseramre.component';
import { GraseramreiComponent } from './pages/productos/pages/graseramrei/graseramrei.component';
import { GraserarcComponent } from './pages/productos/pages/graserarc/graserarc.component';
import { GraserarciComponent } from './pages/productos/pages/graserarci/graserarci.component';
import { GraserarfComponent } from './pages/productos/pages/graserarf/graserarf.component';
import { GraserarfiComponent } from './pages/productos/pages/graserarfi/graserarfi.component';
import { GrifosaComponent } from './pages/productos/pages/grifosa/grifosa.component';
import { GrifosmComponent } from './pages/productos/pages/grifosm/grifosm.component';
import { HerramientainsertoComponent } from './pages/productos/pages/herramientainserto/herramientainserto.component';
import { HerramientamultiComponent } from './pages/productos/pages/herramientamulti/herramientamulti.component';
import { HerramientapiComponent } from './pages/productos/pages/herramientapi/herramientapi.component';
import { HexagonalagricolaComponent } from './pages/productos/pages/hexagonalagricola/hexagonalagricola.component';
import { Hexagonalc10Component } from './pages/productos/pages/hexagonalc10/hexagonalc10.component';
import { Hexagonalc10rcComponent } from './pages/productos/pages/hexagonalc10rc/hexagonalc10rc.component';
import { HexagonalaluminioComponent } from './pages/productos/pages/hexagonalaluminio/hexagonalaluminio.component';
import { Hexagonalc4Component } from './pages/productos/pages/hexagonalc4/hexagonalc4.component';
import { Hexagonalc8Component } from './pages/productos/pages/hexagonalc8/hexagonalc8.component';
import { Hexagonalc8rcComponent } from './pages/productos/pages/hexagonalc8rc/hexagonalc8rc.component';
import { HexagonalbronceComponent } from './pages/productos/pages/hexagonalbronce/hexagonalbronce.component';
import { HexagonalgComponent } from './pages/productos/pages/hexagonalg/hexagonalg.component';
import { HexagonalgcComponent } from './pages/productos/pages/hexagonalgc/hexagonalgc.component';
import { HexagonalplasticoComponent } from './pages/productos/pages/hexagonalplastico/hexagonalplastico.component';
import { Hexagonalg2rcComponent } from './pages/productos/pages/hexagonalg2rc/hexagonalg2rc.component';
import { Hexagonalg2rcgComponent } from './pages/productos/pages/hexagonalg2rcg/hexagonalg2rcg.component';
import { Hexagonalg5cComponent } from './pages/productos/pages/hexagonalg5c/hexagonalg5c.component';
import { HexagonalgrcComponent } from './pages/productos/pages/hexagonalgrc/hexagonalgrc.component';
import { Hexagonalg5comerComponent } from './pages/productos/pages/hexagonalg5comer/hexagonalg5comer.component';
import { Hexagonalg5rcgComponent } from './pages/productos/pages/hexagonalg5rcg/hexagonalg5rcg.component';
import { Hexagonalg8cComponent } from './pages/productos/pages/hexagonalg8c/hexagonalg8c.component';
import { Hexagonalg8ptComponent } from './pages/productos/pages/hexagonalg8pt/hexagonalg8pt.component';
import { HexagonaliComponent } from './pages/productos/pages/hexagonali/hexagonali.component';
import { Hexagonalinox316Component } from './pages/productos/pages/hexagonalinox316/hexagonalinox316.component';
import { Hexagonalinoxa4Component } from './pages/productos/pages/hexagonalinoxa4/hexagonalinoxa4.component';
import { Hexagonalinoxa4rcComponent } from './pages/productos/pages/hexagonalinoxa4rc/hexagonalinoxa4rc.component';
import { HexagonalinoxrcComponent } from './pages/productos/pages/hexagonalinoxrc/hexagonalinoxrc.component';
import { HexagonalinoxrfComponent } from './pages/productos/pages/hexagonalinoxrf/hexagonalinoxrf.component';
import { HexagonalptComponent } from './pages/productos/pages/hexagonalpt/hexagonalpt.component';
import { HexagonalrceComponent } from './pages/productos/pages/hexagonalrce/hexagonalrce.component';
import { HexagonalreComponent } from './pages/productos/pages/hexagonalre/hexagonalre.component';
import { HexagonalrecpComponent } from './pages/productos/pages/hexagonalrecp/hexagonalrecp.component';
import { Hexagonalrfc10Component } from './pages/productos/pages/hexagonalrfc10/hexagonalrfc10.component';
import { HexagonalrfcpComponent } from './pages/productos/pages/hexagonalrfcp/hexagonalrfcp.component';
import { Hexagonalrfc8Component } from './pages/productos/pages/hexagonalrfc8/hexagonalrfc8.component';
import { Hexagonalrfg5Component } from './pages/productos/pages/hexagonalrfg5/hexagonalrfg5.component';
import { Hexagonalrfg5cComponent } from './pages/productos/pages/hexagonalrfg5c/hexagonalrfg5c.component';
import { Hexagonalrfg8Component } from './pages/productos/pages/hexagonalrfg8/hexagonalrfg8.component';
import { HexagonalringlesaComponent } from './pages/productos/pages/hexagonalringlesa/hexagonalringlesa.component';
import { HexagonalrizquierdaComponent } from './pages/productos/pages/hexagonalrizquierda/hexagonalrizquierda.component';
import { Hexagonalrpfc10Component } from './pages/productos/pages/hexagonalrpfc10/hexagonalrpfc10.component';
import { HexagonaltaponComponent } from './pages/productos/pages/hexagonaltapon/hexagonaltapon.component';
import { HexagonaltropicalizadoComponent } from './pages/productos/pages/hexagonaltropicalizado/hexagonaltropicalizado.component';
import { Hexagonaltropicalizadog5Component } from './pages/productos/pages/hexagonaltropicalizadog5/hexagonaltropicalizadog5.component';
import { HexagonaltropicalizadorcComponent } from './pages/productos/pages/hexagonaltropicalizadorc/hexagonaltropicalizadorc.component';
import { HitchpinComponent } from './pages/productos/pages/hitchpin/hitchpin.component';
import { InsertoamecoilComponent } from './pages/productos/pages/insertoamecoil/insertoamecoil.component';
import { InsertoautorosComponent } from './pages/productos/pages/insertoautoros/insertoautoros.component';
import { JuegoextractoresComponent } from './pages/productos/pages/juegoextractores/juegoextractores.component';
import { JuegoseguroseComponent } from './pages/productos/pages/juegosegurose/juegosegurose.component';
import { JuegosegurosiComponent } from './pages/productos/pages/juegosegurosi/juegosegurosi.component';
import { JuegosegurosieComponent } from './pages/productos/pages/juegosegurosie/juegosegurosie.component';
import { KitamecoilComponent } from './pages/productos/pages/kitamecoil/kitamecoil.component';
import { KitamecoilexComponent } from './pages/productos/pages/kitamecoilex/kitamecoilex.component';
import { KitamecoilmComponent } from './pages/productos/pages/kitamecoilm/kitamecoilm.component';
import { LaminaconicoComponent } from './pages/productos/pages/laminaconico/laminaconico.component';
import { LaminahexagonalComponent } from './pages/productos/pages/laminahexagonal/laminahexagonal.component';
import { LlaveesparragoseguridadComponent } from './pages/productos/pages/llaveesparragoseguridad/llaveesparragoseguridad.component';
import { LaminahexagonalcastigaderaComponent } from './pages/productos/pages/laminahexagonalcastigadera/laminahexagonalcastigadera.component';
import { LaminahexagonalconcretoComponent } from './pages/productos/pages/laminahexagonalconcreto/laminahexagonalconcreto.component';
import { LaminahexagonalinoxComponent } from './pages/productos/pages/laminahexagonalinox/laminahexagonalinox.component';
import { LaminahexagonalpbComponent } from './pages/productos/pages/laminahexagonalpb/laminahexagonalpb.component';
import { LaminahexagonalpbcComponent } from './pages/productos/pages/laminahexagonalpbc/laminahexagonalpbc.component';
import { LaminahexagonalpbiComponent } from './pages/productos/pages/laminahexagonalpbi/laminahexagonalpbi.component';
import { LaminahexagonalpbpComponent } from './pages/productos/pages/laminahexagonalpbp/laminahexagonalpbp.component';
import { LaminahexagonalspComponent } from './pages/productos/pages/laminahexagonalsp/laminahexagonalsp.component';
import { LaminaplanacinoxComponent } from './pages/productos/pages/laminaplanacinox/laminaplanacinox.component';
import { LaminaplanapcromadoComponent } from './pages/productos/pages/laminaplanapcromado/laminaplanapcromado.component';
import { LaminaplanapinoxComponent } from './pages/productos/pages/laminaplanapinox/laminaplanapinox.component';
import { LaminaplanapnegroComponent } from './pages/productos/pages/laminaplanapnegro/laminaplanapnegro.component';
import { LaminaplanappbComponent } from './pages/productos/pages/laminaplanappb/laminaplanappb.component';
import { LaminaplanaprComponent } from './pages/productos/pages/laminaplanapr/laminaplanapr.component';
import { LaminaplanaptgComponent } from './pages/productos/pages/laminaplanaptg/laminaplanaptg.component';
import { LaminaplanocComponent } from './pages/productos/pages/laminaplanoc/laminaplanoc.component';
import { LaminaplanopComponent } from './pages/productos/pages/laminaplanop/laminaplanop.component';
import { LaminaredondapcComponent } from './pages/productos/pages/laminaredondapc/laminaredondapc.component';
import { LaminaredondapnComponent } from './pages/productos/pages/laminaredondapn/laminaredondapn.component';
import { LaminaredondaqpbComponent } from './pages/productos/pages/laminaredondaqpb/laminaredondaqpb.component';
import { LaminaredondocComponent } from './pages/productos/pages/laminaredondoc/laminaredondoc.component';
import { LaminaredondociComponent } from './pages/productos/pages/laminaredondoci/laminaredondoci.component';
import { LaminaredondocombinadoComponent } from './pages/productos/pages/laminaredondocombinado/laminaredondocombinado.component';
import { LaminaredondopComponent } from './pages/productos/pages/laminaredondop/laminaredondop.component';
import { LaminaredondopiComponent } from './pages/productos/pages/laminaredondopi/laminaredondopi.component';
import { LaminaredondoppbComponent } from './pages/productos/pages/laminaredondoppb/laminaredondoppb.component';
import { LaminaredondoppbiComponent } from './pages/productos/pages/laminaredondoppbi/laminaredondoppbi.component';
import { LaminaredondotgComponent } from './pages/productos/pages/laminaredondotg/laminaredondotg.component';
import { LiquidoantisobadoComponent } from './pages/productos/pages/liquidoantisobado/liquidoantisobado.component';
import { LlaveallenComponent } from './pages/productos/pages/llaveallen/llaveallen.component';
import { LlaveallenesfericaComponent } from './pages/productos/pages/llaveallenesferica/llaveallenesferica.component';
import { LlaveallenseguridadComponent } from './pages/productos/pages/llaveallenseguridad/llaveallenseguridad.component';
import { LlavecolaComponent } from './pages/productos/pages/llavecola/llavecola.component';
import { LlavetorxComponent } from './pages/productos/pages/llavetorx/llavetorx.component';
import { LubricantealimenticioComponent } from './pages/productos/pages/lubricantealimenticio/lubricantealimenticio.component';
import { LubricantegeneralComponent } from './pages/productos/pages/lubricantegeneral/lubricantegeneral.component';
import { LubricanteinoxidableComponent } from './pages/productos/pages/lubricanteinoxidable/lubricanteinoxidable.component';
import { LubricantemarinoComponent } from './pages/productos/pages/lubricantemarino/lubricantemarino.component';
import { MachuelobspComponent } from './pages/productos/pages/machuelobsp/machuelobsp.component';
import { MachuelonptComponent } from './pages/productos/pages/machuelonpt/machuelonpt.component';
import { Machuelomecanico15Component } from './pages/productos/pages/machuelomecanico15/machuelomecanico15.component';
import { Machuelomecanico35Component } from './pages/productos/pages/machuelomecanico35/machuelomecanico35.component';
import { MachuelometricoexComponent } from './pages/productos/pages/machuelometricoex/machuelometricoex.component';
import { MachuelopinoxComponent } from './pages/productos/pages/machuelopinox/machuelopinox.component';
import { MachueloamecoilComponent } from './pages/productos/pages/machueloamecoil/machueloamecoil.component';
import { MachuelosComponent } from './pages/productos/pages/machuelos/machuelos.component';
import { ManeralmachueloComponent } from './pages/productos/pages/maneralmachuelo/maneralmachuelo.component';
import { ManeraltarrajaComponent } from './pages/productos/pages/maneraltarraja/maneraltarraja.component';
import { MaquinaplanaComponent } from './pages/productos/pages/maquinaplana/maquinaplana.component';
import { MaquinaredondaComponent } from './pages/productos/pages/maquinaredonda/maquinaredonda.component';
import { MaquinaredondabComponent } from './pages/productos/pages/maquinaredondab/maquinaredondab.component';
import { MueladelanteraComponent } from './pages/productos/pages/mueladelantera/mueladelantera.component';
import { MuelatraseraComponent } from './pages/productos/pages/muelatrasera/muelatrasera.component';
import { OringbunanComponent } from './pages/productos/pages/oringbunan/oringbunan.component';
import { OringvitonComponent } from './pages/productos/pages/oringviton/oringviton.component';
import { PinconicoComponent } from './pages/productos/pages/pinconico/pinconico.component';
import { PindowelComponent } from './pages/productos/pages/pindowel/pindowel.component';
import { PintensionComponent } from './pages/productos/pages/pintension/pintension.component';
import { PintensionamericanoComponent } from './pages/productos/pages/pintensionamericano/pintensionamericano.component';
import { PintensioninoxidableComponent } from './pages/productos/pages/pintensioninoxidable/pintensioninoxidable.component';
import { PinzasegurosComponent } from './pages/productos/pages/pinzaseguros/pinzaseguros.component';
import { ProtectorComponent } from './pages/productos/pages/protector/protector.component';
import { Punta1Component } from './pages/productos/pages/punta1/punta1.component';
import { Punta2Component } from './pages/productos/pages/punta2/punta2.component';
import { Punta3Component } from './pages/productos/pages/punta3/punta3.component';
import { RemachealuminioComponent } from './pages/productos/pages/remachealuminio/remachealuminio.component';
import { RemachehierroComponent } from './pages/productos/pages/remachehierro/remachehierro.component';
import { RemachepopComponent } from './pages/productos/pages/remachepop/remachepop.component';
import { RemachepopaaComponent } from './pages/productos/pages/remachepopaa/remachepopaa.component';
import { RemachepopcbComponent } from './pages/productos/pages/remachepopcb/remachepopcb.component';
import { RemachepopcnComponent } from './pages/productos/pages/remachepopcn/remachepopcn.component';
import { RemachepopctComponent } from './pages/productos/pages/remachepopct/remachepopct.component';
import { RemachepopinoxidableComponent } from './pages/productos/pages/remachepopinoxidable/remachepopinoxidable.component';
import { ResortecompresionComponent } from './pages/productos/pages/resortecompresion/resortecompresion.component';
import { ResorteconpataComponent } from './pages/productos/pages/resorteconpata/resorteconpata.component';
import { ResorteestirarComponent } from './pages/productos/pages/resorteestirar/resorteestirar.component';
import { ResorteslComponent } from './pages/productos/pages/resortesl/resortesl.component';
import { Roldanaajuste01Component } from './pages/productos/pages/roldanaajuste01/roldanaajuste01.component';
import { Roldanaajuste03Component } from './pages/productos/pages/roldanaajuste03/roldanaajuste03.component';
import { Roldanaajuste05Component } from './pages/productos/pages/roldanaajuste05/roldanaajuste05.component';
import { RoldanabiseladaComponent } from './pages/productos/pages/roldanabiselada/roldanabiselada.component';
import { RoldanacnaturalComponent } from './pages/productos/pages/roldanacnatural/roldanacnatural.component';
import { RoldanacomercialComponent } from './pages/productos/pages/roldanacomercial/roldanacomercial.component';
import { RoldanaconcavaComponent } from './pages/productos/pages/roldanaconcava/roldanaconcava.component';
import { Roldanaajuste1Component } from './pages/productos/pages/roldanaajuste1/roldanaajuste1.component';
import { RoldanaaluminioComponent } from './pages/productos/pages/roldanaaluminio/roldanaaluminio.component';
import { RoldanaaluminiosellarComponent } from './pages/productos/pages/roldanaaluminiosellar/roldanaaluminiosellar.component';
import { Roldanaanilloinox316Component } from './pages/productos/pages/roldanaanilloinox316/roldanaanilloinox316.component';
import { RoldanacobreComponent } from './pages/productos/pages/roldanacobre/roldanacobre.component';
import { RoldanalujoComponent } from './pages/productos/pages/roldanalujo/roldanalujo.component';
import { RoldanaplasticoComponent } from './pages/productos/pages/roldanaplastico/roldanaplastico.component';
import { Roldanaseguridad1Component } from './pages/productos/pages/roldanaseguridad1/roldanaseguridad1.component';
import { Roldanaseguridad2Component } from './pages/productos/pages/roldanaseguridad2/roldanaseguridad2.component';
import { Roldanaseguridad4Component } from './pages/productos/pages/roldanaseguridad4/roldanaseguridad4.component';
import { Roldanaseguridad5Component } from './pages/productos/pages/roldanaseguridad5/roldanaseguridad5.component';
import { Roldanaseguridad6Component } from './pages/productos/pages/roldanaseguridad6/roldanaseguridad6.component';
import { RoldanatapiceriaComponent } from './pages/productos/pages/roldanatapiceria/roldanatapiceria.component';
import { RoldanaestructuralComponent } from './pages/productos/pages/roldanaestructural/roldanaestructural.component';
import { RoldanafenderComponent } from './pages/productos/pages/roldanafender/roldanafender.component';
import { RoldanafenderinoxComponent } from './pages/productos/pages/roldanafenderinox/roldanafenderinox.component';
import { RoldanagalvanizadaComponent } from './pages/productos/pages/roldanagalvanizada/roldanagalvanizada.component';
import { RoldanagalvanizadaanilloComponent } from './pages/productos/pages/roldanagalvanizadaanillo/roldanagalvanizadaanillo.component';
import { RoldanagalvanizadacalienteComponent } from './pages/productos/pages/roldanagalvanizadacaliente/roldanagalvanizadacaliente.component';
import { RoldanagruesasujecionComponent } from './pages/productos/pages/roldanagruesasujecion/roldanagruesasujecion.component';
import { RoldanainoxidableComponent } from './pages/productos/pages/roldanainoxidable/roldanainoxidable.component';
import { Roldanainoxidable316Component } from './pages/productos/pages/roldanainoxidable316/roldanainoxidable316.component';
import { Roldanainoxidablea4Component } from './pages/productos/pages/roldanainoxidablea4/roldanainoxidablea4.component';
import { RoldanainoxidableanilloComponent } from './pages/productos/pages/roldanainoxidableanillo/roldanainoxidableanillo.component';
import { RoldananaturalComponent } from './pages/productos/pages/roldananatural/roldananatural.component';
import { RoldanaonduladaComponent } from './pages/productos/pages/roldanaondulada/roldanaondulada.component';
import { RoldanaonduladatropicalizadaComponent } from './pages/productos/pages/roldanaonduladatropicalizada/roldanaonduladatropicalizada.component';
import { RoldanatropicalizadaComponent } from './pages/productos/pages/roldanatropicalizada/roldanatropicalizada.component';
import { RoldanabronceComponent } from './pages/productos/pages/roldanabronce/roldanabronce.component';
import { SeguroaranaComponent } from './pages/productos/pages/seguroarana/seguroarana.component';
import { SeguroclipComponent } from './pages/productos/pages/seguroclip/seguroclip.component';
import { SeguroclipinoxComponent } from './pages/productos/pages/seguroclipinox/seguroclipinox.component';
import { SeguroexternoComponent } from './pages/productos/pages/seguroexterno/seguroexterno.component';
import { SeguroexternoinoxComponent } from './pages/productos/pages/seguroexternoinox/seguroexternoinox.component';
import { SegurosujecionanilloComponent } from './pages/productos/pages/segurosujecionanillo/segurosujecionanillo.component';
import { SegurosujecionranuraComponent } from './pages/productos/pages/segurosujecionranura/segurosujecionranura.component';
import { SegurointernoComponent } from './pages/productos/pages/segurointerno/segurointerno.component';
import { SegurointernoinoxComponent } from './pages/productos/pages/segurointernoinox/segurointernoinox.component';
import { SegurocajaComponent } from './pages/productos/pages/segurocaja/segurocaja.component';
import { SierraComponent } from './pages/productos/pages/sierra/sierra.component';
import { SilicongrisComponent } from './pages/productos/pages/silicongris/silicongris.component';
import { SiliconnegroComponent } from './pages/productos/pages/siliconnegro/siliconnegro.component';
import { SiliconrojaComponent } from './pages/productos/pages/siliconroja/siliconroja.component';
import { SilicontransparenteComponent } from './pages/productos/pages/silicontransparente/silicontransparente.component';
import { SoldadurafrioComponent } from './pages/productos/pages/soldadurafrio/soldadurafrio.component';
import { SoldaduratransparenteComponent } from './pages/productos/pages/soldaduratransparente/soldaduratransparente.component';
import { SuperglueComponent } from './pages/productos/pages/superglue/superglue.component';
import { TablaComponent } from './pages/productos/pages/tabla/tabla.component';
import { TablayesoComponent } from './pages/productos/pages/tablayeso/tablayeso.component';
import { TablayesocornetaComponent } from './pages/productos/pages/tablayesocorneta/tablayesocorneta.component';
import { TablayesocornetadacrometComponent } from './pages/productos/pages/tablayesocornetadacromet/tablayesocornetadacromet.component';
import { TablayesocornetadobleroscaComponent } from './pages/productos/pages/tablayesocornetadoblerosca/tablayesocornetadoblerosca.component';
import { TablayesoobleapbComponent } from './pages/productos/pages/tablayesoobleapb/tablayesoobleapb.component';
import { TablayesoobleapbaComponent } from './pages/productos/pages/tablayesoobleapba/tablayesoobleapba.component';
import { TablayesoplanarecortadaComponent } from './pages/productos/pages/tablayesoplanarecortada/tablayesoplanarecortada.component';
import { TablayesoredondaComponent } from './pages/productos/pages/tablayesoredonda/tablayesoredonda.component';
import { TablayesoredondapComponent } from './pages/productos/pages/tablayesoredondap/tablayesoredondap.component';
import { TablayecornetaquadrexComponent } from './pages/productos/pages/tablayecornetaquadrex/tablayecornetaquadrex.component';
import { TablayefinoComponent } from './pages/productos/pages/tablayefino/tablayefino.component';
import { TablayeplanocomercialComponent } from './pages/productos/pages/tablayeplanocomercial/tablayeplanocomercial.component';
import { TablayesopbaComponent } from './pages/productos/pages/tablayesopba/tablayesopba.component';
import { TablayesoplanopilotComponent } from './pages/productos/pages/tablayesoplanopilot/tablayesoplanopilot.component';
import { TablayesorecortadopbComponent } from './pages/productos/pages/tablayesorecortadopb/tablayesorecortadopb.component';
import { TablayesoredondaoComponent } from './pages/productos/pages/tablayesoredondao/tablayesoredondao.component';
import { TablayesopbpqComponent } from './pages/productos/pages/tablayesopbpq/tablayesopbpq.component';
import { TablayesopbComponent } from './pages/productos/pages/tablayesopb/tablayesopb.component';
import { TablayesopbgComponent } from './pages/productos/pages/tablayesopbg/tablayesopbg.component';
import { TablayesopbrComponent } from './pages/productos/pages/tablayesopbr/tablayesopbr.component';
import { TablayesorpbComponent } from './pages/productos/pages/tablayesorpb/tablayesorpb.component';
import { TablayesorpbgComponent } from './pages/productos/pages/tablayesorpbg/tablayesorpbg.component';
import { TablayesorrComponent } from './pages/productos/pages/tablayesorr/tablayesorr.component';
import { TablayesorrnComponent } from './pages/productos/pages/tablayesorrn/tablayesorrn.component';
import { TablayesorrpbComponent } from './pages/productos/pages/tablayesorrpb/tablayesorrpb.component';
import { TablayesorrpbnComponent } from './pages/productos/pages/tablayesorrpbn/tablayesorrpbn.component';
import { TablayesotComponent } from './pages/productos/pages/tablayesot/tablayesot.component';
import { TaponallenComponent } from './pages/productos/pages/taponallen/taponallen.component';
import { TaponallenbspComponent } from './pages/productos/pages/taponallenbsp/taponallenbsp.component';
import { TaponallennptComponent } from './pages/productos/pages/taponallennpt/taponallennpt.component';
import { TaponhexagonalComponent } from './pages/productos/pages/taponhexagonal/taponhexagonal.component';
import { TarrajahssgComponent } from './pages/productos/pages/tarrajahssg/tarrajahssg.component';
import { TarrajasComponent } from './pages/productos/pages/tarrajas/tarrajas.component';
import { TarugoexpansionComponent } from './pages/productos/pages/tarugoexpansion/tarugoexpansion.component';
import { TarugoexpansiondobleComponent } from './pages/productos/pages/tarugoexpansiondoble/tarugoexpansiondoble.component';
import { TarugofisherComponent } from './pages/productos/pages/tarugofisher/tarugofisher.component';
import { TarugofishercomercialComponent } from './pages/productos/pages/tarugofishercomercial/tarugofishercomercial.component';
import { TarugoplasticoComponent } from './pages/productos/pages/tarugoplastico/tarugoplastico.component';
import { TeflonliquidoComponent } from './pages/productos/pages/teflonliquido/teflonliquido.component';
import { TermometrodigitalComponent } from './pages/productos/pages/termometrodigital/termometrodigital.component';
import { TipospaxComponent } from './pages/productos/pages/tipospax/tipospax.component';
import { TipospaxnegroComponent } from './pages/productos/pages/tipospaxnegro/tipospaxnegro.component';
import { TornillocarroceroComponent } from './pages/productos/pages/tornillocarrocero/tornillocarrocero.component';
import { TornillocarrocerogalvanizadoComponent } from './pages/productos/pages/tornillocarrocerogalvanizado/tornillocarrocerogalvanizado.component';
import { TornillocuadradoComponent } from './pages/productos/pages/tornillocuadrado/tornillocuadrado.component';
import { TornilloestufohexagonalComponent } from './pages/productos/pages/tornilloestufohexagonal/tornilloestufohexagonal.component';
import { TornilloestufohexagonalfinoComponent } from './pages/productos/pages/tornilloestufohexagonalfino/tornilloestufohexagonalfino.component';
import { TornilloplanoseguridadComponent } from './pages/productos/pages/tornilloplanoseguridad/tornilloplanoseguridad.component';
import { Tornillocarrocerog5Component } from './pages/productos/pages/tornillocarrocerog5/tornillocarrocerog5.component';
import { TornillocarroceroinoxComponent } from './pages/productos/pages/tornillocarroceroinox/tornillocarroceroinox.component';
import { TornillocentroComponent } from './pages/productos/pages/tornillocentro/tornillocentro.component';
import { TornillocentrometricoComponent } from './pages/productos/pages/tornillocentrometrico/tornillocentrometrico.component';
import { TornilloelevadorComponent } from './pages/productos/pages/tornilloelevador/tornilloelevador.component';
import { TornilloelevadorgalvanizadoComponent } from './pages/productos/pages/tornilloelevadorgalvanizado/tornilloelevadorgalvanizado.component';
import { TornilloelevadorpuntasComponent } from './pages/productos/pages/tornilloelevadorpuntas/tornilloelevadorpuntas.component';
import { TornilloelevadorinoxComponent } from './pages/productos/pages/tornilloelevadorinox/tornilloelevadorinox.component';
import { TornilloestdComponent } from './pages/productos/pages/tornilloestd/tornilloestd.component';
import { TornilloestructuralComponent } from './pages/productos/pages/tornilloestructural/tornilloestructural.component';
import { TornillomaderaplanoComponent } from './pages/productos/pages/tornillomaderaplano/tornillomaderaplano.component';
import { TornillocangilonComponent } from './pages/productos/pages/tornillocangilon/tornillocangilon.component';
import { TornillomaderaojoComponent } from './pages/productos/pages/tornillomaderaojo/tornillomaderaojo.component';
import { TornillomaderapaComponent } from './pages/productos/pages/tornillomaderapa/tornillomaderapa.component';
import { TornillomaderarpaComponent } from './pages/productos/pages/tornillomaderarpa/tornillomaderarpa.component';
import { TornillomaderarrComponent } from './pages/productos/pages/tornillomaderarr/tornillomaderarr.component';
import { TornillomuebleacComponent } from './pages/productos/pages/tornillomuebleac/tornillomuebleac.component';
import { TornillomuebleatpComponent } from './pages/productos/pages/tornillomuebleatp/tornillomuebleatp.component';
import { TornillomuebledpComponent } from './pages/productos/pages/tornillomuebledp/tornillomuebledp.component';
import { TornillomuebledpgComponent } from './pages/productos/pages/tornillomuebledpg/tornillomuebledpg.component';
import { TornillopdrcComponent } from './pages/productos/pages/tornillopdrc/tornillopdrc.component';
import { TornilloprisionerocuadradoComponent } from './pages/productos/pages/tornilloprisionerocuadrado/tornilloprisionerocuadrado.component';
import { Tornillorastrag5Component } from './pages/productos/pages/tornillorastrag5/tornillorastrag5.component';
import { TornillotirabuzonComponent } from './pages/productos/pages/tornillotirabuzon/tornillotirabuzon.component';
import { TornillotirabuzoninoxComponent } from './pages/productos/pages/tornillotirabuzoninox/tornillotirabuzoninox.component';
import { Tornillotorx12ladosComponent } from './pages/productos/pages/tornillotorx12lados/tornillotorx12lados.component';
import { Tornillotorx12ladosfinoComponent } from './pages/productos/pages/tornillotorx12ladosfino/tornillotorx12ladosfino.component';
import { TorxcilindricogComponent } from './pages/productos/pages/torxcilindricog/torxcilindricog.component';
import { TorxbotonComponent } from './pages/productos/pages/torxboton/torxboton.component';
import { TorxplanoComponent } from './pages/productos/pages/torxplano/torxplano.component';
import { Tuerca2hComponent } from './pages/productos/pages/tuerca2h/tuerca2h.component';
import { TuercaallencrComponent } from './pages/productos/pages/tuercaallencr/tuercaallencr.component';
import { TuercaanclajemComponent } from './pages/productos/pages/tuercaanclajem/tuercaanclajem.component';
import { TuercaartilleraComponent } from './pages/productos/pages/tuercaartillera/tuercaartillera.component';
import { Tuercab7Component } from './pages/productos/pages/tuercab7/tuercab7.component';
import { TuercasegurogolpeComponent } from './pages/productos/pages/tuercasegurogolpe/tuercasegurogolpe.component';
import { TuercabraComponent } from './pages/productos/pages/tuercabra/tuercabra.component';
import { TuercabrarComponent } from './pages/productos/pages/tuercabrar/tuercabrar.component';
import { TuercacbreComponent } from './pages/productos/pages/tuercacbre/tuercacbre.component';
import { TuercacbrfComponent } from './pages/productos/pages/tuercacbrf/tuercacbrf.component';
import { TuercaceComponent } from './pages/productos/pages/tuercace/tuercace.component';
import { TuercacedComponent } from './pages/productos/pages/tuercaced/tuercaced.component';
import { TuercaceiComponent } from './pages/productos/pages/tuercacei/tuercacei.component';
import { TuercacfdComponent } from './pages/productos/pages/tuercacfd/tuercacfd.component';
import { TuercacfiComponent } from './pages/productos/pages/tuercacfi/tuercacfi.component';
import { TuercaclreComponent } from './pages/productos/pages/tuercaclre/tuercaclre.component';
import { TuercaclrfComponent } from './pages/productos/pages/tuercaclrf/tuercaclrf.component';
import { TuercacltreComponent } from './pages/productos/pages/tuercacltre/tuercacltre.component';
import { TuercacltrfComponent } from './pages/productos/pages/tuercacltrf/tuercacltrf.component';
import { TuercacseComponent } from './pages/productos/pages/tuercacse/tuercacse.component';
import { TuercacsfComponent } from './pages/productos/pages/tuercacsf/tuercacsf.component';
import { TuercacefComponent } from './pages/productos/pages/tuercacef/tuercacef.component';
import { TuercacfComponent } from './pages/productos/pages/tuercacf/tuercacf.component';
import { TuercacreComponent } from './pages/productos/pages/tuercacre/tuercacre.component';
import { TuercacrfComponent } from './pages/productos/pages/tuercacrf/tuercacrf.component';
import { TuercacrfiComponent } from './pages/productos/pages/tuercacrfi/tuercacrfi.component';
import { TuercacroComponent } from './pages/productos/pages/tuercacro/tuercacro.component';
import { TuercachuchoComponent } from './pages/productos/pages/tuercachucho/tuercachucho.component';
import { TuercacilindricaabComponent } from './pages/productos/pages/tuercacilindricaab/tuercacilindricaab.component';
import { TuercacilindricaacrComponent } from './pages/productos/pages/tuercacilindricaacr/tuercacilindricaacr.component';
import { TuercacilindricaalComponent } from './pages/productos/pages/tuercacilindricaal/tuercacilindricaal.component';
import { TuercacilindricaastrilladaComponent } from './pages/productos/pages/tuercacilindricaastrillada/tuercacilindricaastrillada.component';
import { TuercabronceComponent } from './pages/productos/pages/tuercabronce/tuercabronce.component';
import { TuercacuadradaComponent } from './pages/productos/pages/tuercacuadrada/tuercacuadrada.component';
import { TuercacapiruchoComponent } from './pages/productos/pages/tuercacapirucho/tuercacapirucho.component';
import { TuercacapiruchoiComponent } from './pages/productos/pages/tuercacapiruchoi/tuercacapiruchoi.component';
import { TuercachuchoiComponent } from './pages/productos/pages/tuercachuchoi/tuercachuchoi.component';
import { TuercachucholujoComponent } from './pages/productos/pages/tuercachucholujo/tuercachucholujo.component';
import { TuercachucholujoiComponent } from './pages/productos/pages/tuercachucholujoi/tuercachucholujoi.component';
import { TuercachucholujotrComponent } from './pages/productos/pages/tuercachucholujotr/tuercachucholujotr.component';
import { TuercachuchotuboComponent } from './pages/productos/pages/tuercachuchotubo/tuercachuchotubo.component';
import { TuercacoplaComponent } from './pages/productos/pages/tuercacopla/tuercacopla.component';
import { TuercacoplaiComponent } from './pages/productos/pages/tuercacoplai/tuercacoplai.component';
import { TuercacoronaComponent } from './pages/productos/pages/tuercacorona/tuercacorona.component';
import { TuercacoronaeComponent } from './pages/productos/pages/tuercacoronae/tuercacoronae.component';
import { TuercacoronafComponent } from './pages/productos/pages/tuercacoronaf/tuercacoronaf.component';
import { TuercacoronaoComponent } from './pages/productos/pages/tuercacoronao/tuercacoronao.component';
import { TuercainsercionComponent } from './pages/productos/pages/tuercainsercion/tuercainsercion.component';
import { TuercainsercionmComponent } from './pages/productos/pages/tuercainsercionm/tuercainsercionm.component';
import { TuercalanaComponent } from './pages/productos/pages/tuercalana/tuercalana.component';
import { TuercamariposaComponent } from './pages/productos/pages/tuercamariposa/tuercamariposa.component';
import { TuercamariposafComponent } from './pages/productos/pages/tuercamariposaf/tuercamariposaf.component';
import { TuercamariposainoxComponent } from './pages/productos/pages/tuercamariposainox/tuercamariposainox.component';
import { TuercaplasticaComponent } from './pages/productos/pages/tuercaplastica/tuercaplastica.component';
import { TuercaseguridadmreComponent } from './pages/productos/pages/tuercaseguridadmre/tuercaseguridadmre.component';
import { TuercaseguridadmrfComponent } from './pages/productos/pages/tuercaseguridadmrf/tuercaseguridadmrf.component';
import { TuercaseguridadpreComponent } from './pages/productos/pages/tuercaseguridadpre/tuercaseguridadpre.component';
import { TuercaseguridadprfComponent } from './pages/productos/pages/tuercaseguridadprf/tuercaseguridadprf.component';
import { TuercaseguridadrComponent } from './pages/productos/pages/tuercaseguridadr/tuercaseguridadr.component';
import { TuercaseguridadcrfComponent } from './pages/productos/pages/tuercaseguridadcrf/tuercaseguridadcrf.component';
import { TuercaseguridadgaComponent } from './pages/productos/pages/tuercaseguridadga/tuercaseguridadga.component';
import { TuercaseguridadgaaComponent } from './pages/productos/pages/tuercaseguridadgaa/tuercaseguridadgaa.component';
import { TuercaseguridadgcComponent } from './pages/productos/pages/tuercaseguridadgc/tuercaseguridadgc.component';
import { TuercaseguridadgiComponent } from './pages/productos/pages/tuercaseguridadgi/tuercaseguridadgi.component';
import { TuercaseguridadgmComponent } from './pages/productos/pages/tuercaseguridadgm/tuercaseguridadgm.component';
import { TuercaseguridadgoComponent } from './pages/productos/pages/tuercaseguridadgo/tuercaseguridadgo.component';
import { TuercaseguridadgrComponent } from './pages/productos/pages/tuercaseguridadgr/tuercaseguridadgr.component';
import { TuercaseguridadplasticaComponent } from './pages/productos/pages/tuercaseguridadplastica/tuercaseguridadplastica.component';
import { TuercaseguridadplasticafpComponent } from './pages/productos/pages/tuercaseguridadplasticafp/tuercaseguridadplasticafp.component';
import { TuercaseguridadplasticainoxComponent } from './pages/productos/pages/tuercaseguridadplasticainox/tuercaseguridadplasticainox.component';
import { TuercaseguridadmetalComponent } from './pages/productos/pages/tuercaseguridadmetal/tuercaseguridadmetal.component';
import { TuercaseguridadgpComponent } from './pages/productos/pages/tuercaseguridadgp/tuercaseguridadgp.component';
import { TuercaseguridadgprComponent } from './pages/productos/pages/tuercaseguridadgpr/tuercaseguridadgpr.component';
import { TuercataponComponent } from './pages/productos/pages/tuercatapon/tuercatapon.component';
import { TuercataponinoxComponent } from './pages/productos/pages/tuercataponinox/tuercataponinox.component';
import { TuercahexagonalComponent } from './pages/productos/pages/tuercahexagonal/tuercahexagonal.component';
import { TuercahexagonalalbronceComponent } from './pages/productos/pages/tuercahexagonalalbronce/tuercahexagonalalbronce.component';
import { TuercahexagonalaluminioComponent } from './pages/productos/pages/tuercahexagonalaluminio/tuercahexagonalaluminio.component';
import { TuercahexagonalcaneradComponent } from './pages/productos/pages/tuercahexagonalcanerad/tuercahexagonalcanerad.component';
import { TuercahexagonalcaneraiComponent } from './pages/productos/pages/tuercahexagonalcanerai/tuercahexagonalcanerai.component';
import { TuercahexagonalcmComponent } from './pages/productos/pages/tuercahexagonalcm/tuercahexagonalcm.component';
import { TuercahexagonalranuraComponent } from './pages/productos/pages/tuercahexagonalranura/tuercahexagonalranura.component';
import { TuercahexagonalranurafComponent } from './pages/productos/pages/tuercahexagonalranuraf/tuercahexagonalranuraf.component';
import { TuercahexagonalbroncefComponent } from './pages/productos/pages/tuercahexagonalbroncef/tuercahexagonalbroncef.component';
import { TuercahexagonalcoronaeComponent } from './pages/productos/pages/tuercahexagonalcoronae/tuercahexagonalcoronae.component';
import { TuercahexagonalestructuralComponent } from './pages/productos/pages/tuercahexagonalestructural/tuercahexagonalestructural.component';
import { TuercahexagonalgalvanizadaComponent } from './pages/productos/pages/tuercahexagonalgalvanizada/tuercahexagonalgalvanizada.component';
import { Tuercahexagonalgalvanizadac4Component } from './pages/productos/pages/tuercahexagonalgalvanizadac4/tuercahexagonalgalvanizadac4.component';
import { TuercahexagonalgalvanizadacalienteComponent } from './pages/productos/pages/tuercahexagonalgalvanizadacaliente/tuercahexagonalgalvanizadacaliente.component';
import { Tuercahexagonalgalvanizadag2Component } from './pages/productos/pages/tuercahexagonalgalvanizadag2/tuercahexagonalgalvanizadag2.component';
import { Tuercahexagonalgalvanizadag5Component } from './pages/productos/pages/tuercahexagonalgalvanizadag5/tuercahexagonalgalvanizadag5.component';
import { Tuercahexagonalgrado2Component } from './pages/productos/pages/tuercahexagonalgrado2/tuercahexagonalgrado2.component';
import { Tuercahexagonalgrado5Component } from './pages/productos/pages/tuercahexagonalgrado5/tuercahexagonalgrado5.component';
import { TuercahexagonalinglesaComponent } from './pages/productos/pages/tuercahexagonalinglesa/tuercahexagonalinglesa.component';
import { TuercahexagonalinoxidableComponent } from './pages/productos/pages/tuercahexagonalinoxidable/tuercahexagonalinoxidable.component';
import { Tuercahexagonalinoxidable316Component } from './pages/productos/pages/tuercahexagonalinoxidable316/tuercahexagonalinoxidable316.component';
import { Tuercahexagonalinoxidablea4Component } from './pages/productos/pages/tuercahexagonalinoxidablea4/tuercahexagonalinoxidablea4.component';
import { TuercahexagonalinoxidablerfComponent } from './pages/productos/pages/tuercahexagonalinoxidablerf/tuercahexagonalinoxidablerf.component';
import { TuercahexagonalizquierdaComponent } from './pages/productos/pages/tuercahexagonalizquierda/tuercahexagonalizquierda.component';
import { TuercahexagonalizquierdarfComponent } from './pages/productos/pages/tuercahexagonalizquierdarf/tuercahexagonalizquierdarf.component';
import { TuercahexagonalpacheComponent } from './pages/productos/pages/tuercahexagonalpache/tuercahexagonalpache.component';
import { TuercahexagonalpacheinoxComponent } from './pages/productos/pages/tuercahexagonalpacheinox/tuercahexagonalpacheinox.component';
import { TuercahexagonalreComponent } from './pages/productos/pages/tuercahexagonalre/tuercahexagonalre.component';
import { TuercahexagonalrepComponent } from './pages/productos/pages/tuercahexagonalrep/tuercahexagonalrep.component';
import { TuercahexagonalrfComponent } from './pages/productos/pages/tuercahexagonalrf/tuercahexagonalrf.component';
import { TuercahexagonalrfgComponent } from './pages/productos/pages/tuercahexagonalrfg/tuercahexagonalrfg.component';
import { TuercahexagonalrfiComponent } from './pages/productos/pages/tuercahexagonalrfi/tuercahexagonalrfi.component';
import { TuercahexagonalrfpComponent } from './pages/productos/pages/tuercahexagonalrfp/tuercahexagonalrfp.component';
import { TuercahexagonaltropicalizadaComponent } from './pages/productos/pages/tuercahexagonaltropicalizada/tuercahexagonaltropicalizada.component';
import { TuercamuescaComponent } from './pages/productos/pages/tuercamuesca/tuercamuesca.component';
import { TuercapacheComponent } from './pages/productos/pages/tuercapache/tuercapache.component';
import { TuercapachefiComponent } from './pages/productos/pages/tuercapachefi/tuercapachefi.component';
import { TuercaseguridadmunonComponent } from './pages/productos/pages/tuercaseguridadmunon/tuercaseguridadmunon.component';
import { TuercataponallencComponent } from './pages/productos/pages/tuercataponallenc/tuercataponallenc.component';
import { TuercataponallencrComponent } from './pages/productos/pages/tuercataponallencr/tuercataponallencr.component';
import { TuercatractorComponent } from './pages/productos/pages/tuercatractor/tuercatractor.component';
import { TuercatransversalComponent } from './pages/productos/pages/tuercatransversal/tuercatransversal.component';
import { WashaComponent } from './pages/productos/pages/washa/washa.component';
import { WashaallenComponent } from './pages/productos/pages/washaallen/washaallen.component';
import { WashaalleniComponent } from './pages/productos/pages/washaalleni/washaalleni.component';
import { WashaaluminioComponent } from './pages/productos/pages/washaaluminio/washaaluminio.component';
import { WashaconicaComponent } from './pages/productos/pages/washaconica/washaconica.component';
import { WashabronceComponent } from './pages/productos/pages/washabronce/washabronce.component';
import { WashacomercialComponent } from './pages/productos/pages/washacomercial/washacomercial.component';
import { WashaconicaaComponent } from './pages/productos/pages/washaconicaa/washaconicaa.component';
import { WashaestrellacComponent } from './pages/productos/pages/washaestrellac/washaestrellac.component';
import { WashaestrellaeComponent } from './pages/productos/pages/washaestrellae/washaestrellae.component';
import { WashaestrellaeiComponent } from './pages/productos/pages/washaestrellaei/washaestrellaei.component';
import { WashaestrellaeimComponent } from './pages/productos/pages/washaestrellaeim/washaestrellaeim.component';
import { WashaestrellaeinternaComponent } from './pages/productos/pages/washaestrellaeinterna/washaestrellaeinterna.component';
import { WashagalvanizadaComponent } from './pages/productos/pages/washagalvanizada/washagalvanizada.component';
import { WashagalvanizadacComponent } from './pages/productos/pages/washagalvanizadac/washagalvanizadac.component';
import { WashagalvanizadamComponent } from './pages/productos/pages/washagalvanizadam/washagalvanizadam.component';
import { WashainoxidableComponent } from './pages/productos/pages/washainoxidable/washainoxidable.component';
import { Washainoxidable316Component } from './pages/productos/pages/washainoxidable316/washainoxidable316.component';
import { WashametricaComponent } from './pages/productos/pages/washametrica/washametrica.component';
import { WashatropicalizadaComponent } from './pages/productos/pages/washatropicalizada/washatropicalizada.component';
import { Hexagonalg5Component } from './pages/productos/pages/hexagonalg5/hexagonalg5.component';
import { Hexagonalg5gComponent } from './pages/productos/pages/hexagonalg5g/hexagonalg5g.component';
import { PeabafComponent } from './pages/productos/pages/peabaf/peabaf.component';
import { PeabaiComponent } from './pages/productos/pages/peabai/peabai.component';
import { PeabaacmeComponent } from './pages/productos/pages/peabaacme/peabaacme.component';
import { FrecarComponent } from './pages/productos/pages/frecar/frecar.component';
import { FrecirComponent } from './pages/productos/pages/frecir/frecir.component';
import { FrecirorComponent } from './pages/productos/pages/freciror/freciror.component';
import { FrecobComponent } from './pages/productos/pages/frecob/frecob.component';
import { FrecobdesComponent } from './pages/productos/pages/frecobdes/frecobdes.component';
import { FrecuComponent } from './pages/productos/pages/frecu/frecu.component';
import { AcecorComponent } from './pages/productos/pages/acecor/acecor.component';
import { AbaiComponent } from './pages/productos/pages/abai/abai.component';
import { AeciComponent } from './pages/productos/pages/aeci/aeci.component';
import { AgrcComponent } from './pages/productos/pages/agrc/agrc.component';
import { MtexcencComponent } from './pages/productos/pages/mtexcenc/mtexcenc.component';
import { Ma188Component } from './pages/productos/pages/ma188/ma188.component';
import { BrcenComponent } from './pages/productos/pages/brcen/brcen.component';
import { Burilc6dComponent } from './pages/productos/pages/burilc6d/burilc6d.component';
import { Burilcob8Component } from './pages/productos/pages/burilcob8/burilcob8.component';
import { FrecardesComponent } from './pages/productos/pages/frecardes/frecardes.component';
import { DtorxComponent } from './pages/productos/pages/dtorx/dtorx.component';
import { LlpsComponent } from './pages/productos/pages/llps/llps.component';
import { MapiComponent } from './pages/productos/pages/mapi/mapi.component';
import { AtgComponent } from './pages/productos/pages/atg/atg.component';
import { TacmeComponent } from './pages/productos/pages/tacme/tacme.component';
import { Lh4rpaComponent } from './pages/productos/pages/lh4rpa/lh4rpa.component';
import { Lppb188Component } from './pages/productos/pages/lppb188/lppb188.component';
import { MmcComponent } from './pages/productos/pages/mmc/mmc.component';
import { CarreraEspComponent } from './pages/carrera-esp/carrera-esp.component';
import { AtdtComponent } from './pages/productos/pages/atdt/atdt.component';
import { AtdzComponent } from './pages/productos/pages/atdz/atdz.component';
import { LplsComponent } from './pages/productos/pages/lpls/lpls.component';
import { PeabaecComponent } from './pages/productos/pages/peabaec/peabaec.component';
import { PuntacComponent } from './pages/productos/pages/puntac/puntac.component';
import { LhpbctComponent } from './pages/productos/pages/lhpbct/lhpbct.component';
import { MagComponent } from './pages/productos/pages/mag/mag.component';


const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: '/tornillo/inicio'},
  {path: 'tornillo', component: MenuComponent,
    children :[
      {path: 'inicio', component: InicioComponent},
      {path: 'sucursales', loadChildren: () => import('./pages/sucursales/sucursales.module').then(m => m.SucursalesModule)},
      {path: 'contactenos', component: ContactenosComponent},
      {path: 'quienes-somos', component: QuienesSomosComponent},
      {path: 'productos', loadChildren: () => import('./pages/productos/productos.module').then(m => m.ProductosModule)},
      {path: 'servicios', loadChildren: () => import('./pages/servicios/servicios.module').then(m => m.ServiciosModule)},
      {path: 'nueva-tienda', component: NtiendaComponent},
      {path: 'videos', component: VideosComponent},
      {path: 'nuevos-productos', component: NproductoComponent},
      {path: 'carrera', component: CarreraComponent},
      {path: 'allen-cabeza-cilindrica-plastico', component: AllencpComponent},
      {path: 'allen-cabeza-boton', component: AllencbComponent},
      {path: 'allen-cabeza-boton-tipo-roldana', component: AllencbrComponent},
      {path: 'allen-cabeza-boton-inoxidable', component: AllencbiComponent},
      {path: 'allen-cabeza-pache', component: AllenpacheComponent},
      {path: 'allen-cabeza-pache-inoxidable', component: AllenpacheiComponent},
      {path: 'allen-cabeza-pache-tipo-roldana', component: AllenpacherComponent},
      {path: 'allen-cabeza-cilindrica', component: AllencComponent},
      {path: 'allen-cabeza-cilindrica-clase-12-9', component: Allenc12Component},
      {path: 'allen-cabeza-plana-clase-10-9', component: AllenplanoComponent},
      {path: 'allen-cabeza-cilindrica-clase-8-8', component: Allenc8Component},
      {path: 'allen-cabeza-cilindrica-clase-12-9-rosca-corrida', component: Allencrc12Component},
      {path: 'allen-cilindrico-rosca-corrida-clase-8-8', component: Allencrc8Component},
      {path: 'allen-cilindrico-inoxidable', component: AllenciComponent},
      {path: 'allen-cilindrico-inoxidable-rosca-corrida', component: AllencrciComponent},
      {path: 'allen-cilindrico-rosca-fina', component: AllencfComponent},
      {path: 'allen-cilindrico-rosca-fina-clase-12-9', component: Allencf12Component},
      {path: 'allen-cilindrico-rosca-fina-clase-8-8', component: Allencf8Component},
      {path: 'allen-cilindrico-rosca-fina-inoxidable', component: AllencfiComponent},
      {path: 'allen-hombro-clase-12-9', component: AllenchComponent},
      {path: 'allen-hombro-rosca-fina', component: AllenchfComponent},
      {path: 'allen-mueble-pache', component: AllenmpComponent},
      {path: 'anclaje-camisa', component: AnclajecComponent},
      {path: 'anclaje-galvanizado', component: AnclajegComponent},
      {path: 'anclaje-inoxidable', component: AnclajeiComponent},
      {path: 'anillo-ajuste', component: AnilloajusteComponent},
      {path: 'barra-roscada-b7', component: Barrab7Component},
      {path: 'barra-roscada-galvanizada', component: BarragComponent},
      {path: 'barra-roscada-galvanizada-caliente', component: BarragcComponent},
      {path: 'barra-roscada-inoxidable', component: BarraiComponent},
      {path: 'barra-roscada-metrica-clase-8-8', component: Barram8Component},
      {path: 'barra-roscada-metrica-clase-10-9', component: Barram10Component},
      {path: 'barra-roscada-metrica-galvanizada', component: BarramgComponent},
      {path: 'barra-roscada-metrica-inoxidable', component: BarramiComponent},
      {path: 'bernier', component: BernierComponent},
      {path: 'bernier-electronico', component: BerniereComponent},
      {path: 'birlo-para-techo', component: BirloComponent},
      {path: 'allen-cabeza-boton-seguridad', component: AllencbsComponent},
      {path: 'broca-para-concreto', component: BrocacComponent},
      {path: 'broca-laminada', component: BrocalComponent},
      {path: 'broca-laminada-raiz-1/2', component: BrocalrComponent},
      {path: 'broca-rectificada', component: BrocarComponent},
      {path: 'broca-robusta-para-concreto', component: BrocarcComponent},
      {path: 'broca-rectificada-raiz-1/2', component: BrocarrComponent},
      {path: 'broca-universal', component: BrocaUComponent},
      {path: 'broca-cobalto', component: BrocacoComponent},
      {path: 'cabeza-mariposa', component: CabezamariposaComponent},
      {path: 'cabeza-queso', component: CabezaquesoComponent},
      {path: 'cabeza-queso-inoxidable', component: CabezaquesoiComponent},
      {path: 'allen-cabeza-plana-clase-8-8', component: Allenplano8Component},
      {path: 'allen-cabeza-plana', component: AllenplanoaComponent},
      {path: 'cabeza-plana', component: CabezaplanaComponent},
      {path: 'cabeza-plana-bronce', component: CabezaplanaBComponent},
      {path: 'cabeza-plana-plastico', component: CabezaplanapComponent},
      {path: 'cabeza-plana-inoxidable', component: CabezaplanapiComponent},
      {path: 'allen-cabeza-plana-inoxidable', component: AllenplanoinoxComponent},
      {path: 'cabeza-plana-inoxidable-rosca-fina', component: CabezaplanaifComponent},
      {path: 'cabeza-redonda', component: CabezaredondaComponent},
      {path: 'cabeza-redonda-bronce', component: CabezaredondabComponent},
      {path: 'cabeza-redonda-inoxidable', component: CabezaredondaiComponent},
      {path: 'cabeza-redonda-inoxidable-rosca-fina', component: CabezaredondaifComponent},
      {path: 'caja-plastica', component: CajaplasticaComponent},
      {path: 'calibrador-rosca', component: CalibradorroscaComponent},
      {path: 'capirucho-derecho', component: CapiruchoComponent},
      {path: 'capirucho-izquierdo', component: CapiruchoiComponent},
      {path: 'castigador-allen', component: CastigadorallenComponent},
      {path: 'castigador-allen-inoxidable', component: CastigadoralleniComponent},
      {path: 'castigador-allen-rosca-fina', component: CastigadorallenfComponent},
      {path: 'cemento-cabeza-oblea-astilla-quadrex', component: CementooaComponent},
      {path: 'cemento-cabeza-oblea-punta-aguda', component: CementoopaComponent},
      {path: 'cemento-cabeza-oblea-punta-broca', component: CementoopbComponent},
      {path: 'cemento-cabeza-oblea-punta-broca-alas', component: CementoopbaComponent},
      {path: 'cemento-cabeza-plana-punta-broca-alas', component: CementopalaComponent},
      {path: 'cemento-cabeza-plana-astilla-quadrex', component: CementopasqComponent},
      {path: 'chaveta', component: ChavetaComponent},
      {path: 'chaveta-metrica', component: ChavetamComponent},
      {path: 'chaveta-metrica-inoxidable', component: ChavetamiComponent},
      {path: 'chucho-prensa-cables-trabajo-liviano', component: ChuchocablComponent},
      {path: 'chucho-prensa-cables-trabajo-pesado', component: ChuchocabpComponent},
      {path: 'cinceles-para-rotomartillo', component: CincelesComponent},
      {path: 'conico-abombado', component: ConicoaComponent},
      {path: 'cono-americano', component: ConoaComponent},
      {path: 'cono-metrico', component: ConomComponent},
      {path: 'copa-estrillada-12-lados', component: CopaesComponent},
      {path: 'copa-hexagonal-barreno', component: CopapbComponent},
      {path: 'cuna-cuadrada', component: CunacComponent},
      {path: 'cuna-cuadrada-inoxidable', component: CunaiComponent},
      {path: 'cuna-cuadrada-metrica', component: CunamComponent},
      {path: 'cuna-media-luna', component: CunamlComponent},
      {path: 'empaque-neoprene', component: EmpaquenComponent},
      {path: 'empaque-neoprene-inoxidable', component: EmpaqueniComponent},
      {path: 'empaque-plastico', component: EmpaquepComponent},
      {path: 'esparrago-artillero-grado-2', component: Esparragoa2Component},
      {path: 'esparrago-artillero-grado-5', component: Esparragoa4Component},
      {path: 'esparrago-artillero-grado-8', component: Esparragoa8Component},
      {path: 'esparrago-astrillado', component: EsparragoasComponent},
      {path: 'esparrago-astrillado-rosca-fina', component: EsparragoasfComponent},
      {path: 'esparrago-astrillado-izquierdo', component: EsparragoasiComponent},
      {path: 'esparrago-astrillado-rosca-ordinaria', component: EsparragoasoComponent},
      {path: 'esparrago-canero', component: EsparragocaneroComponent},
      {path: 'esparrago-cabeza-chucho-bolita', component: EsparragocbComponent},
      {path: 'esparrago-cabeza-chucho-lujo-tubo', component: EsparragoclComponent},
      {path: 'esparrago-conico-seguridad-extrafino', component: EsparragocsComponent},
      {path: 'esparrago-conico-seguridad-fino', component: EsparragocsfComponent},
      {path: 'esparrago-conico-extrafino', component: EsparragoceComponent},
      {path: 'esparrago-conico-rosca-fina', component: EsparragocfComponent},
      {path: 'esparrago-doble-punta-rosca-inicio', component: EsparragodpComponent},
      {path: 'esparrago-doble-punta-fino-ordinario', component: EsparragodpfoComponent},
      {path: 'esparrago-doble-punta-ordinario-ordinario', component: EsparragodpooComponent},
      {path: 'esparrago-extrapesado', component: EsparragoexComponent},
      {path: 'esparrago-extrapesado-astrillado-derecho', component: EsparragoexasComponent},
      {path: 'esparrago-extrapesado-astrillado-izquierdo', component: EsparragoexasiComponent},
      {path: 'esparrago-extrapesado-derecho', component: EsparragoexdComponent},
      {path: 'esparrago-extrapesado-doble-punta-derecho', component: EsparragoexdpdComponent},
      {path: 'esparrago-extrapesado-doble-punta-derecho-astrillado', component: EsparragoexdpdaComponent},
      {path: 'esparrago-extrapesado-doble-punta-izquierdo', component: EsparragoexdpiComponent},
      {path: 'esparrago-extrapesado-doble-punta-sobre-medida-izquierdo', component: EsparragoexdpsiComponent},
      {path: 'esparrago-extrapesado-doble-punta-sobre-medida-derecho', component: EsparragoexsdComponent},
      {path: 'esparrago-extrapesado-sobre-medida-derecho', component: EsparragoexsmdComponent},
      {path: 'esparrago-metrico-doble-rosca-fino-fino', component: EsparragodpffComponent},
      {path: 'esparrago-metrico-doble-rosca-fino-ordinario', component: EsparragomdpfoComponent},
      {path: 'esparrago-metrico-rosca-extrafina', component: EsparragomreComponent},
      {path: 'esparrago-metrico-sobre-medida', component: EsparragomsmComponent},
      {path: 'esparrago-sobre-medida', component: EsparragosmComponent},
      {path: 'esparrago-astrillado-rosca-extrafina', component: EsparragoasexComponent},
      {path: 'estufa-cabeza-plana', component: EstufacpComponent},
      {path: 'estufa-cabeza-plana-12-hilos', component: Estufacp12Component},
      {path: 'estufa-cabeza-plana-rosca-fina', component: EstufacprfComponent},
      {path: 'estufa-cabeza-redonda', component: EstufarComponent},
      {path: 'estufa-cabeza-redonda-combinada', component: EstufarcComponent},
      {path: 'estufa-cabeza-redonda-negro', component: EstufarnComponent},
      {path: 'estufa-cabeza-redonda-rosca-fina', component: EstufarrfComponent},
      {path: 'extractor-amecoil', component: ExtractorameComponent},
      {path: 'extractor-tornillo', component: ExtrctoruComponent},
      {path: 'ferrule-amecoil', component: FerruleameComponent},
      {path: 'fijador-rosca-especial', component: FijadorespComponent},
      {path: 'fijador-rosca-media-resistencia', component: FijadormrComponent},
      {path: 'fijador-rosca-trabajo-pesado', component: FijadortpComponent},
      {path: 'grapas', component: GrapasComponent},
      {path: 'grapas-cuadradas', component: GrapascuadradasComponent},
      {path: 'grasera-metrica', component: GraseramComponent},
      {path: 'grasera-metrica-inoxidable', component: GraseramiComponent},
      {path: 'grasera-metrica-rosca-extrafina', component: GraseramreComponent},
      {path: 'grasera-metrica-rosca-extrafina-inoxidable', component: GraseramreiComponent},
      {path: 'grasera-rosca-caneria', component: GraserarcComponent},
      {path: 'grasera-rosca-caneria-inoxidable', component: GraserarciComponent},
      {path: 'grasera-rosca-fina', component: GraserarfComponent},
      {path: 'grasera-rosca-fina-inoxidable', component: GraserarfiComponent},
      {path: 'grifos-americanos', component: GrifosaComponent},
      {path: 'grifos-metricos', component: GrifosmComponent},
      {path: 'herramienta-para-insertos', component: HerramientainsertoComponent},
      {path: 'herramienta-multidimensional', component: HerramientamultiComponent},
      {path: 'herramienta-para-intalacion-insertos', component: HerramientapiComponent},
      {path: 'hexagonal-agricola', component: HexagonalagricolaComponent},
      {path: 'hexagonal-clase-10-9', component: Hexagonalc10Component},
      {path: 'hexagonal-clase-10-9-rosca-corrida', component: Hexagonalc10rcComponent},
      {path: 'hexagonal-aluminio', component: HexagonalaluminioComponent},
      {path: 'hexagonal-clase-4-8', component: Hexagonalc4Component},
      {path: 'hexagonal-clase-8-8', component: Hexagonalc8Component},
      {path: 'hexagonal-clase-8-8-rosca-corrida', component: Hexagonalc8rcComponent},
      {path: 'hexagonal-bronce', component: HexagonalbronceComponent},
      {path: 'hexagonal-galvanizado', component: HexagonalgComponent},
      {path: 'hexagonal-galvanizado-caliente', component: HexagonalgcComponent},
      {path: 'hexagonal-plastico', component: HexagonalplasticoComponent},
      {path: 'hexagonal-grado-2-rosca-corrida', component: Hexagonalg2rcComponent},
      {path: 'hexagonal-grado-2-rosca-corrida-galvanizado', component: Hexagonalg2rcgComponent},
      {path: 'hexagonal-galvanizado-grado-5-comercial', component: Hexagonalg5cComponent},
      {path: 'hexagonal-galvanizado-rosca-corrida', component: HexagonalgrcComponent},
      {path: 'hexagonal-grado-5-comercial', component: Hexagonalg5comerComponent},
      {path: 'hexagonal-grado-5-rosca-corrida-galvanizado', component: Hexagonalg5rcgComponent},
      {path: 'hexagonal-grado-8-comercial', component: Hexagonalg8cComponent},
      {path: 'hexagonal-grado-8-para-tractor', component: Hexagonalg8ptComponent},
      {path: 'hexagonal-inoxidable', component: HexagonaliComponent},
      {path: 'hexagonal-inoxidable-316', component: Hexagonalinox316Component},
      {path: 'hexagonal-inoxidable-a4', component: Hexagonalinoxa4Component},
      {path: 'hexagonal-inoxidable-a4-rosca-corrida', component: Hexagonalinoxa4rcComponent},
      {path: 'hexagonal-inoxidable-rosca-corrida', component: HexagonalinoxrcComponent},
      {path: 'hexagonal-inoxidable-rosca-fina', component: HexagonalinoxrfComponent},
      {path: 'hexagonal-para-transmision', component: HexagonalptComponent},
      {path: 'hexagonal-rosca-corrida-extrafino', component: HexagonalrceComponent},
      {path: 'hexagonal-rosca-extrafina', component: HexagonalreComponent},
      {path: 'hexagonal-rosca-extrafina-cabeza-pequena', component: HexagonalrecpComponent},
      {path: 'hexagonal-rosca-fina-clase-10-9', component: Hexagonalrfc10Component},
      {path: 'hexagonal-rosca-fina-cabeza-pequena', component: HexagonalrfcpComponent},
      {path: 'hexagonal-rosca-fina-clase-8-8', component: Hexagonalrfc8Component},
      {path: 'hexagonal-rosca-fina-grado-5', component: Hexagonalrfg5Component},
      {path: 'hexagonal-rosca-fina-grado-5-comercial', component: Hexagonalrfg5cComponent},
      {path: 'hexagonal-rosca-fina-grado-8', component: Hexagonalrfg8Component},
      {path: 'hexagonal-rosca-inglesa', component: HexagonalringlesaComponent},
      {path: 'hexagonal-rosca-izquierda', component: HexagonalrizquierdaComponent},
      {path: 'hexagonal-rosca-parcial-fina-clase-10-9', component: Hexagonalrpfc10Component},
      {path: 'tapon-hexagonal-rosca-extrafina', component: HexagonaltaponComponent},
      {path: 'hexagonal-tropicalizado', component: HexagonaltropicalizadoComponent},
      {path: 'hexagonal-tropicalizado-grado-5', component: Hexagonaltropicalizadog5Component},
      {path: 'hexagonal-tropicalizado-rosca-corrida', component: HexagonaltropicalizadorcComponent},
      {path: 'hitch-pin', component: HitchpinComponent},
      {path: 'inserto-amecoil', component: InsertoamecoilComponent},
      {path: 'inserto-autoroscante', component: InsertoautorosComponent},
      {path: 'juego-extractores', component: JuegoextractoresComponent},
      {path: 'juego-seguros-externos', component: JuegoseguroseComponent},
      {path: 'juego-seguros-internos', component: JuegosegurosiComponent},
      {path: 'juego-seguros-internos-externos', component: JuegosegurosieComponent},
      {path: 'kit-amecoil', component: KitamecoilComponent},
      {path: 'kit-insertos-amecoil-extrafino', component: KitamecoilexComponent},
      {path: 'kit-insertos-amecoil-metrico', component: KitamecoilmComponent},
      {path: 'lamina-conico', component: LaminaconicoComponent},
      {path: 'lamina-hexagonal', component: LaminahexagonalComponent},
      {path: 'llave-para-tuerca-esparrogo-seguridad', component: LlaveesparragoseguridadComponent},
      {path: 'lamina-hexagonal-castigadera', component: LaminahexagonalcastigaderaComponent},
      {path: 'lamina-hexagonal-concreto-azul', component: LaminahexagonalconcretoComponent},
      {path: 'lamina-hexagonal-acero-inoxidable', component: LaminahexagonalinoxComponent},
      {path: 'lamina-hexagonal-punta-broca', component: LaminahexagonalpbComponent},
      {path: 'lamina-hexagonal-punta-broca-castigadera', component: LaminahexagonalpbcComponent},
      {path: 'lamina-hexagonal-punta-broca-inoxidable', component: LaminahexagonalpbiComponent},
      {path: 'lamina-hexagonal-punta-broca-pilot', component: LaminahexagonalpbpComponent},
      {path: 'lamina-hexagonal-sin-punta', component: LaminahexagonalspComponent},
      {path: 'lamina-plana-castigadera-inoxidable', component: LaminaplanacinoxComponent},
      {path: 'lamina-plana-phillips-cromado', component: LaminaplanapcromadoComponent},
      {path: 'lamina-plana-phillips-inoxidable', component: LaminaplanapinoxComponent},
      {path: 'lamina-plana-phillips-negro', component: LaminaplanapnegroComponent},
      {path: 'lamina-plana-phillips-punta-broca', component: LaminaplanappbComponent},
      {path: 'lamina-plana-roseta', component: LaminaplanaprComponent},
      {path: 'lamina-plana-torx-galvanizado', component: LaminaplanaptgComponent},
      {path: 'lamina-plano-castigadera', component: LaminaplanocComponent},
      {path: 'lamina-plano-phillips', component: LaminaplanopComponent},
      {path: 'lamina-redonda-phillips-cromado', component: LaminaredondapcComponent},
      {path: 'lamina-redonda-phillips-negro', component: LaminaredondapnComponent},
      {path: 'lamina-redonda-queso-punta-broca', component: LaminaredondaqpbComponent},
      {path: 'lamina-redondo-castigadera', component: LaminaredondocComponent},
      {path: 'lamina-redondo-castigadera-inoxidable', component: LaminaredondociComponent},
      {path: 'lamina-redondo-combinado', component: LaminaredondocombinadoComponent},
      {path: 'lamina-redondo-phillips', component: LaminaredondopComponent},
      {path: 'lamina-redondo-phillips-inoxidable', component: LaminaredondopiComponent},
      {path: 'lamina-redondo-phillips-punta-broca', component: LaminaredondoppbComponent},
      {path: 'lamina-redondo-phillips-punta-broca-inoxidable', component: LaminaredondoppbiComponent},
      {path: 'lamina-redondo-torx-galvanizado', component: LaminaredondotgComponent},
      {path: 'liquido-anti-sobado', component: LiquidoantisobadoComponent},
      {path: 'llave-allen', component: LlaveallenComponent},
      {path: 'llave-allen-esferica', component: LlaveallenesfericaComponent},
      {path: 'llave-allen-seguridad', component: LlaveallenseguridadComponent},
      {path: 'llave-cola-corona', component: LlavecolaComponent},
      {path: 'llave-torx', component: LlavetorxComponent},
      {path: 'lubricante-grado-alimenticio', component: LubricantealimenticioComponent},
      {path: 'lubricante-grado-general', component: LubricantegeneralComponent},
      {path: 'lubricante-grado-inoxidable', component: LubricanteinoxidableComponent},
      {path: 'lubricante-grado-marino', component: LubricantemarinoComponent},
      {path: 'machuelo-caneria-rosca-bsp', component: MachuelobspComponent},
      {path: 'machuelo-caneria-rosca-npt', component: MachuelonptComponent},
      {path: 'machuelo-mecanico-15-grados', component: Machuelomecanico15Component},
      {path: 'machuelo-mecanico-35-grados', component: Machuelomecanico35Component},
      {path: 'machuelo-metrico-extrafino', component: MachuelometricoexComponent},
      {path: 'machuelo-acero-inoxidable', component: MachuelopinoxComponent},
      {path: 'machuelo-amecoil', component: MachueloamecoilComponent},
      {path: 'machuelos', component: MachuelosComponent},
      {path: 'maneral-para-machuelos', component: ManeralmachueloComponent},
      {path: 'maneral-para-tarraja', component: ManeraltarrajaComponent},
      {path: 'maquina-plana', component: MaquinaplanaComponent},
      {path: 'maquina-redonda', component: MaquinaredondaComponent},
      {path: 'maquina-redonda-binding', component: MaquinaredondabComponent},
      {path: 'muela-esparrago-delantero', component: MueladelanteraComponent},
      {path: 'muela-esparrago-trasero', component: MuelatraseraComponent},
      {path: 'oring-bunan', component: OringbunanComponent},
      {path: 'oring-viton', component: OringvitonComponent},
      {path: 'pin-conico', component: PinconicoComponent},
      {path: 'pin-dowel', component: PindowelComponent},
      {path: 'pin-tension', component: PintensionComponent},
      {path: 'pin-tension-americano', component: PintensionamericanoComponent},
      {path: 'pin-tension-inoxidable', component: PintensioninoxidableComponent},
      {path: 'pinza-para-seguros', component: PinzasegurosComponent},
      {path: 'protector', component: ProtectorComponent},
      {path: 'punta-1-barreno', component: Punta1Component},
      {path: 'punta-2-barreno', component: Punta2Component},
      {path: 'punta-3-barreno', component: Punta3Component},
      {path: 'remache-golpe-aluminio', component: RemachealuminioComponent},
      {path: 'remache-golpe-hierro', component: RemachehierroComponent},
      {path: 'remache-pop', component: RemachepopComponent},
      {path: 'remache-pop-ala-ancha', component: RemachepopaaComponent},
      {path: 'remache-pop-color-blanco', component: RemachepopcbComponent},
      {path: 'remache-pop-color-negro', component: RemachepopcnComponent},
      {path: 'remache-pop-color-tabaco', component: RemachepopctComponent},
      {path: 'remache-pop-inoxidable', component: RemachepopinoxidableComponent},
      {path: 'resorte-para-compresion', component: ResortecompresionComponent},
      {path: 'resorte-con-pata', component: ResorteconpataComponent},
      {path: 'resorte-para-estirar', component: ResorteestirarComponent},
      {path: 'resorte-sl', component: ResorteslComponent},
      {path: 'roldana-ajuste-01-mm', component: Roldanaajuste01Component},
      {path: 'roldana-ajuste-03-mm', component: Roldanaajuste03Component},
      {path: 'roldana-ajuste-05-mm', component: Roldanaajuste05Component},
      {path: 'roldana-biselada', component: RoldanabiseladaComponent},
      {path: 'roldana-color-natural', component: RoldanacnaturalComponent},
      {path: 'roldana-comercial', component: RoldanacomercialComponent},
      {path: 'roldana-concava', component: RoldanaconcavaComponent},
      {path: 'roldana-ajuste-1-mm', component: Roldanaajuste1Component},
      {path: 'roldana-aluminio', component: RoldanaaluminioComponent},
      {path: 'roldana-aluminio-sellar', component: RoldanaaluminiosellarComponent},
      {path: 'roldana-anillo-inoxidable-316', component: Roldanaanilloinox316Component},
      {path: 'roldana-cobre-sellar', component: RoldanacobreComponent},
      {path: 'roldana-lujo', component: RoldanalujoComponent},
      {path: 'roldana-plastico', component: RoldanaplasticoComponent},
      {path: 'roldana-seguridad-tipo-1', component: Roldanaseguridad1Component},
      {path: 'roldana-seguridad-tipo-2', component: Roldanaseguridad2Component},
      {path: 'roldana-seguridad-tipo-4', component: Roldanaseguridad4Component},
      {path: 'roldana-seguridad-tipo-5', component: Roldanaseguridad5Component},
      {path: 'roldana-seguridad-tipo-6', component: Roldanaseguridad6Component},
      {path: 'roldana-tapiceria', component: RoldanatapiceriaComponent},
      {path: 'roldana-estructural', component: RoldanaestructuralComponent},
      {path: 'roldana-fender', component: RoldanafenderComponent},
      {path: 'roldana-fender-inoxidable', component: RoldanafenderinoxComponent},
      {path: 'roldana-galvanizada', component: RoldanagalvanizadaComponent},
      {path: 'roldana-galvanizada-anillo', component: RoldanagalvanizadaanilloComponent},
      {path: 'roldana-galvanizada-caliente', component: RoldanagalvanizadacalienteComponent},
      {path: 'roldana-gruesa-sujecion', component: RoldanagruesasujecionComponent},
      {path: 'roldana-inoxidable', component: RoldanainoxidableComponent},
      {path: 'roldana-inoxidable-316', component: Roldanainoxidable316Component},
      {path: 'roldana-inoxidable-a4', component: Roldanainoxidablea4Component},
      {path: 'roldana-inoxidable-anillo', component: RoldanainoxidableanilloComponent},
      {path: 'roldana-natural', component: RoldananaturalComponent},
      {path: 'roldana-ondulada', component: RoldanaonduladaComponent},
      {path: 'roldana-ondulada-tropicalizada', component: RoldanaonduladatropicalizadaComponent},
      {path: 'roldana-tropicalizada', component: RoldanatropicalizadaComponent},
      {path: 'roldana-bronce', component: RoldanabronceComponent},
      {path: 'seguro-araña', component: SeguroaranaComponent},
      {path: 'seguro-clip-eje', component: SeguroclipComponent},
      {path: 'seguro-clip-eje-inoxidable', component: SeguroclipinoxComponent},
      {path: 'seguro-externo-eje', component: SeguroexternoComponent},
      {path: 'seguro-externo-eje-inoxidable', component: SeguroexternoinoxComponent},
      {path: 'seguro-sujecion-sin-ranura', component: SegurosujecionranuraComponent},
      {path: 'seguro-sujecion-tipo-anillo', component: SegurosujecionanilloComponent},
      {path: 'seguro-interno-agujero', component: SegurointernoComponent},
      {path: 'seguro-interno-agujero-inoxidable', component: SegurointernoinoxComponent},
      {path: 'seguro-caja', component: SegurocajaComponent},
      {path: 'sierra', component: SierraComponent},
      {path: 'silicon-gris', component: SilicongrisComponent},
      {path: 'silicon-negro', component: SiliconnegroComponent},
      {path: 'silicon-rojo', component: SiliconrojaComponent},
      {path: 'silicon-transparente', component: SilicontransparenteComponent},
      {path: 'soldadura-frio', component: SoldadurafrioComponent},
      {path: 'soldadura-frio-transparente', component: SoldaduratransparenteComponent},
      {path: 'superglue', component: SuperglueComponent},
      {path: 'tabla-medidora', component: TablaComponent},
      {path: 'tabla-yeso', component: TablayesoComponent},
      {path: 'tabla-yeso-cabeza-corneta', component: TablayesocornetaComponent},
      {path: 'tabla-yeso-cabeza-corneta-plateado', component: TablayesocornetadacrometComponent},
      {path: 'tabla-yeso-corneta-doble-rosca', component: TablayesocornetadobleroscaComponent},
      {path: 'tabla-yeso-cabeza-oblea-punta-broca', component: TablayesoobleapbComponent},
      {path: 'tabla-yeso-cabeza-oblea-punta-broca-alas', component: TablayesoobleapbaComponent},
      {path: 'tabla-yeso-cabeza-plana-recortada', component: TablayesoplanarecortadaComponent},
      {path: 'tabla-yeso-cabeza-redonda', component: TablayesoredondaComponent},
      {path: 'tabla-yeso-cabeza-redonda-pache', component: TablayesoredondapComponent},
      {path: 'tabla-yeso-corneta-quadrex', component: TablayecornetaquadrexComponent},
      {path: 'tabla-yeso-fino', component: TablayefinoComponent},
      {path: 'tabla-yeso-plano-comercial', component: TablayeplanocomercialComponent},
      {path: 'tabla-yeso-punta-broca-alas', component: TablayesopbaComponent},
      {path: 'tabla-yeso-plano-pilot-quadrex', component: TablayesoplanopilotComponent},
      {path: 'tabla-yeso-recortado-punta-broca', component: TablayesorecortadopbComponent},
      {path: 'tabla-yeso-cabeza-redonda-ordinario', component: TablayesoredondaoComponent},
      {path: 'tabla-yeso-Punta-Broca-Pilot-Quadrex', component: TablayesopbpqComponent},
      {path: 'tabla-yeso-Punta-Broca', component: TablayesopbComponent},
      {path: 'tabla-yeso-Punta-Broca-galvanizado', component: TablayesopbgComponent},
      {path: 'tabla-yeso-Punta-Broca-ruspert', component: TablayesopbrComponent},
      {path: 'tabla-yeso-Redondo-Punta-Broca', component: TablayesorpbComponent},
      {path: 'tabla-yeso-Redondo-Punta-Broca-galvanizado', component: TablayesorpbgComponent},
      {path: 'tabla-yeso-Redondo-Roldana', component: TablayesorrComponent},
      {path: 'tabla-yeso-redondo-roldana-negro', component: TablayesorrnComponent},
      {path: 'tabla-yeso-redondo-roldana-punta-broca', component: TablayesorrpbComponent},
      {path: 'tabla-yeso-redondo-roldana-punta-broca-negro', component: TablayesorrpbnComponent},
      {path: 'tabla-yeso-tropicalizado', component: TablayesotComponent},
      {path: 'tapon-allen', component: TaponallenComponent},
      {path: 'tapon-allen-rosca-bsp', component: TaponallenbspComponent},
      {path: 'tapon-allen-rosca-npt', component: TaponallennptComponent},
      {path: 'tapon-hexagonal', component: TaponhexagonalComponent},
      {path: 'tarraja-hssg', component: TarrajahssgComponent},
      {path: 'tarrajas', component: TarrajasComponent},
      {path: 'tarugo-expansion', component: TarugoexpansionComponent},
      {path: 'tarugo-expansion-doble', component: TarugoexpansiondobleComponent},
      {path: 'tarugo-fisher', component: TarugofisherComponent},
      {path: 'tarugo-fisher-comercial', component: TarugofishercomercialComponent},
      {path: 'tarugo-plastico', component: TarugoplasticoComponent},
      {path: 'teflon-liquido', component: TeflonliquidoComponent},
      {path: 'termometro-digital', component: TermometrodigitalComponent},
      {path: 'tipo-spax', component: TipospaxComponent},
      {path: 'tipo-spax-negro', component: TipospaxnegroComponent},
      {path: 'tornillo-carrocero', component: TornillocarroceroComponent},
      {path: 'tornillo-carrocero-galvanizado', component: TornillocarrocerogalvanizadoComponent},
      {path: 'tornillo-cabeza-cuadrada', component: TornillocuadradoComponent},
      {path: 'tornillo-estufa-hexagonal', component: TornilloestufohexagonalComponent},
      {path: 'tornillo-estufa-hexagonal-rosca-fina', component: TornilloestufohexagonalfinoComponent},
      {path: 'tornillo-cabeza-plana-seguridad', component: TornilloplanoseguridadComponent},
      {path: 'tornillo-carrocero-grado-5', component: Tornillocarrocerog5Component},
      {path: 'tornillo-carrocero-Inoxidable', component: TornillocarroceroinoxComponent},
      {path: 'tornillo-centro', component: TornillocentroComponent},
      {path: 'tornillo-centro-metrico', component: TornillocentrometricoComponent},
      {path: 'tornillo-elevador', component: TornilloelevadorComponent},
      {path: 'tornillo-elevador-galvanizado', component: TornilloelevadorgalvanizadoComponent},
      {path: 'tornillo-elevador-puntas', component: TornilloelevadorpuntasComponent},
      {path: 'tornillo-elevador-inoxidable', component: TornilloelevadorinoxComponent},
      {path: 'tornillo-apretar-dedos', component: TornilloestdComponent},
      {path: 'tornillo-estructural-a-325', component: TornilloestructuralComponent},
      {path: 'tornillo-madera-cabeza-plana', component: TornillomaderaplanoComponent},
      {path: 'tornillo-cangilon', component: TornillocangilonComponent},
      {path: 'tornillo-madera-ojo', component: TornillomaderaojoComponent},
      {path: 'tornillo-madera-punta-astrillada', component: TornillomaderapaComponent},
      {path: 'tornillo-madera-redondo-punta-astrillada', component: TornillomaderarpaComponent},
      {path: 'tornillo-madera-redondo-roldana', component: TornillomaderarrComponent},
      {path: 'tornillo-mueble-allen-conico', component: TornillomuebleacComponent},
      {path: 'tornillo-mueble-allen-tipo-roldana', component: TornillomuebleatpComponent},
      {path: 'tornillo-mueble-doble-punta', component: TornillomuebledpComponent},
      {path: 'tornillo-mueble-galvanizado', component: TornillomuebledpgComponent},
      {path: 'tornillo-plano-doble-rosca-concreto-azul', component: TornillopdrcComponent},
      {path: 'tornillo-prisionero-cabeza-cuadrada', component: TornilloprisionerocuadradoComponent},
      {path: 'tornillo-rastra-grado-5', component: Tornillorastrag5Component},
      {path: 'tornillo-tirabuzon', component: TornillotirabuzonComponent},
      {path: 'tornillo-tirabuzon-inoxidable', component: TornillotirabuzoninoxComponent},
      {path: 'tornillo-torx-12-lados', component: Tornillotorx12ladosComponent},
      {path: 'tornillo-torx-12-lados-rosca-fina', component: Tornillotorx12ladosfinoComponent},
      {path: 'tornillo-torx-cabeza-cilindrica-galvanizado', component: TorxcilindricogComponent},
      {path: 'tornillo-torx-cabeza-boton-galvanizado', component: TorxbotonComponent},
      {path: 'tornillo-torx-cabeza-plana-galvanizado', component: TorxplanoComponent},
      {path: 'tuerca-2h', component: Tuerca2hComponent},
      {path: 'tuerca-allen-cilindrica-roldana', component: TuercaallencrComponent},
      {path: 'tuerca-anclaje-mariposa', component: TuercaanclajemComponent},
      {path: 'tuerca-artillera', component: TuercaartilleraComponent},
      {path: 'tuerca-b7', component: Tuercab7Component},
      {path: 'tuerca-seguro-golpe', component: TuercasegurogolpeComponent},
      {path: 'tuerca-busca-rosca-allen', component: TuercabraComponent},
      {path: 'tuerca-busca-rosca-allen-roldana', component: TuercabrarComponent},
      {path: 'tuerca-chucho-bolita-rosca-extrafina', component: TuercacbreComponent},
      {path: 'tuerca-chucho-bolita-rosca-fina', component: TuercacbrfComponent},
      {path: 'tuerca-campana-extrafina', component: TuercaceComponent},
      {path: 'tuerca-campana-extrafina-derecha', component: TuercacedComponent},
      {path: 'tuerca-campana-extrafina-izquierda', component: TuercaceiComponent},
      {path: 'tuerca-campana-rosca-fina-derecha', component: TuercacfdComponent},
      {path: 'tuerca-campana-rosca-fina-izquierda', component: TuercacfiComponent},
      {path: 'tuerca-chucho-lujo-rosca-extrafina', component: TuercaclreComponent},
      {path: 'tuerca-chucho-lujo-rosca-fina', component: TuercaclrfComponent},
      {path: 'tuerca-chucho-lujo-tubo-rosca-extrafina', component: TuercacltreComponent},
      {path: 'tuerca-chucho-lujo-tubo-rosca-fina', component: TuercacltrfComponent},
      {path: 'tuerca-chucho-seguridad-rosca-extrafina', component: TuercacseComponent},
      {path: 'tuerca-chucho-seguridad-rosca-fina', component: TuercacsfComponent},
      {path: 'tuerca-chucho-extrafina-forjada', component: TuercacefComponent},
      {path: 'tuerca-chucho-forjada', component: TuercacfComponent},
      {path: 'tuerca-chucho-rosca-extrafina', component: TuercacreComponent},
      {path: 'tuerca-chucho-rosca-fina', component: TuercacrfComponent},
      {path: 'tuerca-chucho-rosca-fina-izquierdo', component: TuercacrfiComponent},
      {path: 'tuerca-chucho-rosca-ordinaria', component: TuercacroComponent},
      {path: 'tuerca-chucho', component: TuercachuchoComponent},
      {path: 'tuerca-cilindrica-allen-boton', component: TuercacilindricaabComponent},
      {path: 'tuerca-cilindrica-allen-Roldana', component: TuercacilindricaacrComponent},
      {path: 'tuerca-cilindrica-allen-lisa', component: TuercacilindricaalComponent},
      {path: 'tuerca-cilindrica-astrillada', component: TuercacilindricaastrilladaComponent},
      {path: 'tuerca-bronce', component: TuercabronceComponent},
      {path: 'tuerca-cuadrada', component: TuercacuadradaComponent},
      {path: 'tuerca-capirucho-derecha', component: TuercacapiruchoComponent},
      {path: 'tuerca-capirucho-izquierda', component: TuercacapiruchoiComponent},
      {path: 'tuerca-chucho-izquierda', component: TuercachuchoiComponent},
      {path: 'tuerca-chucho-lujo', component: TuercachucholujoComponent},
      {path: 'tuerca-chucho-lujo-izquierda', component: TuercachucholujoiComponent},
      {path: 'tuerca-chucho-lujo-tubo-roldana', component: TuercachucholujotrComponent},
      {path: 'tuerca-chucho-Tubo', component: TuercachuchotuboComponent},
      {path: 'tuerca-copla', component: TuercacoplaComponent},
      {path: 'tuerca-copla-inoxidable', component: TuercacoplaiComponent},
      {path: 'tuerca-corona', component: TuercacoronaComponent},
      {path: 'tuerca-corona-rosca-extrafina', component: TuercacoronaeComponent},
      {path: 'tuerca-corona-rosca-fina', component: TuercacoronafComponent},
      {path: 'tuerca-corona-rosca-ordinaria', component: TuercacoronaoComponent},
      {path: 'tuerca-insercion', component: TuercainsercionComponent},
      {path: 'tuerca-insercion-metrica', component: TuercainsercionmComponent},
      {path: 'tuerca-lana-rosca-fina', component: TuercalanaComponent},
      {path: 'tuerca-mariposa', component: TuercamariposaComponent},
      {path: 'tuerca-mariposa-rosca-fina', component: TuercamariposafComponent},
      {path: 'tuerca-mariposa-inoxidable', component: TuercamariposainoxComponent},
      {path: 'tuerca-plastico', component: TuercaplasticaComponent},
      {path: 'tuerca-seguridad-metal-rosca-extrafina', component: TuercaseguridadmreComponent},
      {path: 'tuerca-seguridad-metal-rosca-fina', component: TuercaseguridadmrfComponent},
      {path: 'tuerca-seguridad-plastica-rosca-extrafina', component: TuercaseguridadpreComponent},
      {path: 'tuerca-seguridad-plastica-rosca-fina', component: TuercaseguridadprfComponent},
      {path: 'tuerca-seguridad-roldana', component: TuercaseguridadrComponent},
      {path: 'tuerca-seguro-conico-rosca-fina', component: TuercaseguridadcrfComponent},
      {path: 'tuerca-seguro-golpe-anillo', component: TuercaseguridadgaComponent},
      {path: 'tuerca-seguro-golpe-anillo-alta', component: TuercaseguridadgaaComponent},
      {path: 'tuerca-seguro-golpe-corona', component: TuercaseguridadgcComponent},
      {path: 'tuerca-seguro-golpe-izquierda', component: TuercaseguridadgiComponent},
      {path: 'tuerca-seguro-golpe-mediana', component: TuercaseguridadgmComponent},
      {path: 'tuerca-seguro-golpe-ovalada', component: TuercaseguridadgoComponent},
      {path: 'tuerca-seguro-golpe-roldana', component: TuercaseguridadgrComponent},
      {path: 'tuerca-seguridad-plastica', component: TuercaseguridadplasticaComponent},
      {path: 'tuerca-seguro-plastico-rosca-fina-pache', component: TuercaseguridadplasticafpComponent},
      {path: 'tuerca-seguridad-plastica-inoxidable', component: TuercaseguridadplasticainoxComponent},
      {path: 'tuerca-seguridad-metal', component: TuercaseguridadmetalComponent},
      {path: 'tuerca-seguro-golpe-pache', component: TuercaseguridadgpComponent},
      {path: 'tuerca-seguro-golpe-pache-roldana', component: TuercaseguridadgprComponent},
      {path: 'tuerca-tapon', component: TuercataponComponent},
      {path: 'tuerca-tapon-inoxidable', component: TuercataponinoxComponent},
      {path: 'tuerca-hexagonal', component: TuercahexagonalComponent},
      {path: 'tuerca-hexagonal-bronce', component: TuercahexagonalalbronceComponent},
      {path: 'tuerca-hexagonal-aluminio', component: TuercahexagonalaluminioComponent},
      {path: 'tuerca-hexagonal-canera-derecha', component: TuercahexagonalcaneradComponent},
      {path: 'tuerca-hexagonal-canera-izquierda', component: TuercahexagonalcaneraiComponent},
      {path: 'tuerca-hexagonal-corona-munon', component: TuercahexagonalcmComponent},
      {path: 'tuerca-hexagonal-ranura', component: TuercahexagonalranuraComponent},
      {path: 'tuerca-hexagonal-ranura-rosca-fina', component: TuercahexagonalranurafComponent},
      {path: 'tuerca-hexagonal-bronce-rosca-fina', component: TuercahexagonalbroncefComponent},
      {path: 'tuerca-hexagonal-corona-extrafina', component: TuercahexagonalcoronaeComponent},
      {path: 'tuerca-hexagonal-estructural', component: TuercahexagonalestructuralComponent},
      {path: 'tuerca-hexagonal-galvanizada', component: TuercahexagonalgalvanizadaComponent},
      {path: 'tuerca-hexagonal-galvanizada-clase-4-8', component: Tuercahexagonalgalvanizadac4Component},
      {path: 'tuerca-hexagonal-galvanizada-caliente', component: TuercahexagonalgalvanizadacalienteComponent},
      {path: 'tuerca-hexagonal-galvanizada-grado-2', component: Tuercahexagonalgalvanizadag2Component},
      {path: 'tuerca-hexagonal-galvanizada-grado-5', component: Tuercahexagonalgalvanizadag5Component},
      {path: 'tuerca-hexagonal-grado-2', component: Tuercahexagonalgrado2Component},
      {path: 'tuerca-hexagonal-grado-5', component: Tuercahexagonalgrado5Component},
      {path: 'tuerca-hexagonal-inglesa', component: TuercahexagonalinglesaComponent},
      {path: 'tuerca-hexagonal-inoxidable', component: TuercahexagonalinoxidableComponent},
      {path: 'tuerca-hexagonal-inoxidable-316', component: Tuercahexagonalinoxidable316Component},
      {path: 'tuerca-hexagonal-inoxidable-a4', component: Tuercahexagonalinoxidablea4Component},
      {path: 'tuerca-hexagonal-inoxidable-rosca-fina', component: TuercahexagonalinoxidablerfComponent},
      {path: 'tuerca-hexagonal-izquierda', component: TuercahexagonalizquierdaComponent},
      {path: 'tuerca-hexagonal-izquierda-rosca-fina', component: TuercahexagonalizquierdarfComponent},
      {path: 'tuerca-hexagonal-pache', component: TuercahexagonalpacheComponent},
      {path: 'tuerca-hexagonal-pache-inoxidable', component: TuercahexagonalpacheinoxComponent},
      {path: 'tuerca-hexagonal-rosca-extrafina', component: TuercahexagonalreComponent},
      {path: 'tuerca-hexagonal-rosca-extrafina-pache', component: TuercahexagonalrepComponent},
      {path: 'tuerca-hexagonal-rosca-fina', component: TuercahexagonalrfComponent},
      {path: 'tuerca-hexagonal-rosca-fina-galvanizada', component: TuercahexagonalrfgComponent},
      {path: 'tuerca-hexagonal-rosca-fina-inoxidable', component: TuercahexagonalrfiComponent},
      {path: 'tuerca-hexagonal-rosca-fina-pache', component: TuercahexagonalrfpComponent},
      {path: 'tuerca-hexagonal-tropicalizada', component: TuercahexagonaltropicalizadaComponent},
      {path: 'tuerca-muesca-rosca-fina', component: TuercamuescaComponent},
      {path: 'tuerca-pache', component: TuercapacheComponent},
      {path: 'tuerca-pache-rosca-fina-inoxidable', component: TuercapachefiComponent},
      {path: 'tuerca-seguridad-munon', component: TuercaseguridadmunonComponent},
      {path: 'tuerca-tapon-allen-cilindrica', component: TuercataponallencComponent},
      {path: 'tuerca-tapon-allen-cilindrica-roldana', component: TuercataponallencrComponent},
      {path: 'tuerca-tractor-rosca-fina', component: TuercatractorComponent},
      {path: 'tuerca-transversal', component: TuercatransversalComponent},
      {path: 'washa-presion', component: WashaComponent},
      {path: 'washa-allen', component: WashaallenComponent},
      {path: 'washa-allen-inoxidable', component: WashaalleniComponent},
      {path: 'washa-aluminio', component: WashaaluminioComponent},
      {path: 'washa-conica', component: WashaconicaComponent},
      {path: 'washa-bronce', component: WashabronceComponent},
      {path: 'washa-presion-comercial', component: WashacomercialComponent},
      {path: 'washa-presion-conica-americana', component: WashaconicaaComponent},
      {path: 'washa-estrella-conica', component: WashaestrellacComponent},
      {path: 'washa-estrella-externa', component: WashaestrellaeComponent},
      {path: 'washa-estrella-externa-inoxidable', component: WashaestrellaeiComponent},
      {path: 'washa-estrella-externa-metrica', component: WashaestrellaeimComponent},
      {path: 'washa-estrella-interna', component: WashaestrellaeinternaComponent},
      {path: 'washa-galvanizada', component: WashagalvanizadaComponent},
      {path: 'washa-galvanizada-caliente', component: WashagalvanizadacComponent},
      {path: 'washa-galvanizada-metrica', component: WashagalvanizadamComponent},
      {path: 'washa-inoxidable', component: WashainoxidableComponent},
      {path: 'washa-inoxidable-316', component: Washainoxidable316Component},
      {path: 'washa-metrica', component: WashametricaComponent},
      {path: 'washa-tropicalizada', component: WashatropicalizadaComponent},
      {path: 'hexagonal-grado-5', component: Hexagonalg5Component},
      {path: 'hexagonal-galvanizado-grado-5', component: Hexagonalg5gComponent},
      {path: 'barra-roscada-fina', component: PeabafComponent},
      {path: 'barra-roscada-izquierda', component: PeabaiComponent},
      {path: 'barra-roscada-acme', component: PeabaacmeComponent},
      {path: 'fresa-carburo', component: FrecarComponent},
      {path: 'fresa-circular-hss', component: FrecirComponent},
      {path: 'fresa-circular-hss-ordinario', component: FrecirorComponent},
      {path: 'fresa-cobalto', component: FrecobComponent},
      {path: 'fresa-cobalto-desbaste', component: FrecobdesComponent},
      {path: 'fresa-cunero', component: FrecuComponent},
      {path: 'aceite-corte', component: AcecorComponent},
      {path: 'barra-roscada-izquierda', component: AbaiComponent},
      {path: 'esparrago-canero-izquierdo', component: AeciComponent},
      {path: 'grasera-rosca-ordinaria', component: AgrcComponent},
      {path: 'excentrico-rosca-ordinaria', component: MtexcencComponent},
      {path: 'allen-cilindrico-inoxidable-metrico', component: Ma188Component},
      {path: 'broca-centro', component: BrcenComponent},
      {path: 'buril-carburo-clase-6-derecho', component: Burilc6dComponent},
      {path: 'buril-cobalto', component: Burilcob8Component},
      {path: 'fresa-carburo-desbaste', component: FrecardesComponent},
      {path: 'desarmador-torx', component: DtorxComponent},
      {path: 'llave-puntas-seguridad', component: LlpsComponent},
      {path: 'allen-plano-rosca-izquierda', component: MapiComponent},
      {path: 'tornillo-tirabuzon-galvanizado', component: AtgComponent},
      {path: 'tuerca-acme-trapezoidal', component: TacmeComponent},
      {path: 'lamina-hexagonal-recubrimiento-punta-aguda', component: Lh4rpaComponent},
      {path: 'lamina-plana-philips-punta-broca-inoxidable', component: Lppb188Component},
      {path: 'machuelo-metrico', component: MmcComponent},
      //{path: 'carrera-esp', component: CarreraEspComponent},
      {path: 'tarugo-diente-tiburon', component: AtdtComponent},
      {path: 'tarugo-diente-tiburon-zamak', component: AtdzComponent},
      {path: 'lamina-plana-seguridad', component: LplsComponent},
      {path: 'barra-roscada-estructural', component: PeabaecComponent},
      {path: 'punta-cuadrada', component: PuntacComponent},
      {path: 'lamina-hexagonal-punta-broca-casa-tornillo', component: LhpbctComponent},
      {path: 'allen-cilindrico-galvanizado', component: MagComponent},

    ]},
  {path: '**', redirectTo: '/tornillo/inicio'}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
