import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { elementAt } from 'rxjs/operators';
import { ProductosComponent } from './../productos.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-buscar',
  templateUrl: './buscar.component.html',
  styleUrls: ['./buscar.component.scss']
})
export class BuscarComponent implements OnInit {

  sum : any;
  name : any;
  a : any;
  i : any;
  elemtos: any;
  public productos = [];
  public productos2 = [];
  public productos3 = [];
  public productos4 = [];
  numero: any;
  numero2: any;
  num: any;
  num2: any;
  public lista = [];


  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public dialogo : ProductosComponent
  ) {
    this.numero = 0;
    this.numero2 = 0;
    this.sum = 0;
   }

  ngOnInit(): void {
    this.inicio();
  }

  mostrarDialogo(){
    this.dialogo.mostrarDialogo();
  }

  borrar(){
    if(isPlatformBrowser(this.platformId)){
    //compara si el input de texto esta vacio 
    if (this.name != null){
      const insert = document.getElementById("todo");
      
      //La variable i contiene el numero de productos devueltos
      if (this.i != 0){
        const ins = document.getElementById("resultado")
        
        //ciclo que quita todos los elementos de la pantalla resultado
        for (this.a=0; this.a<=this.i; this.a++){
          const all = document.getElementById("all");
          insert.appendChild(all);
        }
        ins.innerHTML = "";
        this.productos = [];
        this.numero = 0;
        this.productos2 = [];
        this.numero2 = 0;
        this.productos3 = [];
        this.productos4 = [];
      
      }else{
          
        if (this.sum == 0){
          const ins = document.getElementById("resultado");
          ins.innerHTML = "Sin Resultados...";
        }
      }
      
        this.Buscar();
      }else{
        alert('Debe ingresar un texto a buscar');
      }
      }

    }

    Buscar(){
      if(isPlatformBrowser(this.platformId)){
      this.sum = 0;
      //referencia al div resultado
       const insert = document.getElementById("resultado");
       
       
       
       console.log(this.name);
       
      //comvierte a minusculas la cadena de texto del input 
       this.elemtos = this.name.toLowerCase();
       this.name = null;
  
      console.log(this.elemtos);
      if (this.elemtos == "galvanizado en caliente") {
        this.elemtos = "en caliente"

      }

      if (this.elemtos == "galvanizada en caliente") {
        this.elemtos = "en caliente"
      }

      if (this.elemtos == "galvanizado"){
        this.elemtos = "galvanizad"
      }

      if (this.elemtos == "galvanizada"){
        this.elemtos = "galvanizad"
      }

      if (this.elemtos == "redonda"){
        this.elemtos = "redond"
      }

      if (this.elemtos == "redondo"){
        this.elemtos = "redond"
      }

      if (this.elemtos == "cabeza plana"){
        this.elemtos = "plan"
      }

      if (this.elemtos == "plano"){
        this.elemtos = "plan"
      }

      if (this.elemtos == "plana"){
        this.elemtos = "plan"
      }

      if (this.elemtos == "izquierda"){
        this.elemtos = "izquierd"
      }

      if (this.elemtos == "izquierdo"){
        this.elemtos = "izquierd"
      }

      if (this.elemtos == "tornillo izquierdo"){
        this.elemtos = "izquierd"
      }

      if (this.elemtos == "acero inoxidable"){
        this.elemtos = "inoxidable"
      }

      if (this.elemtos == "tornillo inoxidable"){
        this.elemtos = "inoxidable"
      }

      if (this.elemtos == "tuerca plastica"){
        this.elemtos = "tuerca plastic"
      }

      if (this.elemtos == "tuerca plastico"){
        this.elemtos = "tuerca plastic"
      }

      if (this.elemtos == "epoxy"){
        this.elemtos = "soldadura en frio"
      }

      //referencia a todos los documentos con nombre all 
       const move = document.getElementsByName("allb");
       
      //separa la cadena en palabras
       const prod = this.elemtos;
       const prue = prod.split(' ');
       const nump = prue.length;
       console.log(nump);
       
      if (nump==2){
        //busca los nombres que tienen relacion a las palabras introducidas
        move.forEach(element => {
          
          const comp = element.textContent.toLowerCase();
          const ll = comp.indexOf(prue[0]);
          const tt = comp.indexOf(prue[1]);
          if (ll != -1 && tt != -1){
            this.productos[this.numero]=element.textContent.toLowerCase();
            this.numero = this.numero + 1;

          }

          if (ll != -1 || tt != -1){
            this.productos2[this.numero2]=element.textContent.toLowerCase();
            this.numero2 = this.numero2 + 1;

          }
          
        });

        //ordena en eorden alfabetico las dos cadenas
        this.productos.sort();
        this.productos2.sort();
        
        //concatena las dos cadena
        this.productos3 = this.productos.concat(this.productos2);

        const cant = this.productos3.length;

        const todo = document.getElementsByName("allb");
 
        //ciclo que busca los elementos segun los nombres de la cadena 
        for (this.num=0; this.num<=cant; this.num++){
          todo.forEach(element => {
            const text = element.textContent.toLocaleLowerCase();
            const search = text.indexOf(this.productos3[this.num]);
            if (search != -1){
              const busqueda = this.productos4.indexOf(text);
              if (busqueda == -1){
                this.productos4.push(text);
                insert.appendChild(element);
                this.sum = this.sum + 1;
                
              }       
            }
          });
        }

      }else{
      
       //recorrido de los elementos recuperodos con nombre all 
        move.forEach(element => {
          //comvierte a minuscula el texto de los elementos y 
          //luego busca en el texto las palabras ingresadas en el input
          const comp = element.textContent.toLowerCase();       
          const ll = comp.indexOf(this.elemtos);
          
          //si encuentra una palabra en el texto,
          //inserta el elemento en el div "resultados"
          if (ll != -1){
            this.productos[this.numero] = element.textContent.toLowerCase();
            this.numero = this.numero + 1;
            //insert.appendChild(element);
            //this.sum = this.sum + 1;
          }
         
        })
        const cantidad = this.productos.length;
        this.productos.sort();
        for (this.num=0; this.num<=cantidad; this.num++){
          move.forEach(element => {
            const comp = element.textContent.toLowerCase();
            const ll = comp.indexOf(this.productos[this.num]);
            if (ll != -1){
              insert.appendChild(element);
              this.sum = this.sum + 1;
            }  
          })
        }
  
      }
      console.log(this.sum);
        if (this.sum === 0){
          this.buscarlista();
        }
       
       this.i = move.length;
       console.log(this.i);
      }
    }

    inicio(){
      this.name = this.dialogo.name;
      this.borrar();
    }

    buscarlista(){
      if(isPlatformBrowser(this.platformId)){
      const pin = ['pin canela', 'pin tension', 'pin caña', 'pin abierto', 'spring pin'];
      const plana = ['avellanada', 'cabeza avellanada', 'conico', 'tornillo conico'];
      const inoxidable = ['a304', 'a316', 'acero inoxidable', 'tornillo inoxidable'];
      const estructural = ['tonillo a325', 'tuerca a325', 'roldana a325', 'a 325', 'a325'];
      const grado = ['a36', 'a 36', 'tornillo a36'];
      const shim = ['alza', 'alzas', 'disco de distancia'];
      const roldana = ['arandela', 'arandelas'];
      const maneral = ['brazo bandeador', 'porta machuelos', 'volvedor'];
      const carrocero = ['bomper', 'cabeza de remache'];
      const castigador = ['allen sin cabeza', 'granitos', 'prisionero'];
      const mueble = ['esparrago busca rosca'];
      const hitchpin = ['grapas de sujecion'];
      const allen = ['hexagonal interno','tornillo allen'];
      const inserto = ['helicoides'];
      const grapa = ['laminitas', 'laña', 'tuerca rapida'];
      const esferica = ['llave de bola'];
      const muela = ['mariposa', 'mariposas'];
      const seguro = ['micky mouse', 'seguro omega', 'seguro seger'];
      const barra = ['pernos', 'sin fin', 'tornillo sin fin'];
      const vernier = ['pie de rey'];
      const fisher = ['s8'];
      const sangrador = ['grifo','sangrador','tornillo sangrador'];
      const sujecion = ['seguro de anillo', 'seguro anillo', 'seguro c'];
      const clip = ['seguro e', 'seguro tipo e'];
      const tapiceria = ['sombreritos'];
      const tarraja = ['terraja', 'terrajas', 'tarrajas'];
      const expansion = ['expansion', 'tarugo expansivo', 'doble expansion', 'dropy', 'tarugo dropy'];
      const redondo = ['wafer', 'tornillo wafer'];
      const redonda = ['tornillo gota', 'tornillo cabeza de gota', 'cabeza de gota'];
      const caliente = ['Galvanizado en caliente'];
      const estufa = ['tornillo estufa'];
      const hexagonal = ['tornillo hexagonal'];
      const plastico = ['tornillo plastico'];
      const aluminio = ['tornillo de aluminio', 'tornillo aluminio'];
      const bronce = ['tornillo de bronce', 'tornillo bronce'];
      const maquina = ['tornillo maquina', 'tornillo para maquina'];
      
     
      const l1 = pin.indexOf(this.elemtos);
      const l2 = plana.indexOf(this.elemtos);
      const l3 = inoxidable.indexOf(this.elemtos);
      const l4 = estructural.indexOf(this.elemtos);
      const l5 = grado.indexOf(this.elemtos);
      const l6 = shim.indexOf(this.elemtos);
      const l7 = roldana.indexOf(this.elemtos);
      const l8 = maneral.indexOf(this.elemtos);
      const l9 = carrocero.indexOf(this.elemtos);
      const l10 = castigador.indexOf(this.elemtos);
      const l11 = mueble.indexOf(this.elemtos);
      const l12 = hitchpin.indexOf(this.elemtos);
      const l13 = allen.indexOf(this.elemtos);
      const l14 = inserto.indexOf(this.elemtos);
      const l15 = esferica.indexOf(this.elemtos);
      const l16 = muela.indexOf(this.elemtos);
      const l17 = seguro.indexOf(this.elemtos);
      const l18 = barra.indexOf(this.elemtos);
      const l19 = vernier.indexOf(this.elemtos);
      const l20 = fisher.indexOf(this.elemtos);
      const l21 = sangrador.indexOf(this.elemtos);
      const l22 = sujecion.indexOf(this.elemtos);
      const l23 = clip.indexOf(this.elemtos);
      const l24 = tapiceria.indexOf(this.elemtos);
      const l25 = tarraja.indexOf(this.elemtos);
      const l26 = expansion.indexOf(this.elemtos);
      const l27 = redondo.indexOf(this.elemtos);
      const l28 = grapa.indexOf(this.elemtos);
      const l29 = redonda.indexOf(this.elemtos);
      const l30 = caliente.indexOf(this.elemtos);
      const l31 = estufa.indexOf(this.elemtos);
      const l32 = hexagonal.indexOf(this.elemtos);
      const l33 = plastico.indexOf(this.elemtos);
      const l34 = aluminio.indexOf(this.elemtos);
      const l35 = bronce.indexOf(this.elemtos);
      const l36 = maquina.indexOf(this.elemtos);

      if (l1 != -1){
        this.name = "pin tension";
        this.Buscar();
      };

      if (l2 != -1){
        this.name = "plana";
        this.Buscar();
      };

      if (l3 != -1){
        this.name = "inoxidable";
        this.Buscar();
      };

      if (l4 != -1){
        this.name = "estructural";
        this.Buscar();
      };

      if (l5 != -1){
        this.name = "grado 2";
        this.Buscar();
      };

      if (l6 != -1){
        this.name = "shim";
        this.Buscar();
      };

      if (l7 != -1){
        this.name = "roldana";
        this.Buscar();
      };

      if (l8 != -1){
        this.name = "maneral";
        this.Buscar();
      };

      if (l9 != -1){
        this.name = "carrocero";
        this.Buscar();
      };

      if (l10 != -1){
        this.name = "castigador";
        this.Buscar();
      };

      if (l11 != -1){
        this.name = "mueble";
        this.Buscar();
      };

      if (l12 != -1){
        this.name = "hitch pin";
        this.Buscar();
      };

      if (l13 != -1){
        this.name = "allen";
        this.Buscar();
      };

      if (l14 != -1){
        this.name = "inserto";
        this.Buscar();
      };

      if (l15 != -1){
        this.name = "esferica";
        this.Buscar();
      };
      
      if (l16 != -1){
        this.name = "muela";
        this.Buscar();
      };

      if (l17 != -1){
        this.name = "seguro";
        this.Buscar();
      };

      if (l18 != -1){
        this.name = "barra";
        this.Buscar();
      };

      if (l19 != -1){
        this.name = "vernier";
        this.Buscar();
      };

      if (l20 != -1){
        this.name = "fisher";
        this.Buscar();
      };

      if (l21 != -1){
        this.name = "sangrador";
        this.Buscar();
      };

      if (l22 != -1){
        this.name = "sujecion";
        this.Buscar();
      }; 

      if (l23 != -1){
        this.name = "clip";
        this.Buscar();
      };

      if (l24 != -1){
        this.name = "tapiceria";
        this.Buscar();
      };

      if (l25 != -1){
        this.name = "tarraja";
        this.Buscar();
      };

      if (l26 != -1){
        this.name = "expansión";
        this.Buscar();
      };

      if (l27 != -1){
        this.name = "redondo con roldana";
        this.Buscar();
      };

      if (l28 != -1){
        this.name = "grapa";
        this.Buscar();
      };
      
      if (l29 != -1){
        this.name = "redonda";
        this.Buscar();
      };

      if (l30 != -1){
        this.name = "galvanizada en caliente";
        this.Buscar();
      };

      if (l31 != -1){
        this.name = "estufa";
        this.Buscar();
      };

      if (l32 != -1){
        this.name = "hexagonal";
        this.Buscar();
      };

      if (l33 != -1){
        this.name = "plastico";
        this.Buscar();
      };

      if (l34 != -1){
        this.name = "aluminio";
        this.Buscar();
      };
      
      if (l35 != -1){
        this.name = "bronce";
        this.Buscar();
      };

      if (l36 != -1){
        this.name = "maquina";
        this.Buscar();
      };


      if (this.sum === 0){
        const insert = document.getElementById("resultado");
        insert.innerHTML = "Sin resultado...";
      };
    }
    }


}
