import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-burilc6d',
  templateUrl: './burilc6d.component.html',
  styleUrls: ['./burilc6d.component.scss']
})
export class Burilc6dComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) {
    this.title.setTitle('Buril de Carbono Clase 6 Derecho- La Casa Del Tornillo S.A.');
  
    this.meta.addTags([
      {name: 'keywords', content: 'buril de carbono'}
    ]);
   }

   mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
