import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-roldanaanilloinox316',
  templateUrl: './roldanaanilloinox316.component.html',
  styleUrls: ['./roldanaanilloinox316.component.scss']
})
export class Roldanaanilloinox316Component implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) {

    this.title.setTitle('Roldana De Ajuste 1 MM - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'Roldana De Ajuste 1 MM'}     
    ]);

   }


   mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
