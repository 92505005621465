import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private _http: HttpClient) { }

  sendMessage(body) {

    return this._http.post('https://www.casator.com/messagesphp/mail.php', body, {responseType: 'text'});
  }

  sendMessagecontacto(body) {

    return this._http.post('https://www.casator.com/messagesphp/mailcontacto.php', body, {responseType: 'text'});
  }
    
}
