import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-laminaredondaqpb',
  templateUrl: './laminaredondaqpb.component.html',
  styleUrls: ['./laminaredondaqpb.component.scss']
})
export class LaminaredondaqpbComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Tornillo Lamina Redonda Cabeza De Queso Punta De Broca - La Casa Del Tornillo S.A.');
   
    this.meta.addTags([
      {name: 'keywords', content: 'tornillo lamina redonda cabeza de queso punta de broca'}
    ]);

  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
