import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'src/app/services/message.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contactenos',
  templateUrl: './contactenos.component.html',
  styleUrls: ['./contactenos.component.scss']
})
export class ContactenosComponent implements OnInit {

  public contactForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private messageservice: MessageService,
    private meta: Meta,
    private title: Title
  ) {

    this.title.setTitle('Contactenos - La Casa Del Tornillo S.A.');

    this.meta.addTags([
      {name: 'keywords', content: 'contacto la casa del tornillo'}
    ]);

    this.createForm();
  }

  private createForm(): void{
    this.contactForm = this.fb.group({
      nombre: ['', [Validators.required]],
      telefono: ['', [Validators.required]],
      correo: ['', [Validators.required]],
      comentario: ['', [Validators.required]]
    });
  }

  submit(){
    if (this.contactForm.valid){
      const {nombre, telefono, correo, comentario} = this.contactForm.value;
      //console.log(nombre, telefono, correo, comentario);
      alert("Mensaje enviado");
      this.contactForm.reset();
      const comment = {
        nombre: nombre,
        telefono: telefono,
        correo: correo,
        comentario: comentario
      }
      this.messageservice.sendMessagecontacto(comment).subscribe(() => {
        //console.log("ok")
      });

    }else{
      alert("Debe llenar todos los campos");
    }
  }

  ngOnInit(): void {
  }

}
