import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-hexagonalc10rc',
  templateUrl: './hexagonalc10rc.component.html',
  styleUrls: ['./hexagonalc10rc.component.scss']
})
export class Hexagonalc10rcComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Tornillo Hexagonal Clase 10.9 Rosca Corrida - La Casa Del Tornillo S.A.');
   
    this.meta.addTags([
      {name: 'keywords', content: 'tornillo hexagonal clase 10.9 rosca corrida'}
    ]);

  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
