import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private _http: HttpClient) { }

  getpuestos() {
    return this._http.get('https://www.casator.com/admincasator/services/puestos.php');
  }
  
}
