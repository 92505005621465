import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';


@Component({
  selector: 'app-sucursales',
  templateUrl: './sucursales.component.html',
  styleUrls: ['./sucursales.component.scss']
})
export class SucursalesComponent implements OnInit {

  constructor( 
    @Inject(PLATFORM_ID) private platformId: any,
    private meta: Meta, 
    private title: Title
    ) {
  
      this.title.setTitle('Sucursales - La Casa Del Tornillo S.A.');
   
      this.meta.addTags([
        {name: 'keywords', content: 'sucursales la casa del tornillo'}
      ]);


  }

  ngOnInit(): void {
  }

  vista(){
    if(isPlatformBrowser(this.platformId)){
    const resolucion = screen.width;
    if (resolucion < 600){
      window.scroll({
        top: 800
      })
    }
  }
  }




}
