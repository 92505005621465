import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-siliconnegro',
  templateUrl: './siliconnegro.component.html',
  styleUrls: ['./siliconnegro.component.scss']
})
export class SiliconnegroComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) {

    this.title.setTitle('Silicon Negro - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'silicon negro'}     
    ]);

   }

   mostrar(){
    this.dialogo.mostrarDialogo();
  }


  ngOnInit(): void {
  }

}
