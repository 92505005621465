import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tuercahexagonalcanerai',
  templateUrl: './tuercahexagonalcanerai.component.html',
  styleUrls: ['./tuercahexagonalcanerai.component.scss']
})
export class TuercahexagonalcaneraiComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Tuerca Hexagonal Cañera Izquierda - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'tuerca hexagonal cañera izquierda'}     
    ])


  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
