import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { ProductosComponent } from './../../productos.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-automotriz',
  templateUrl: './automotriz.component.html',
  styleUrls: ['./automotriz.component.scss']
})
export class AutomotrizComponent implements OnInit {

  public productosnombre = [];
  numero: any;
  a: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public dialogo : ProductosComponent
  ) { 
    this.numero = 0;
  }

    Mostrar(){
      this.dialogo.mostrarDialogo();
    }

    prueba(){
      if(isPlatformBrowser(this.platformId)){
      const productos = document.getElementsByName("autA");
      const lista = document.getElementById("todoauta");
      const tamaño = productos.length;
      productos.forEach(nprod => {
  
        this.productosnombre[this.numero] = nprod.textContent;
        this.numero = this.numero + 1;
      });
      this.productosnombre.sort();
      for (this.a=0; this.a<=tamaño; this.a++){
        productos.forEach(element => {
          const nodo = element.textContent.indexOf(this.productosnombre[this.a]);
          if (nodo != -1){
            lista.appendChild(element);
          }
        });
      }
    }
    }
  
    ngAfterViewInit(): void {
      this.prueba();
    }

  ngOnInit(): void {
  }

}
