import { Component, OnInit } from '@angular/core';
import { ProductosComponent } from './../../productos.component';

@Component({
  selector: 'app-all',
  templateUrl: './all.component.html',
  styleUrls: ['./all.component.scss']
})
export class AllComponent implements OnInit {

  cards: any;
  nprod: any;

  constructor(
    public dialogo : ProductosComponent
  ) {
    
  }

  mostrarDialogo(){
    this.dialogo.mostrarDialogo();
  }


  ngOnInit(): void {
  }

}
