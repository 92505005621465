import { Component, OnInit, AfterViewChecked, ElementRef, ViewChild, PLATFORM_ID, Inject  } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators} from '@angular/forms'
//import { ChatroomService } from './../../services/chatroom.service';
//import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Room } from 'src/app/classes/room';
//import { AngularFireStorage } from 'angularfire2/storage';
import { MatDialog } from '@angular/material/dialog';
import { DialogoComponent } from './../dialogo/dialogo.component';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
//import 'firebase/firestore';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, AfterViewChecked {

  @ViewChild('scrollcontainer') private scrollContainer: ElementRef;

  private subscriptions: Subscription[] = [];
  public nameForm: FormGroup;
  public cliente: boolean;
  public formname: boolean;
  public social: boolean;
  public timeout: boolean;
  //public roomCollection: AngularFirestoreCollection<Room>
  public messages: Observable<any>;
  public messages2: Observable<any>;
  testID: string;
  public dialog: boolean;
  private subsubscripitons: Subscription[] = [];
  public currentuserid: any = null;
  public dowloadurl: string | null = null;
  public messagesinput: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private auth: AuthService,
    private router: Router,
    private fb: FormBuilder,
    //public chatroomService: ChatroomService,
    //private db: AngularFirestore,
    //private fs: AngularFireStorage,
    public dialogo: MatDialog,
    private _http: HttpClient
  ) {
    this.limpiar();
    this.social = true;
    this.createForm();
    //this.roomCollection = this.db.collection('chatrooms');
    //this.cliente = true;
  }

  //formulario para el nombre
  private createForm(): void{
    this.nameForm = this.fb.group({
      name: ['', [Validators.required]],
    });
  }

  public submit(): void{
    if(isPlatformBrowser(this.platformId)){
    if (this.nameForm.valid){
    const num = Math.random();
    const {name} = this.nameForm.value;
    const {firstName, lastName, email, password} = {
      firstName: name,
      lastName: 'client'+num,
      email: 'client'+num+'@mail.com',
      password: 'client'+num
    }
    this.subscriptions.push(
      //this.auth.signup(firstName, lastName, email, password).subscribe(succses => {
       // if (succses){
          /*this.getdateandtime().subscribe(data => {

            this.auth.roomName(name);
            const idcliente = this.auth.idrooms;
            //console.log(`name: ${name}`)
            //console.log(data.toString())
            this.chatroomService.changeChatroom.next(idcliente);
            this.chatroomService.mostrarchats();
            this.chatroomService.selectedChatroomMessages.subscribe(messages => {
              this.messages = messages;
            });
          });*/
         /*  this.auth.roomName(name);
          const idcliente = this.auth.idrooms;
          //console.log(`name: ${name}`)
          //console.log("Exito")
          this.chatroomService.changeChatroom.next(idcliente);
          this.chatroomService.mostrarchats();
          this.chatroomService.selectedChatroomMessages.subscribe(messages => {
            this.messages = messages;
          });

        }else {
          this.router.navigate(['/signup']);
        } */
      //}
    )
    this.cliente = true;
   }
    else{
    this.cliente = false;
    }
    }
  }

  //habilita el formulario para ingresar el nombre
  public mostrarform(){
    //this.fueradetiempo();
    const dd = new Date()
    const time = new Date().getUTCHours();
    const day = new Date().getUTCDay();

    const hora =time - 6;
    const dia = day;


    if (dia == 0){
      this.fueradetiempo();
      //console.log(dia);
      }else{
        if (dia == 6){
          if (hora<7 || hora>11){
            this.fueradetiempo();
            //console.log(hora)
          }else{
            this.formname = true;
            this.social = false;
          }
        }else{
          if (hora<7 || hora>16){
            this.fueradetiempo();
            //console.log(hora);
          }else{
            this.formname = true;
            this.social = false;
          }
        }
    }


  }

  //muestra la pantalla de fuera de horario
  public fueradetiempo(){
    this.formname = true;
    this.timeout = true;
    this.social = false;
  }

  //oculta la pantalla de chats
  public minimizar(){
    this.formname = false;
    this.social = true;
  }

  //oculta la pantalla de fuera de horario
  public minimizar2(){
    this.timeout = false;
    this.formname = false;
    this.social = true;
  }

  ngOnInit(): void {
    if(isPlatformBrowser(this.platformId)){
    this.scrollToBottom();
    const chatcliente = this.auth.idrooms;
    //this.chatroomService.changeChatroom.next(chatcliente);
    }
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  private scrollToBottom(): void {
    try {
      this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
    }catch(err) {}
  }

  //borrar
  limpiar(){
    if(isPlatformBrowser(this.platformId)){
    const time = new Date().getUTCHours();
    const hora = time - 6;
    //console.log(hora)
    if (hora == 5){
      //const chats = this.db.collection('chatrooms');
      //this.chatroomService.borrarcoleccion(chats);
      //const client = this.db.collection('users');
      //this.chatroomService.borrarusers(client);
      //this.chatroomService.deletestorage();
    }
    }
  }

  mostrarDialogo(): void {
    if(isPlatformBrowser(this.platformId)){
    this.dialogo
      .open(DialogoComponent, {
        data: `¿Quieres consultar sobre esté producto?`
      })
      .afterClosed()
      .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.mostrarform();
        } else {
          //console.log("ok");
        }
      });
    }
  }

  getdateandtime() {
    return this._http.get('https://casator.com/messagesphp/dateandtime.php');
   }

}
