import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-peabaacme',
  templateUrl: './peabaacme.component.html',
  styleUrls: ['./peabaacme.component.scss']
})
export class PeabaacmeComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Barra Roscada Trapezoidal - La Casa Del Tornillo S.A.');
   
    this.meta.addTags([
      {name: 'keywords', content: 'barra roscada acme o trapezoidal'}
    ]);


  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
