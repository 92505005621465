import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Message } from '../../../classes/message';
import { LoadingService } from '../../../services/loading.service';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss']
})
export class ChatMessageComponent implements OnInit {

  @Input() message: Message;

  private subscriptions: Subscription[] = [];
  public loading: boolean = false;

  constructor(
  private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.loadingService.isloading.subscribe(isLoading => {
        this.loading = isLoading; 
      })
    )
  }

}
