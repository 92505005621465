import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-allencbi',
  templateUrl: './allencbi.component.html',
  styleUrls: ['./allencbi.component.scss']
})
export class AllencbiComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title ) { 

    this.title.setTitle('Tornillo Allen Cabeza De Boton Inoxidable - La Casa Del Tornillo S.A.');
   
    this.meta.addTags([
      {name: 'keywords', content: 'tornillo allen cabeza de boton inoxidable'}
    ]);
    
  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
