import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { PrincipalService } from '../../services/principal.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  imagenes : any;
  view_oportunidades = false;

  constructor(@Inject(PLATFORM_ID) private platformId: any, private meta: Meta, private title: Title, private principal : PrincipalService) {

    this.title.setTitle('La Casa Del Tornillo S.A.');

    this.meta.addTags([
      {name: 'keywords', content: 'tornillo en guatemala, la casa del tornillo'}
    ]);

  }

  ngOnInit(): void {
    this.principal.getimg().subscribe(data => {
      this.imagenes = data
    });
  }

  subir(){
    if(isPlatformBrowser(this.platformId)){
    window.scroll({
      top: 0
    })
    }
  }

  change_view_oportunidades(){
    this.view_oportunidades = !this.view_oportunidades;
    //console.log(this.view_oportunidades)
  }

}
