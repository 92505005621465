import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-hexagonalg2rc',
  templateUrl: './hexagonalg2rc.component.html',
  styleUrls: ['./hexagonalg2rc.component.scss']
})
export class Hexagonalg2rcComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Tornillo Hexagonal Grado 2 Rosca Corrida - La Casa Del Tornillo S.A.');
   
    this.meta.addTags([
      {name: 'keywords', content: 'tornillo hexagonal grado 2 rosca corrida'}
    ]);

  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
