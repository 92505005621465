import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-machuelometricoex',
  templateUrl: './machuelometricoex.component.html',
  styleUrls: ['./machuelometricoex.component.scss']
})
export class MachuelometricoexComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Machuelo Metrico Extrafino - La Casa Del Tornillo S.A.');
   
    this.meta.addTags([
      {name: 'keywords', content: 'machuelo metrico extrafino'}
    ]);

  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
