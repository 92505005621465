import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tuercacse',
  templateUrl: './tuercacse.component.html',
  styleUrls: ['./tuercacse.component.scss']
})
export class TuercacseComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) {

    this.title.setTitle('Tuerca Chucho De Seguridad Rosca Extrafina  - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'tuerca chucho de seguridad rosca extrafina'}     
    ])

   }

   mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
