import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tuercacilindricaal',
  templateUrl: './tuercacilindricaal.component.html',
  styleUrls: ['./tuercacilindricaal.component.scss']
})
export class TuercacilindricaalComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) {

    this.title.setTitle('Tuerca Cilindrica Allen Lisa - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'tuerca cilindrico allen Lisa'}     
    ])

   }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
