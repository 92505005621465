import { Component, OnInit } from '@angular/core';
//import { MenuComponent } from './../../../menu/menu.component';
//import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-lhpbct',
  templateUrl: './lhpbct.component.html',
  styleUrls: ['./lhpbct.component.scss']
})
export class LhpbctComponent implements OnInit {

  constructor() { 

    /*this.title.setTitle('Lamina Hexagonal Punta De Broca Casa Del Tornillo - La Casa Del Tornillo S.A.');
   
    this.meta.addTags([
      {name: 'keywords', content: 'Lamina Hexagonal Punta De Broca Casa Del Tornillo'}
    ]);*/

  }

  /*mostrar(){
    this.dialogo.mostrarDialogo();
  }*/

  ngOnInit(): void {
  }

}
