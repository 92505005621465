import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-roldanacomercial',
  templateUrl: './roldanacomercial.component.html',
  styleUrls: ['./roldanacomercial.component.scss']
})
export class RoldanacomercialComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Roldana Comercial - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'roldana comercial'}     
    ]);

  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
