import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tuercachucholujoi',
  templateUrl: './tuercachucholujoi.component.html',
  styleUrls: ['./tuercachucholujoi.component.scss']
})
export class TuercachucholujoiComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Tuerca De Chucho De Lujo Izquierda - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'tuerca de chucho de lujo izquierda'}     
    ])
    
  }
  
  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
