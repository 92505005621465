import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-quienes-somos',
  templateUrl: './quienes-somos.component.html',
  styleUrls: ['./quienes-somos.component.scss']
})
export class QuienesSomosComponent implements OnInit {

  constructor( private meta: Meta, private title: Title) {
    
    this.title.setTitle('Quienos Somos - La Casa Del Tornillo S.A.');
   
    this.meta.addTags([
      {name: 'keywords', content: 'quienes somos la casa del tornillo'}
    ]);

   }

  ngOnInit(): void {
  }

}
