import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-allencbr',
  templateUrl: './allencbr.component.html',
  styleUrls: ['./allencbr.component.scss']
})
export class AllencbrComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title ) { 
   
    this.title.setTitle('Tornillo Allen Cabeza De Boton Inoxidable Tipo Roldana - La Casa Del Tornillo S.A.');

    this.meta.addTags([
      {name: 'keywords', content: 'tornillo allen cabeza de boton tipo roldana'}
    ]);
  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
