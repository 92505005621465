import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-chuchocabp',
  templateUrl: './chuchocabp.component.html',
  styleUrls: ['./chuchocabp.component.scss']
})
export class ChuchocabpComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) {

    this.title.setTitle('Chucho para Cables Trabajo Pesado - La Casa Del Tornillo S.A.');
   
    this.meta.addTags([
      {name: 'keywords', content: 'chucho prensa cables trabajo pesado'}
    ]);
   }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
