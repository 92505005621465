import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tablayesot',
  templateUrl: './tablayesot.component.html',
  styleUrls: ['./tablayesot.component.scss']
})
export class TablayesotComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) {

    this.title.setTitle('Tornillo Tabla Yeso Tropicalizado - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'tornillo tabla yeso tropicalizado'}     
    ])

   }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
