import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-allenc12',
  templateUrl: './allenc12.component.html',
  styleUrls: ['./allenc12.component.scss']
})
export class Allenc12Component implements OnInit {

  constructor( public dialogo: MenuComponent, private meta: Meta, private title: Title) {
    
    this.title.setTitle('Tornillo Allen Cabeza Cilindrica Clase 12.9 - La Casa Del Tornillo S.A.');

    this.meta.addTags([
      {name: 'keywords', content: 'tornillo allen cabeza cilindrica clase 12.9'}
    ]);
   }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
