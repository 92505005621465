// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
    apiKey: "AIzaSyAYoTEAHH2Aynk7gjoycG8S2bgUA3P0T6c",
    authDomain: "chat-c3dcf.firebaseapp.com",
    databaseURL: "https://chat-c3dcf.firebaseio.com",
    projectId: "chat-c3dcf",
    storageBucket: "chat-c3dcf.appspot.com",
    messagingSenderId: "1015099880039",
    appId: "1:1015099880039:web:130efa9696310a91501ac2",
    measurementId: "G-VQZ3TDLEXH"
  
};

