import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-cementooa',
  templateUrl: './cementooa.component.html',
  styleUrls: ['./cementooa.component.scss']
})
export class CementooaComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Tornillo Para Cemento Cabeza Oblea Con Astrilla Quadrex - La Casa Del Tornillo S.A.');
    
    this.meta.addTags([
      {name: 'keywords', content: 'tornillo para cemento cabeza oblea con astrilla quadrex'}
    ]);
  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
