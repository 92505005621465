import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tablayesoobleapb',
  templateUrl: './tablayesoobleapb.component.html',
  styleUrls: ['./tablayesoobleapb.component.scss']
})
export class TablayesoobleapbComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) {

    this.title.setTitle('Tornillo Tabla Yeso Cabeza Oblea Punta de Broca - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'tornillo tabla yeso cabeza oblea punta de broca'}     
    ]);

   }


   mostrar(){
    this.dialogo.mostrarDialogo();
  }


  ngOnInit(): void {
  }

}
