import { Injectable, PLATFORM_ID, Inject, } from '@angular/core';
import { from as fromPromise, Observable, of, Subscription, observable} from 'rxjs';
import { AlertType } from './../enums/alert-type.enum';
import { Router } from '@angular/router';
import { User } from './../classes/user';
import { Room } from './../classes/room';
import { Alert } from './../classes/alert';
import { AlertService } from './alert.service';
//import { AngularFireAuth } from 'angularfire2/auth';
//import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from 'angularfire2/firestore';
import { switchMap, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
//import 'firebase/firestore';

@Injectable()

export class AuthService {

  public currentUser: Observable<User | null>;
  public currentUserSnapshot: User | null;
  public changeChatroom: BehaviorSubject<string | null> = new BehaviorSubject(null);
  public selectedChatroom: Observable<any>;
//  private roomCollection: AngularFirestoreCollection<Room>;
 // private roomCollection1: AngularFirestoreCollection<Room>;
  rooms: Observable<Room[]>;
 // private roomsdoc: AngularFirestoreDocument<Room>;
  idrooms: string;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private router: Router,
    private alertService: AlertService,
//    private afAuth: AngularFireAuth,
//    private db: AngularFirestore,
    private _http: HttpClient

  ) {
    if(isPlatformBrowser(this.platformId)){
   // this.roomCollection = db.collection<Room>('chatrooms');

   /*  this.currentUser = this.afAuth.authState
      .pipe(switchMap((user) => {
        if (user) {
     //     return this.db.doc<User>(`users/${user.uid}`).valueChanges();
        }else{
          return of(null);
        }
      })) */

    //this.setCurrentUserSnapshot();
    }
   }

   //crear usuario en firebase
/*    public signup(firstName: string, lastName: string, email: string, password: string): Observable<boolean> {
    if(isPlatformBrowser(this.platformId)){
    return fromPromise(
       this.afAuth.auth.createUserWithEmailAndPassword(email, password)
        .then((user) => {
          const userRef: AngularFirestoreDocument<User> = this.db.doc(`users/${user.user.uid}`);
          const updatedUser = {
            id: user.user.uid,
            email: user.user.email,
            firstName,
            lastName,
            personal: "not",
          }
          userRef.set(updatedUser);
          return true;
        })
        .catch((err) => false)
     );}
   }  */

   //validacion para usuario
  /*  public login(email: string, password: string): Observable<boolean> {
    if(isPlatformBrowser(this.platformId)){
    return fromPromise(
       this.afAuth.auth.signInWithEmailAndPassword(email, password)
        .then((user) => true)
        .catch((err) => false)
     )}
   } */

   //salida de sesion
  /*  public logout(): void {
    if(isPlatformBrowser(this.platformId)){
    this.afAuth.auth.signOut().then(() => {
      this.router.navigate(['/login']);
      this.alertService.alerts.next(new Alert('you have been signed out.'));
     });   }
   } */

   //creacion de la nueva sala de chat
   /* roomName(room: string) {
    if(isPlatformBrowser(this.platformId)){
    const idroom = this.db.createId();
    const datos = {
      id : idroom,
      name: room,
      tipochat: "Nuevo"
    }
    //this.roomCollection.doc(idroom).set(datos);
    const senders = {
      email: "",
      firstName: "",
      lastName: "",
      id: "",
    }

      const message = {
        message: 'Buen dia en que podemos servirle',
        createdAt: new Date,
        sender: senders,
        image: "NO IMAGE",
        archivo: "NO ARCHIVO",
        nombreA: "not"
      };
      this.db.collection(`chatrooms/${idroom}/messages`).add(message);

      this.idrooms = idroom;

    }

   }

   private setCurrentUserSnapshot(): void{
    if(isPlatformBrowser(this.platformId)){
    this.currentUser.subscribe(user => this.currentUserSnapshot = user);
    }
   }
 */

}
