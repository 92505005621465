import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MessageService } from './../../services/message.service';
import { HttpClient } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
import { DataService } from './../../services/data.service';
import { LoadingService } from '../../services/loading.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-carrera-esp',
  templateUrl: './carrera-esp.component.html',
  styleUrls: ['./carrera-esp.component.scss']
})
export class CarreraEspComponent implements OnInit {

  public carreraForm: FormGroup;
  puestos = ['Albañil','Ayudante de Albañil','Carpintero'] ;
  seleccion: any;
  fileToUpload: File = null;
  addfile: any;
  uploadedFiles: Array < File > ;
  msm: boolean;
  form: boolean;
  public format: string;
  public loading: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    public _MessageService: MessageService,
    private httpClient : HttpClient,
    private meta: Meta,
    private title: Title,
    private datapuestos: DataService,
    private loadingService: LoadingService
  ) {

    this.title.setTitle('Carrera - La Casa Del Tornillo S.A.');

    this.meta.addTags([
      {name: 'keywords', content: 'empleos la casa del tornillo'}
    ]);


    this.createForm();
    this.form = true;
    this.msm = false;

    this.loadingService.isloading.next(false);
  }

  private createForm(): void{
    this.carreraForm = this.fb.group({
      nombre: ['', [Validators.required]],
      apellido: ['', [Validators.required]],
      telefono: ['', [Validators.required]]
    });
  }

  public submit(): void{
  if (this.form == true){
    if (this.carreraForm.valid && this.seleccion!=null ){

      /*if (this.uploadedFiles!=null){

        if (this.format=="application/pdf"){*/

        const {nombre, apellido, telefono} = this.carreraForm.value;
        ////console.log(nombre, apellido, telefono, this.seleccion)
        const select = this.seleccion;

        this.carreraForm.reset();
        const form = {
          asunto: select,
          nombre,
          apellido,
          telefono,
          //email: "pruebacasat@gmail.com",
          //add: this.uploadedFiles[0].name
        }

        let formData = new FormData();
        /*for (var i = 0; i < this.uploadedFiles.length; i++) {
          formData.append("upload",this.uploadedFiles[i]);
        }*/
        this.loadingService.isloading.next(true);

       /* this.subirArchivo(formData).subscribe(() => {
          ////console.log("ok")
          this.mensaje(form).subscribe(data => {
            //console.log("mensaje enviado")
            this.msm=true;
            this.loadingService.isloading.next(false);
            alert(data)
          });
        });*/

        this.mensaje(form).subscribe(data => {
          //console.log("mensaje enviado")
          this.msm=true;
          this.loadingService.isloading.next(false);
          alert(data)
          this.form=false;
        });

        /*}else{
          alert("Debe subir su curriculom en formato pdf")
        }

      }else{
        alert("Debe subir su curriculum  *el archivo tiene que ser en formato pdf")
      }*/

    }else{
      alert("Debe llenar todos los campos");
    }

  }
  }

  callType(value){
    this.seleccion = value;
  }

  /*uploadfiles(file){
    this.uploadedFiles = file.target.files;
    //console.log(this.uploadedFiles[0].name)
    this.format = this.uploadedFiles[0].type;
    if (this.format!="application/pdf"){
      alert("El archivo tiene que ser un documento en formato pdf, " + this.format)
    }else{
      //console.log("file ok")
    }*/



  /*public subirArchivo(file){
      const endpoint = 'https://tornibodegas.com/wsphp/uploadfile.php';
      return this.httpClient.post(endpoint, file, {responseType: 'text'});
      //http://localhost/mailsinajunto/messagesphp/uploadfile.php
      //https://www.casator.com/messagesphp/uploadfile.php
  }*/

  public mensaje(body){
    return this.httpClient.post('https://tornibodegas.com/wsphp/mailcarreraesp.php', body, {responseType: 'text'});
    //https://www.casator.com/messagesphp/mailcarrera.php
  }

  ngOnInit(): void {
    /*this.datapuestos.getpuestos().subscribe(data =>{
      this.puestos = data
      //console.log(data)
    })*/

    this.subscriptions.push(
      this.loadingService.isloading.subscribe(isLoading => {
        this.loading = isLoading;
      })
    )
  }

}
