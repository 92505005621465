import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tuercaseguridadplasticainox',
  templateUrl: './tuercaseguridadplasticainox.component.html',
  styleUrls: ['./tuercaseguridadplasticainox.component.scss']
})
export class TuercaseguridadplasticainoxComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Tuerca De Seguridad Plastica Inoxidable - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'tuerca de seguridad plastica inoxidable'}     
    ])

  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
