import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-atg',
  templateUrl: './atg.component.html',
  styleUrls: ['./atg.component.scss']
})
export class AtgComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) {
    this.title.setTitle('Tirabuzon Hexagonal Galvanizado - La Casa Del Tornillo S.A.');
    
    this.meta.addTags([
      {name: 'keywords', content: 'tirabuzon hexagonal galvanizado'}
    ]);

  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
