import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-lppb188',
  templateUrl: './lppb188.component.html',
  styleUrls: ['./lppb188.component.scss']
})
export class Lppb188Component implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 
    this.title.setTitle('Lamina plana philips punta de broca inoxidable - La Casa Del Tornillo S.A.');
   
    this.meta.addTags([
      {name: 'keywords', content: 'Lamina plana philips punta de broca inoxidable'}
    ]);
  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
