import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { ProductosComponent } from './../../productos.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-paginacion',
  templateUrl: './paginacion.component.html',
  styleUrls: ['./paginacion.component.scss']
})
export class PaginacionComponent implements OnInit {

  a: any;
  b: any;
  page = ["uno", "dos", "tres"];
  public productosnombre = [];
  numero: any;
  a1:boolean;
  a2:boolean;
  a3:boolean;
  a4:boolean;
  a5:boolean;
  a6:boolean;
  a7:boolean;


  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public dialogo : ProductosComponent,

  ) { 
    this.a2=true;
    this.a3=true;
    this.a4=true;
    this.a5=true;
    this.a6=true;
    this.a7=true;
  }

  ngOnInit(): void {
  }

  mostrarDialogo(){
    this.dialogo.mostrarDialogo();
  }

  pagprod(){
    if(isPlatformBrowser(this.platformId)){
    const prod = document.getElementsByName("all");
    for (this.b=1; this.b<=3; this.b++){
      if (this.b==1){  
        const pages = document.getElementById("uno")
      }
      if (this.b==1){  
        const pages = document.getElementById("uno")
      }
        for (this.a=1; this.a<=108; this.a++){
          const all = document.getElementById("all")
          
        }
    }
  }
  }

  prueba(){
    if(isPlatformBrowser(this.platformId)){
    const productos = document.getElementsByName("all");
    const lista = document.getElementById("resultado");
    const tamaño = productos.length;
    productos.forEach(nprod => {

      this.productosnombre[this.numero] = nprod.textContent
      this.numero = this.numero + 1;
    });
    this.productosnombre.sort();
    console.log(this.productosnombre);
    for (this.a=0; this.a<=tamaño; this.a++){
      productos.forEach(element => {
        if (this.a <= 105){
          const lista1 = document.getElementById("uno");
          const nodo = element.textContent.indexOf(this.productosnombre[this.a]);
          if (nodo != -1){
            lista1.appendChild(element);
          }
        }

        if (this.a>105 && this.a<=210){
          const lista2 = document.getElementById("dos");
          const nodo = element.textContent.indexOf(this.productosnombre[this.a]);
          if (nodo != -1){
            lista2.appendChild(element);
          }
          if(this.a==210){
            this.a2 = false;
          }
        }

        if (this.a>210 && this.a<=315){
          const lista3 = document.getElementById("tres");
          const nodo = element.textContent.indexOf(this.productosnombre[this.a]);
          if (nodo != -1){
            lista3.appendChild(element);
          }
          if (this.a==315){
            this.a3 = false;
          }
        }

        if (this.a>315 && this.a<=420){
          const lista4 = document.getElementById("cuatro");
          const nodo = element.textContent.indexOf(this.productosnombre[this.a]);
          if (nodo != -1){
            lista.appendChild(element);
          }
          if (this.a==420){
            this.a = false;
          }
        }

        if (this.a>420 && this.a<=525){
          const lista5 = document.getElementById("cinco");
          const nodo = element.textContent.indexOf(this.productosnombre[this.a]);
          if (nodo != -1){
            lista.appendChild(element);
          }
          if (this.a==525){
            this.a5 = false;
          }
        }

        if (this.a>525 && this.a<=630){
          const lista6 = document.getElementById("seis");
          const nodo = element.textContent.indexOf(this.productosnombre[this.a]);
          if (nodo != -1){
            lista.appendChild(element);
          }
          if (this.a==630){
            this.a6=false;
          }
        }
      });
    }
  }
  }

  pag2(){
    if(isPlatformBrowser(this.platformId)){
    document.getElementById("dos").style.display = "block";
    this.a2 = true;
    }
  }

  pag3(){
    if(isPlatformBrowser(this.platformId)){
    document.getElementById("tres").style.display = "block";
    this.a3 = true;
    }
  }
  pag4(){
    if(isPlatformBrowser(this.platformId)){
    document.getElementById("cuatro").style.display = "block";
    this.a4 = true;
    }
  }
  pag5(){
    if(isPlatformBrowser(this.platformId)){
    document.getElementById("cinco").style.display = "block";
    this.a5 = true;
    }
  }
  pag6(){
    if(isPlatformBrowser(this.platformId)){
    document.getElementById("seis").style.display = "block";
    this.a6 = true;
    }
  }
  pag7(){
    if(isPlatformBrowser(this.platformId)){
    document.getElementById("siete").style.display = "block";
    this.a7 = true;
    }
  }

}
