import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tuercacbrf',
  templateUrl: './tuercacbrf.component.html',
  styleUrls: ['./tuercacbrf.component.scss']
})
export class TuercacbrfComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) {

    this.title.setTitle('Tuerca Chucho Bolita Rosca Fina - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'tuerca chucho bolita rosca fina'}     
    ])

   }

     mostrar(){
    this.dialogo.mostrarDialogo();
  }
  

  ngOnInit(): void {
  }

}
