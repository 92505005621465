
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { environment } from '../environments/environment';

//modules
import { AlertModule } from 'ngx-bootstrap/alert';
import { NgxLoadingModule } from 'ngx-loading';
//import { AngularFireModule } from 'angularfire2';
//import { AngularFirestoreModule } from 'angularfire2/firestore';
//import { AngularFireStorageModule } from 'angularfire2/storage';
//import { AngularFireAuthModule } from 'angularfire2/auth';
import { MatSliderModule } from '@angular/material/slider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy} from '@angular/common';

//guards
import { AuthGuard } from './guards/auth.guard';

//services
import { AlertService } from './services/alert.service';
import { AuthService } from './services/auth.service';
//import { ChatroomService } from './services/chatroom.service';
import { HttpClientModule } from '@angular/common/http';
import { MessageService } from './services/message.service';
import { DataService } from './services/data.service';

//components
import { AppComponent } from './app.component';
import { MenuComponent } from './pages/menu/menu.component';
import { ChatMessageComponent } from './../app/pages/chat1/chat-message/chat-message.component';
import { SucursalesComponent } from './pages/sucursales/sucursales.component';
import { ContactenosComponent } from './pages/contactenos/contactenos.component';
import { ProductosComponent } from './pages/productos/productos.component';
import { NavbarMenuComponent } from './components/navbar-menu/navbar-menu.component';
import { InicioComponent } from './pages/inicio/inicio.component';
import { LayoutComponent } from './pages/layout/layout.component';
//import { ChatInutComponent } from './pages/chat1/chat-inut/chat-inut.component';
import { QuienesSomosComponent } from './pages/quienes-somos/quienes-somos.component';
import { AllproductsComponent } from './pages/productos/sheets/allproducts/allproducts.component';
import { BuscaRoscaComponent } from './pages/productos/sheets/busca-rosca/busca-rosca.component';
import { PegamentosComponent } from './pages/productos/sheets/pegamentos/pegamentos.component';
import { AllenComponent } from './pages/productos/sheets/allen/allen.component';
import { TuercasComponent } from './pages/productos/sheets/tuercas/tuercas.component';
import { WashasComponent } from './pages/productos/sheets/washas/washas.component';
import { RoldanasComponent } from './pages/productos/sheets/roldanas/roldanas.component';
import { HerramientasComponent } from './pages/productos/sheets/herramientas/herramientas.component';
import { ResortesComponent } from './pages/productos/sheets/resortes/resortes.component';
import { HexagonalmComponent } from './pages/productos/sheets/hexagonalm/hexagonalm.component';
import { AllenmComponent } from './pages/productos/sheets/allenm/allenm.component';
import { RoldanasmComponent } from './pages/productos/sheets/roldanasm/roldanasm.component';
import { WashasmComponent } from './pages/productos/sheets/washasm/washasm.component';
import { TuercasmComponent } from './pages/productos/sheets/tuercasm/tuercasm.component';
import { BuscaRoscamComponent } from './pages/productos/sheets/busca-roscam/busca-roscam.component';
import { SegurosComponent } from './pages/productos/sheets/seguros/seguros.component';
import { PinesComponent } from './pages/productos/sheets/pines/pines.component';
import { EspecialidadesComponent } from './pages/productos/sheets/especialidades/especialidades.component';
import { AllComponent } from './pages/productos/sheets/all/all.component';
import { ServiciosComponent } from './pages/servicios/servicios.component';
import { NtiendaComponent } from './pages/ntienda/ntienda.component';
import { VideosComponent } from './pages/videos/videos.component';
import { NproductoComponent } from './pages/nproducto/nproducto.component';
import { CarreraComponent } from './pages/carrera/carrera.component';
import { EspecialedadesmComponent } from './pages/productos/sheets/especialedadesm/especialedadesm.component';
import { AutomotrizmComponent } from './pages/productos/sheets/automotrizm/automotrizm.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { DialogoComponent } from './pages/dialogo/dialogo.component';
import { DesarmadormComponent } from './pages/productos/sheets/desarmadorm/desarmadorm.component';
import { DesarmadorComponent } from './pages/productos/sheets/desarmador/desarmador.component';
import { FijacionComponent } from './pages/productos/sheets/fijacion/fijacion.component';
import { AutomotrizComponent } from './pages/productos/sheets/automotriz/automotriz.component';
import { TorxComponent } from './pages/productos/sheets/torx/torx.component';
import { PaginacionComponent } from './pages/productos/sheets/paginacion/paginacion.component';
import { BuscarComponent } from './pages/productos/buscar/buscar.component';
import { AllenpacheComponent } from './pages/productos/pages/allenpache/allenpache.component';
import { AllenpacheiComponent } from './pages/productos/pages/allenpachei/allenpachei.component';
import { AllenpacherComponent } from './pages/productos/pages/allenpacher/allenpacher.component';
import { AllenplanoComponent } from './pages/productos/pages/allenplano/allenplano.component';
import { AllencComponent } from './pages/productos/pages/allenc/allenc.component';
import { Allenc12Component } from './pages/productos/pages/allenc12/allenc12.component';
import { Allencrc12Component } from './pages/productos/pages/allencrc12/allencrc12.component';
import { Allenc8Component } from './pages/productos/pages/allenc8.8/allenc8.8.component';
import { Allencrc8Component } from './pages/productos/pages/allencrc8/allencrc8.component';
import { AllenciComponent } from './pages/productos/pages/allenci/allenci.component';
import { AllencrciComponent } from './pages/productos/pages/allencrci/allencrci.component';
import { AllencfComponent } from './pages/productos/pages/allencf/allencf.component';
import { Allencf12Component } from './pages/productos/pages/allencf12/allencf12.component';
import { Allencf8Component } from './pages/productos/pages/allencf8/allencf8.component';
import { AllencfiComponent } from './pages/productos/pages/allencfi/allencfi.component';
import { AllenchComponent } from './pages/productos/pages/allench/allench.component';
import { AllenchfComponent } from './pages/productos/pages/allenchf/allenchf.component';
import { AllenmpComponent } from './pages/productos/pages/allenmp/allenmp.component';
import { AnclajecComponent } from './pages/productos/pages/anclajec/anclajec.component';
import { AnclajegComponent } from './pages/productos/pages/anclajeg/anclajeg.component';
import { AnclajeiComponent } from './pages/productos/pages/anclajei/anclajei.component';
import { AnilloajusteComponent } from './pages/productos/pages/anilloajuste/anilloajuste.component';
import { BarragcComponent } from './pages/productos/pages/barragc/barragc.component';
import { Barrab7Component } from './pages/productos/pages/barrab7/barrab7.component';
import { BarragComponent } from './pages/productos/pages/barrag/barrag.component';
import { BarraiComponent } from './pages/productos/pages/barrai/barrai.component';
import { BarramgComponent } from './pages/productos/pages/barramg/barramg.component';
import { Barram10Component } from './pages/productos/pages/barram10/barram10.component';
import { Barram8Component } from './pages/productos/pages/barram8/barram8.component';
import { BarramiComponent } from './pages/productos/pages/barrami/barrami.component';
import { BernierComponent } from './pages/productos/pages/bernier/bernier.component';
import { BerniereComponent } from './pages/productos/pages/berniere/berniere.component';
import { BirloComponent } from './pages/productos/pages/birlo/birlo.component';
import { AllencbsComponent } from './pages/productos/pages/allencbs/allencbs.component';
import { BrocalComponent } from './pages/productos/pages/brocal/brocal.component';
import { BrocalrComponent } from './pages/productos/pages/brocalr/brocalr.component';
import { BrocacComponent } from './pages/productos/pages/brocac/brocac.component';
import { BrocarComponent } from './pages/productos/pages/brocar/brocar.component';
import { BrocarrComponent } from './pages/productos/pages/brocarr/brocarr.component';
import { BrocarcComponent } from './pages/productos/pages/brocarc/brocarc.component';
import { BrocaUComponent } from './pages/productos/pages/broca-u/broca-u.component';
import { BrocacoComponent } from './pages/productos/pages/brocaco/brocaco.component';
import { CabezamariposaComponent } from './pages/productos/pages/cabezamariposa/cabezamariposa.component';
import { CabezaquesoComponent } from './pages/productos/pages/cabezaqueso/cabezaqueso.component';
import { CabezaquesoiComponent } from './pages/productos/pages/cabezaquesoi/cabezaquesoi.component';
import { Allenplano8Component } from './pages/productos/pages/allenplano8/allenplano8.component';
import { AllenplanoaComponent } from './pages/productos/pages/allenplanoa/allenplanoa.component';
import { CabezaplanaComponent } from './pages/productos/pages/cabezaplana/cabezaplana.component';
import { CabezaplanaBComponent } from './pages/productos/pages/cabezaplana-b/cabezaplana-b.component';
import { CabezaplanapComponent } from './pages/productos/pages/cabezaplanap/cabezaplanap.component';
import { CabezaplanapiComponent } from './pages/productos/pages/cabezaplanapi/cabezaplanapi.component';
import { AllenplanoinoxComponent } from './pages/productos/pages/allenplanoinox/allenplanoinox.component';
import { CabezaplanaifComponent } from './pages/productos/pages/cabezaplanaif/cabezaplanaif.component';
import { CabezaredondaComponent } from './pages/productos/pages/cabezaredonda/cabezaredonda.component';
import { CabezaredondabComponent } from './pages/productos/pages/cabezaredondab/cabezaredondab.component';
import { CabezaredondaiComponent } from './pages/productos/pages/cabezaredondai/cabezaredondai.component';
import { CabezaredondaifComponent } from './pages/productos/pages/cabezaredondaif/cabezaredondaif.component';
import { CajaplasticaComponent } from './pages/productos/pages/cajaplastica/cajaplastica.component';
import { CalibradorroscaComponent } from './pages/productos/pages/calibradorrosca/calibradorrosca.component';
import { CapiruchoComponent } from './pages/productos/pages/capirucho/capirucho.component';
import { CapiruchoiComponent } from './pages/productos/pages/capiruchoi/capiruchoi.component';
import { CastigadorallenComponent } from './pages/productos/pages/castigadorallen/castigadorallen.component';
import { CastigadoralleniComponent } from './pages/productos/pages/castigadoralleni/castigadoralleni.component';
import { CastigadorallenfComponent } from './pages/productos/pages/castigadorallenf/castigadorallenf.component';
import { CementooaComponent } from './pages/productos/pages/cementooa/cementooa.component';
import { CementoopaComponent } from './pages/productos/pages/cementoopa/cementoopa.component';
import { CementoopbComponent } from './pages/productos/pages/cementoopb/cementoopb.component';
import { CementoopbaComponent } from './pages/productos/pages/cementoopba/cementoopba.component';
import { CementopasqComponent } from './pages/productos/pages/cementopasq/cementopasq.component';
import { CementopalaComponent } from './pages/productos/pages/cementopala/cementopala.component';
import { ChavetaComponent } from './pages/productos/pages/chaveta/chaveta.component';
import { ChavetamComponent } from './pages/productos/pages/chavetam/chavetam.component';
import { ChavetamiComponent } from './pages/productos/pages/chavetami/chavetami.component';
import { ChuchocablComponent } from './pages/productos/pages/chuchocabl/chuchocabl.component';
import { ChuchocabpComponent } from './pages/productos/pages/chuchocabp/chuchocabp.component';
import { CincelesComponent } from './pages/productos/pages/cinceles/cinceles.component';
import { ConicoaComponent } from './pages/productos/pages/conicoa/conicoa.component';
import { ConoaComponent } from './pages/productos/pages/conoa/conoa.component';
import { ConomComponent } from './pages/productos/pages/conom/conom.component';
import { CopaesComponent } from './pages/productos/pages/copaes/copaes.component';
import { CopapbComponent } from './pages/productos/pages/copapb/copapb.component';
import { CunacComponent } from './pages/productos/pages/cunac/cunac.component';
import { CunaiComponent } from './pages/productos/pages/cunai/cunai.component';
import { CunamComponent } from './pages/productos/pages/cunam/cunam.component';
import { CunamlComponent } from './pages/productos/pages/cunaml/cunaml.component';
import { EmpaquenComponent } from './pages/productos/pages/empaquen/empaquen.component';
import { EmpaqueniComponent } from './pages/productos/pages/empaqueni/empaqueni.component';
import { EmpaquepComponent } from './pages/productos/pages/empaquep/empaquep.component';
import { Esparragoa2Component } from './pages/productos/pages/esparragoa2/esparragoa2.component';
import { Esparragoa4Component } from './pages/productos/pages/esparragoa4/esparragoa4.component';
import { Esparragoa8Component } from './pages/productos/pages/esparragoa8/esparragoa8.component';
import { EsparragoasComponent } from './pages/productos/pages/esparragoas/esparragoas.component';
import { EsparragoasiComponent } from './pages/productos/pages/esparragoasi/esparragoasi.component';
import { EsparragoasfComponent } from './pages/productos/pages/esparragoasf/esparragoasf.component';
import { EsparragoasoComponent } from './pages/productos/pages/esparragoaso/esparragoaso.component';
import { EsparragoclComponent } from './pages/productos/pages/esparragocl/esparragocl.component';
import { EsparragocaneroComponent } from './pages/productos/pages/esparragocanero/esparragocanero.component';
import { EsparragocsComponent } from './pages/productos/pages/esparragocs/esparragocs.component';
import { EsparragocsfComponent } from './pages/productos/pages/esparragocsf/esparragocsf.component';
import { EsparragoceComponent } from './pages/productos/pages/esparragoce/esparragoce.component';
import { EsparragocfComponent } from './pages/productos/pages/esparragocf/esparragocf.component';
import { EsparragodpComponent } from './pages/productos/pages/esparragodp/esparragodp.component';
import { EsparragodpfoComponent } from './pages/productos/pages/esparragodpfo/esparragodpfo.component';
import { EsparragodpooComponent } from './pages/productos/pages/esparragodpoo/esparragodpoo.component';
import { EsparragoexComponent } from './pages/productos/pages/esparragoex/esparragoex.component';
import { EsparragoexasComponent } from './pages/productos/pages/esparragoexas/esparragoexas.component';
import { EsparragoexasiComponent } from './pages/productos/pages/esparragoexasi/esparragoexasi.component';
import { EsparragoexdComponent } from './pages/productos/pages/esparragoexd/esparragoexd.component';
import { EsparragoexdpdComponent } from './pages/productos/pages/esparragoexdpd/esparragoexdpd.component';
import { EsparragoexdpdaComponent } from './pages/productos/pages/esparragoexdpda/esparragoexdpda.component';
import { EsparragoexdpiComponent } from './pages/productos/pages/esparragoexdpi/esparragoexdpi.component';
import { EsparragoexdpsiComponent } from './pages/productos/pages/esparragoexdpsi/esparragoexdpsi.component';
import { EsparragoexsdComponent } from './pages/productos/pages/esparragoexsd/esparragoexsd.component';
import { EsparragodpffComponent } from './pages/productos/pages/esparragodpff/esparragodpff.component';
import { EsparragoexsmdComponent } from './pages/productos/pages/esparragoexsmd/esparragoexsmd.component';
import { EsparragomdpfoComponent } from './pages/productos/pages/esparragomdpfo/esparragomdpfo.component';
import { EsparragomreComponent } from './pages/productos/pages/esparragomre/esparragomre.component';
import { EsparragomsmComponent } from './pages/productos/pages/esparragomsm/esparragomsm.component';
import { EsparragosmComponent } from './pages/productos/pages/esparragosm/esparragosm.component';
import { EsparragoasexComponent } from './pages/productos/pages/esparragoasex/esparragoasex.component';
import { EstufacpComponent } from './pages/productos/pages/estufacp/estufacp.component';
import { Estufacp12Component } from './pages/productos/pages/estufacp12/estufacp12.component';
import { EstufacprfComponent } from './pages/productos/pages/estufacprf/estufacprf.component';
import { EstufarComponent } from './pages/productos/pages/estufar/estufar.component';
import { EstufarcComponent } from './pages/productos/pages/estufarc/estufarc.component';
import { EstufarnComponent } from './pages/productos/pages/estufarn/estufarn.component';
import { EstufarrfComponent } from './pages/productos/pages/estufarrf/estufarrf.component';
import { ExtractorameComponent } from './pages/productos/pages/extractorame/extractorame.component';
import { ExtrctoruComponent } from './pages/productos/pages/extrctoru/extrctoru.component';
import { FerruleameComponent } from './pages/productos/pages/ferruleame/ferruleame.component';
import { FijadorespComponent } from './pages/productos/pages/fijadoresp/fijadoresp.component';
import { FijadormrComponent } from './pages/productos/pages/fijadormr/fijadormr.component';
import { FijadortpComponent } from './pages/productos/pages/fijadortp/fijadortp.component';
import { GrapasComponent } from './pages/productos/pages/grapas/grapas.component';
import { GrapascuadradasComponent } from './pages/productos/pages/grapascuadradas/grapascuadradas.component';
import { GraseramComponent } from './pages/productos/pages/graseram/graseram.component';
import { GraseramiComponent } from './pages/productos/pages/graserami/graserami.component';
import { GraseramreComponent } from './pages/productos/pages/graseramre/graseramre.component';
import { GraseramreiComponent } from './pages/productos/pages/graseramrei/graseramrei.component';
import { GraserarcComponent } from './pages/productos/pages/graserarc/graserarc.component';
import { GraserarciComponent } from './pages/productos/pages/graserarci/graserarci.component';
import { GraserarfComponent } from './pages/productos/pages/graserarf/graserarf.component';
import { GraserarfiComponent } from './pages/productos/pages/graserarfi/graserarfi.component';
import { GrifosaComponent } from './pages/productos/pages/grifosa/grifosa.component';
import { GrifosmComponent } from './pages/productos/pages/grifosm/grifosm.component';
import { HerramientainsertoComponent } from './pages/productos/pages/herramientainserto/herramientainserto.component';
import { HerramientamultiComponent } from './pages/productos/pages/herramientamulti/herramientamulti.component';
import { HerramientapiComponent } from './pages/productos/pages/herramientapi/herramientapi.component';
import { HexagonalagricolaComponent } from './pages/productos/pages/hexagonalagricola/hexagonalagricola.component';
import { Hexagonalc10Component } from './pages/productos/pages/hexagonalc10/hexagonalc10.component';
import { Hexagonalc10rcComponent } from './pages/productos/pages/hexagonalc10rc/hexagonalc10rc.component';
import { Hexagonalc4Component } from './pages/productos/pages/hexagonalc4/hexagonalc4.component';
import { Hexagonalc8Component } from './pages/productos/pages/hexagonalc8/hexagonalc8.component';
import { Hexagonalc8rcComponent } from './pages/productos/pages/hexagonalc8rc/hexagonalc8rc.component';
import { HexagonalaluminioComponent } from './pages/productos/pages/hexagonalaluminio/hexagonalaluminio.component';
import { HexagonalbronceComponent } from './pages/productos/pages/hexagonalbronce/hexagonalbronce.component';
import { HexagonalplasticoComponent } from './pages/productos/pages/hexagonalplastico/hexagonalplastico.component';
import { HexagonalgComponent } from './pages/productos/pages/hexagonalg/hexagonalg.component';
import { HexagonalgcComponent } from './pages/productos/pages/hexagonalgc/hexagonalgc.component';
import { Hexagonalg5cComponent } from './pages/productos/pages/hexagonalg5c/hexagonalg5c.component';
import { HexagonalgrcComponent } from './pages/productos/pages/hexagonalgrc/hexagonalgrc.component';
import { Hexagonalg2rcComponent } from './pages/productos/pages/hexagonalg2rc/hexagonalg2rc.component';
import { Hexagonalg2rcgComponent } from './pages/productos/pages/hexagonalg2rcg/hexagonalg2rcg.component';
import { Hexagonalg5comerComponent } from './pages/productos/pages/hexagonalg5comer/hexagonalg5comer.component';
import { Hexagonalg5rcgComponent } from './pages/productos/pages/hexagonalg5rcg/hexagonalg5rcg.component';
import { Hexagonalg8cComponent } from './pages/productos/pages/hexagonalg8c/hexagonalg8c.component';
import { Hexagonalg8ptComponent } from './pages/productos/pages/hexagonalg8pt/hexagonalg8pt.component';
import { HexagonaliComponent } from './pages/productos/pages/hexagonali/hexagonali.component';
import { Hexagonalinox316Component } from './pages/productos/pages/hexagonalinox316/hexagonalinox316.component';
import { Hexagonalinoxa4Component } from './pages/productos/pages/hexagonalinoxa4/hexagonalinoxa4.component';
import { Hexagonalinoxa4rcComponent } from './pages/productos/pages/hexagonalinoxa4rc/hexagonalinoxa4rc.component';
import { HexagonalinoxrcComponent } from './pages/productos/pages/hexagonalinoxrc/hexagonalinoxrc.component';
import { HexagonalinoxrfComponent } from './pages/productos/pages/hexagonalinoxrf/hexagonalinoxrf.component';
import { HexagonalptComponent } from './pages/productos/pages/hexagonalpt/hexagonalpt.component';
import { HexagonalrceComponent } from './pages/productos/pages/hexagonalrce/hexagonalrce.component';
import { HexagonalreComponent } from './pages/productos/pages/hexagonalre/hexagonalre.component';
import { HexagonalrecpComponent } from './pages/productos/pages/hexagonalrecp/hexagonalrecp.component';
import { HexagonalrfcpComponent } from './pages/productos/pages/hexagonalrfcp/hexagonalrfcp.component';
import { Hexagonalrfc10Component } from './pages/productos/pages/hexagonalrfc10/hexagonalrfc10.component';
import { Hexagonalrfc8Component } from './pages/productos/pages/hexagonalrfc8/hexagonalrfc8.component';
import { Hexagonalrfg5Component } from './pages/productos/pages/hexagonalrfg5/hexagonalrfg5.component';
import { Hexagonalrfg5cComponent } from './pages/productos/pages/hexagonalrfg5c/hexagonalrfg5c.component';
import { Hexagonalrfg8Component } from './pages/productos/pages/hexagonalrfg8/hexagonalrfg8.component';
import { HexagonalringlesaComponent } from './pages/productos/pages/hexagonalringlesa/hexagonalringlesa.component';
import { HexagonalrizquierdaComponent } from './pages/productos/pages/hexagonalrizquierda/hexagonalrizquierda.component';
import { Hexagonalrpfc10Component } from './pages/productos/pages/hexagonalrpfc10/hexagonalrpfc10.component';
import { HexagonaltaponComponent } from './pages/productos/pages/hexagonaltapon/hexagonaltapon.component';
import { HexagonaltropicalizadoComponent } from './pages/productos/pages/hexagonaltropicalizado/hexagonaltropicalizado.component';
import { Hexagonaltropicalizadog5Component } from './pages/productos/pages/hexagonaltropicalizadog5/hexagonaltropicalizadog5.component';
import { HexagonaltropicalizadorcComponent } from './pages/productos/pages/hexagonaltropicalizadorc/hexagonaltropicalizadorc.component';
import { HitchpinComponent } from './pages/productos/pages/hitchpin/hitchpin.component';
import { InsertoamecoilComponent } from './pages/productos/pages/insertoamecoil/insertoamecoil.component';
import { InsertoautorosComponent } from './pages/productos/pages/insertoautoros/insertoautoros.component';
import { JuegoextractoresComponent } from './pages/productos/pages/juegoextractores/juegoextractores.component';
import { JuegoseguroseComponent } from './pages/productos/pages/juegosegurose/juegosegurose.component';
import { JuegosegurosiComponent } from './pages/productos/pages/juegosegurosi/juegosegurosi.component';
import { JuegosegurosieComponent } from './pages/productos/pages/juegosegurosie/juegosegurosie.component';
import { KitamecoilComponent } from './pages/productos/pages/kitamecoil/kitamecoil.component';
import { KitamecoilexComponent } from './pages/productos/pages/kitamecoilex/kitamecoilex.component';
import { KitamecoilmComponent } from './pages/productos/pages/kitamecoilm/kitamecoilm.component';
import { LlaveesparragoseguridadComponent } from './pages/productos/pages/llaveesparragoseguridad/llaveesparragoseguridad.component';
import { LaminaconicoComponent } from './pages/productos/pages/laminaconico/laminaconico.component';
import { LaminahexagonalComponent } from './pages/productos/pages/laminahexagonal/laminahexagonal.component';
import { LaminahexagonalinoxComponent } from './pages/productos/pages/laminahexagonalinox/laminahexagonalinox.component';
import { LaminahexagonalcastigaderaComponent } from './pages/productos/pages/laminahexagonalcastigadera/laminahexagonalcastigadera.component';
import { LaminahexagonalconcretoComponent } from './pages/productos/pages/laminahexagonalconcreto/laminahexagonalconcreto.component';
import { LaminahexagonalpbComponent } from './pages/productos/pages/laminahexagonalpb/laminahexagonalpb.component';
import { LaminahexagonalpbcComponent } from './pages/productos/pages/laminahexagonalpbc/laminahexagonalpbc.component';
import { LaminahexagonalpbiComponent } from './pages/productos/pages/laminahexagonalpbi/laminahexagonalpbi.component';
import { LaminahexagonalpbpComponent } from './pages/productos/pages/laminahexagonalpbp/laminahexagonalpbp.component';
import { LaminahexagonalspComponent } from './pages/productos/pages/laminahexagonalsp/laminahexagonalsp.component';
import { LaminaplanacinoxComponent } from './pages/productos/pages/laminaplanacinox/laminaplanacinox.component';
import { LaminaplanapcromadoComponent } from './pages/productos/pages/laminaplanapcromado/laminaplanapcromado.component';
import { LaminaplanapinoxComponent } from './pages/productos/pages/laminaplanapinox/laminaplanapinox.component';
import { LaminaplanapnegroComponent } from './pages/productos/pages/laminaplanapnegro/laminaplanapnegro.component';
import { LaminaplanappbComponent } from './pages/productos/pages/laminaplanappb/laminaplanappb.component';
import { LaminaplanaprComponent } from './pages/productos/pages/laminaplanapr/laminaplanapr.component';
import { LaminaplanaptgComponent } from './pages/productos/pages/laminaplanaptg/laminaplanaptg.component';
import { LaminaplanocComponent } from './pages/productos/pages/laminaplanoc/laminaplanoc.component';
import { LaminaplanopComponent } from './pages/productos/pages/laminaplanop/laminaplanop.component';
import { LaminaredondapcComponent } from './pages/productos/pages/laminaredondapc/laminaredondapc.component';
import { LaminaredondapnComponent } from './pages/productos/pages/laminaredondapn/laminaredondapn.component';
import { LaminaredondaqpbComponent } from './pages/productos/pages/laminaredondaqpb/laminaredondaqpb.component';
import { LaminaredondocComponent } from './pages/productos/pages/laminaredondoc/laminaredondoc.component';
import { LaminaredondociComponent } from './pages/productos/pages/laminaredondoci/laminaredondoci.component';
import { LaminaredondocombinadoComponent } from './pages/productos/pages/laminaredondocombinado/laminaredondocombinado.component';
import { LaminaredondopComponent } from './pages/productos/pages/laminaredondop/laminaredondop.component';
import { LaminaredondopiComponent } from './pages/productos/pages/laminaredondopi/laminaredondopi.component';
import { LaminaredondoppbComponent } from './pages/productos/pages/laminaredondoppb/laminaredondoppb.component';
import { LaminaredondoppbiComponent } from './pages/productos/pages/laminaredondoppbi/laminaredondoppbi.component';
import { LaminaredondotgComponent } from './pages/productos/pages/laminaredondotg/laminaredondotg.component';
import { LiquidoantisobadoComponent } from './pages/productos/pages/liquidoantisobado/liquidoantisobado.component';
import { LlaveallenComponent } from './pages/productos/pages/llaveallen/llaveallen.component';
import { LlaveallenseguridadComponent } from './pages/productos/pages/llaveallenseguridad/llaveallenseguridad.component';
import { LlaveallenesfericaComponent } from './pages/productos/pages/llaveallenesferica/llaveallenesferica.component';
import { LlavetorxComponent } from './pages/productos/pages/llavetorx/llavetorx.component';
import { LlavecolaComponent } from './pages/productos/pages/llavecola/llavecola.component';
import { LubricantealimenticioComponent } from './pages/productos/pages/lubricantealimenticio/lubricantealimenticio.component';
import { LubricantegeneralComponent } from './pages/productos/pages/lubricantegeneral/lubricantegeneral.component';
import { LubricanteinoxidableComponent } from './pages/productos/pages/lubricanteinoxidable/lubricanteinoxidable.component';
import { LubricantemarinoComponent } from './pages/productos/pages/lubricantemarino/lubricantemarino.component';
import { MachuelobspComponent } from './pages/productos/pages/machuelobsp/machuelobsp.component';
import { MachuelonptComponent } from './pages/productos/pages/machuelonpt/machuelonpt.component';
import { Machuelomecanico15Component } from './pages/productos/pages/machuelomecanico15/machuelomecanico15.component';
import { Machuelomecanico35Component } from './pages/productos/pages/machuelomecanico35/machuelomecanico35.component';
import { MachuelometricoexComponent } from './pages/productos/pages/machuelometricoex/machuelometricoex.component';
import { MachuelopinoxComponent } from './pages/productos/pages/machuelopinox/machuelopinox.component';
import { MachueloamecoilComponent } from './pages/productos/pages/machueloamecoil/machueloamecoil.component';
import { MachuelosComponent } from './pages/productos/pages/machuelos/machuelos.component';
import { ManeralmachueloComponent } from './pages/productos/pages/maneralmachuelo/maneralmachuelo.component';
import { ManeraltarrajaComponent } from './pages/productos/pages/maneraltarraja/maneraltarraja.component';
import { MaquinaplanaComponent } from './pages/productos/pages/maquinaplana/maquinaplana.component';
import { MaquinaredondaComponent } from './pages/productos/pages/maquinaredonda/maquinaredonda.component';
import { MaquinaredondabComponent } from './pages/productos/pages/maquinaredondab/maquinaredondab.component';
import { MueladelanteraComponent } from './pages/productos/pages/mueladelantera/mueladelantera.component';
import { MuelatraseraComponent } from './pages/productos/pages/muelatrasera/muelatrasera.component';
import { OringbunanComponent } from './pages/productos/pages/oringbunan/oringbunan.component';
import { OringvitonComponent } from './pages/productos/pages/oringviton/oringviton.component';
import { PinconicoComponent } from './pages/productos/pages/pinconico/pinconico.component';
import { PindowelComponent } from './pages/productos/pages/pindowel/pindowel.component';
import { PintensionComponent } from './pages/productos/pages/pintension/pintension.component';
import { PintensionamericanoComponent } from './pages/productos/pages/pintensionamericano/pintensionamericano.component';
import { PintensioninoxidableComponent } from './pages/productos/pages/pintensioninoxidable/pintensioninoxidable.component';
import { PinzasegurosComponent } from './pages/productos/pages/pinzaseguros/pinzaseguros.component';
import { ProtectorComponent } from './pages/productos/pages/protector/protector.component';
import { Punta1Component } from './pages/productos/pages/punta1/punta1.component';
import { Punta3Component } from './pages/productos/pages/punta3/punta3.component';
import { RemachealuminioComponent } from './pages/productos/pages/remachealuminio/remachealuminio.component';
import { RemachehierroComponent } from './pages/productos/pages/remachehierro/remachehierro.component';
import { RemachepopComponent } from './pages/productos/pages/remachepop/remachepop.component';
import { Punta2Component } from './pages/productos/pages/punta2/punta2.component';
import { RemachepopcbComponent } from './pages/productos/pages/remachepopcb/remachepopcb.component';
import { RemachepopcnComponent } from './pages/productos/pages/remachepopcn/remachepopcn.component';
import { RemachepopctComponent } from './pages/productos/pages/remachepopct/remachepopct.component';
import { RemachepopaaComponent } from './pages/productos/pages/remachepopaa/remachepopaa.component';
import { RemachepopinoxidableComponent } from './pages/productos/pages/remachepopinoxidable/remachepopinoxidable.component';
import { ResorteconpataComponent } from './pages/productos/pages/resorteconpata/resorteconpata.component';
import { ResortecompresionComponent } from './pages/productos/pages/resortecompresion/resortecompresion.component';
import { ResorteestirarComponent } from './pages/productos/pages/resorteestirar/resorteestirar.component';
import { ResorteslComponent } from './pages/productos/pages/resortesl/resortesl.component';
import { RoldanabiseladaComponent } from './pages/productos/pages/roldanabiselada/roldanabiselada.component';
import { RoldanacnaturalComponent } from './pages/productos/pages/roldanacnatural/roldanacnatural.component';
import { RoldanacomercialComponent } from './pages/productos/pages/roldanacomercial/roldanacomercial.component';
import { RoldanaconcavaComponent } from './pages/productos/pages/roldanaconcava/roldanaconcava.component';
import { Roldanaajuste01Component } from './pages/productos/pages/roldanaajuste01/roldanaajuste01.component';
import { Roldanaajuste03Component } from './pages/productos/pages/roldanaajuste03/roldanaajuste03.component';
import { Roldanaajuste05Component } from './pages/productos/pages/roldanaajuste05/roldanaajuste05.component';
import { Roldanaajuste1Component } from './pages/productos/pages/roldanaajuste1/roldanaajuste1.component';
import { RoldanaaluminioComponent } from './pages/productos/pages/roldanaaluminio/roldanaaluminio.component';
import { RoldanaaluminiosellarComponent } from './pages/productos/pages/roldanaaluminiosellar/roldanaaluminiosellar.component';
import { Roldanaanilloinox316Component } from './pages/productos/pages/roldanaanilloinox316/roldanaanilloinox316.component';
import { RoldanacobreComponent } from './pages/productos/pages/roldanacobre/roldanacobre.component';
import { RoldanalujoComponent } from './pages/productos/pages/roldanalujo/roldanalujo.component';
import { RoldanaplasticoComponent } from './pages/productos/pages/roldanaplastico/roldanaplastico.component';
import { Roldanaseguridad1Component } from './pages/productos/pages/roldanaseguridad1/roldanaseguridad1.component';
import { Roldanaseguridad2Component } from './pages/productos/pages/roldanaseguridad2/roldanaseguridad2.component';
import { Roldanaseguridad4Component } from './pages/productos/pages/roldanaseguridad4/roldanaseguridad4.component';
import { Roldanaseguridad5Component } from './pages/productos/pages/roldanaseguridad5/roldanaseguridad5.component';
import { Roldanaseguridad6Component } from './pages/productos/pages/roldanaseguridad6/roldanaseguridad6.component';
import { RoldanatapiceriaComponent } from './pages/productos/pages/roldanatapiceria/roldanatapiceria.component';
import { RoldanaestructuralComponent } from './pages/productos/pages/roldanaestructural/roldanaestructural.component';
import { RoldanafenderComponent } from './pages/productos/pages/roldanafender/roldanafender.component';
import { RoldanafenderinoxComponent } from './pages/productos/pages/roldanafenderinox/roldanafenderinox.component';
import { RoldanagalvanizadaComponent } from './pages/productos/pages/roldanagalvanizada/roldanagalvanizada.component';
import { RoldanagalvanizadacalienteComponent } from './pages/productos/pages/roldanagalvanizadacaliente/roldanagalvanizadacaliente.component';
import { RoldanagalvanizadaanilloComponent } from './pages/productos/pages/roldanagalvanizadaanillo/roldanagalvanizadaanillo.component';
import { RoldanagruesasujecionComponent } from './pages/productos/pages/roldanagruesasujecion/roldanagruesasujecion.component';
import { RoldanainoxidableComponent } from './pages/productos/pages/roldanainoxidable/roldanainoxidable.component';
import { Roldanainoxidable316Component } from './pages/productos/pages/roldanainoxidable316/roldanainoxidable316.component';
import { Roldanainoxidablea4Component } from './pages/productos/pages/roldanainoxidablea4/roldanainoxidablea4.component';
import { RoldanainoxidableanilloComponent } from './pages/productos/pages/roldanainoxidableanillo/roldanainoxidableanillo.component';
import { RoldananaturalComponent } from './pages/productos/pages/roldananatural/roldananatural.component';
import { RoldanaonduladaComponent } from './pages/productos/pages/roldanaondulada/roldanaondulada.component';
import { RoldanaonduladatropicalizadaComponent } from './pages/productos/pages/roldanaonduladatropicalizada/roldanaonduladatropicalizada.component';
import { RoldanatropicalizadaComponent } from './pages/productos/pages/roldanatropicalizada/roldanatropicalizada.component';
import { RoldanabronceComponent } from './pages/productos/pages/roldanabronce/roldanabronce.component';
import { SeguroaranaComponent } from './pages/productos/pages/seguroarana/seguroarana.component';
import { SeguroclipComponent } from './pages/productos/pages/seguroclip/seguroclip.component';
import { SeguroclipinoxComponent } from './pages/productos/pages/seguroclipinox/seguroclipinox.component';
import { SegurosujecionranuraComponent } from './pages/productos/pages/segurosujecionranura/segurosujecionranura.component';
import { SegurosujecionanilloComponent } from './pages/productos/pages/segurosujecionanillo/segurosujecionanillo.component';
import { SeguroexternoComponent } from './pages/productos/pages/seguroexterno/seguroexterno.component';
import { SeguroexternoinoxComponent } from './pages/productos/pages/seguroexternoinox/seguroexternoinox.component';
import { SegurointernoComponent } from './pages/productos/pages/segurointerno/segurointerno.component';
import { SegurointernoinoxComponent } from './pages/productos/pages/segurointernoinox/segurointernoinox.component';
import { SegurocajaComponent } from './pages/productos/pages/segurocaja/segurocaja.component';
import { SierraComponent } from './pages/productos/pages/sierra/sierra.component';
import { SilicongrisComponent } from './pages/productos/pages/silicongris/silicongris.component';
import { SiliconnegroComponent } from './pages/productos/pages/siliconnegro/siliconnegro.component';
import { SiliconrojaComponent } from './pages/productos/pages/siliconroja/siliconroja.component';
import { SilicontransparenteComponent } from './pages/productos/pages/silicontransparente/silicontransparente.component';
import { SoldadurafrioComponent } from './pages/productos/pages/soldadurafrio/soldadurafrio.component';
import { SoldaduratransparenteComponent } from './pages/productos/pages/soldaduratransparente/soldaduratransparente.component';
import { SuperglueComponent } from './pages/productos/pages/superglue/superglue.component';
import { TablaComponent } from './pages/productos/pages/tabla/tabla.component';
import { TablayesoComponent } from './pages/productos/pages/tablayeso/tablayeso.component';
import { TablayesocornetaComponent } from './pages/productos/pages/tablayesocorneta/tablayesocorneta.component';
import { TablayesocornetadacrometComponent } from './pages/productos/pages/tablayesocornetadacromet/tablayesocornetadacromet.component';
import { TablayesocornetadobleroscaComponent } from './pages/productos/pages/tablayesocornetadoblerosca/tablayesocornetadoblerosca.component';
import { TablayesoobleapbComponent } from './pages/productos/pages/tablayesoobleapb/tablayesoobleapb.component';
import { TablayesoobleapbaComponent } from './pages/productos/pages/tablayesoobleapba/tablayesoobleapba.component';
import { TablayesoplanarecortadaComponent } from './pages/productos/pages/tablayesoplanarecortada/tablayesoplanarecortada.component';
import { TablayesoredondaComponent } from './pages/productos/pages/tablayesoredonda/tablayesoredonda.component';
import { TablayesoredondaoComponent } from './pages/productos/pages/tablayesoredondao/tablayesoredondao.component';
import { TablayesopbpqComponent } from './pages/productos/pages/tablayesopbpq/tablayesopbpq.component';
import { TablayesopbrComponent } from './pages/productos/pages/tablayesopbr/tablayesopbr.component';
import { TablayesopbComponent } from './pages/productos/pages/tablayesopb/tablayesopb.component';
import { TablayesopbgComponent } from './pages/productos/pages/tablayesopbg/tablayesopbg.component';
import { TablayesorpbComponent } from './pages/productos/pages/tablayesorpb/tablayesorpb.component';
import { TablayesorpbgComponent } from './pages/productos/pages/tablayesorpbg/tablayesorpbg.component';
import { TablayesorrComponent } from './pages/productos/pages/tablayesorr/tablayesorr.component';
import { TablayesorrnComponent } from './pages/productos/pages/tablayesorrn/tablayesorrn.component';
import { TablayesorrpbComponent } from './pages/productos/pages/tablayesorrpb/tablayesorrpb.component';
import { TablayesorrpbnComponent } from './pages/productos/pages/tablayesorrpbn/tablayesorrpbn.component';
import { TablayesotComponent } from './pages/productos/pages/tablayesot/tablayesot.component';
import { TaponallenComponent } from './pages/productos/pages/taponallen/taponallen.component';
import { TaponallenbspComponent } from './pages/productos/pages/taponallenbsp/taponallenbsp.component';
import { TaponallennptComponent } from './pages/productos/pages/taponallennpt/taponallennpt.component';
import { TaponhexagonalComponent } from './pages/productos/pages/taponhexagonal/taponhexagonal.component';
import { TarrajahssgComponent } from './pages/productos/pages/tarrajahssg/tarrajahssg.component';
import { TarrajasComponent } from './pages/productos/pages/tarrajas/tarrajas.component';
import { TarugoexpansionComponent } from './pages/productos/pages/tarugoexpansion/tarugoexpansion.component';
import { TarugoexpansiondobleComponent } from './pages/productos/pages/tarugoexpansiondoble/tarugoexpansiondoble.component';
import { TarugofisherComponent } from './pages/productos/pages/tarugofisher/tarugofisher.component';
import { TarugofishercomercialComponent } from './pages/productos/pages/tarugofishercomercial/tarugofishercomercial.component';
import { TarugoplasticoComponent } from './pages/productos/pages/tarugoplastico/tarugoplastico.component';
import { TeflonliquidoComponent } from './pages/productos/pages/teflonliquido/teflonliquido.component';
import { TermometrodigitalComponent } from './pages/productos/pages/termometrodigital/termometrodigital.component';
import { TipospaxComponent } from './pages/productos/pages/tipospax/tipospax.component';
import { TipospaxnegroComponent } from './pages/productos/pages/tipospaxnegro/tipospaxnegro.component';
import { TornilloestufohexagonalComponent } from './pages/productos/pages/tornilloestufohexagonal/tornilloestufohexagonal.component';
import { TornilloestufohexagonalfinoComponent } from './pages/productos/pages/tornilloestufohexagonalfino/tornilloestufohexagonalfino.component';
import { TornillocuadradoComponent } from './pages/productos/pages/tornillocuadrado/tornillocuadrado.component';
import { TornilloplanoseguridadComponent } from './pages/productos/pages/tornilloplanoseguridad/tornilloplanoseguridad.component';
import { TornillocarroceroComponent } from './pages/productos/pages/tornillocarrocero/tornillocarrocero.component';
import { TornillocarrocerogalvanizadoComponent } from './pages/productos/pages/tornillocarrocerogalvanizado/tornillocarrocerogalvanizado.component';
import { Tornillocarrocerog5Component } from './pages/productos/pages/tornillocarrocerog5/tornillocarrocerog5.component';
import { TornillocarroceroinoxComponent } from './pages/productos/pages/tornillocarroceroinox/tornillocarroceroinox.component';
import { TornillocentroComponent } from './pages/productos/pages/tornillocentro/tornillocentro.component';
import { TornillocentrometricoComponent } from './pages/productos/pages/tornillocentrometrico/tornillocentrometrico.component';
import { TornilloelevadorComponent } from './pages/productos/pages/tornilloelevador/tornilloelevador.component';
import { TornilloelevadorpuntasComponent } from './pages/productos/pages/tornilloelevadorpuntas/tornilloelevadorpuntas.component';
import { TornilloelevadorgalvanizadoComponent } from './pages/productos/pages/tornilloelevadorgalvanizado/tornilloelevadorgalvanizado.component';
import { TornilloelevadorinoxComponent } from './pages/productos/pages/tornilloelevadorinox/tornilloelevadorinox.component';
import { TornilloestructuralComponent } from './pages/productos/pages/tornilloestructural/tornilloestructural.component';
import { TornilloestdComponent } from './pages/productos/pages/tornilloestd/tornilloestd.component';
import { TornillocangilonComponent } from './pages/productos/pages/tornillocangilon/tornillocangilon.component';
import { TornillomaderaplanoComponent } from './pages/productos/pages/tornillomaderaplano/tornillomaderaplano.component';
import { TornillomaderaojoComponent } from './pages/productos/pages/tornillomaderaojo/tornillomaderaojo.component';
import { TornillomaderapaComponent } from './pages/productos/pages/tornillomaderapa/tornillomaderapa.component';
import { TornillomaderarrComponent } from './pages/productos/pages/tornillomaderarr/tornillomaderarr.component';
import { TornillomaderarpaComponent } from './pages/productos/pages/tornillomaderarpa/tornillomaderarpa.component';
import { TornillomuebleacComponent } from './pages/productos/pages/tornillomuebleac/tornillomuebleac.component';
import { TornillomuebleatpComponent } from './pages/productos/pages/tornillomuebleatp/tornillomuebleatp.component';
import { TornillomuebledpComponent } from './pages/productos/pages/tornillomuebledp/tornillomuebledp.component';
import { TornillomuebledpgComponent } from './pages/productos/pages/tornillomuebledpg/tornillomuebledpg.component';
import { Tornillorastrag5Component } from './pages/productos/pages/tornillorastrag5/tornillorastrag5.component';
import { TornillopdrcComponent } from './pages/productos/pages/tornillopdrc/tornillopdrc.component';
import { TornilloprisionerocuadradoComponent } from './pages/productos/pages/tornilloprisionerocuadrado/tornilloprisionerocuadrado.component';
import { TornillotirabuzonComponent } from './pages/productos/pages/tornillotirabuzon/tornillotirabuzon.component';
import { TornillotirabuzoninoxComponent } from './pages/productos/pages/tornillotirabuzoninox/tornillotirabuzoninox.component';
import { Tornillotorx12ladosComponent } from './pages/productos/pages/tornillotorx12lados/tornillotorx12lados.component';
import { Tornillotorx12ladosfinoComponent } from './pages/productos/pages/tornillotorx12ladosfino/tornillotorx12ladosfino.component';
import { TorxcilindricogComponent } from './pages/productos/pages/torxcilindricog/torxcilindricog.component';
import { TorxbotonComponent } from './pages/productos/pages/torxboton/torxboton.component';
import { TorxplanoComponent } from './pages/productos/pages/torxplano/torxplano.component';
import { TuercasegurogolpeComponent } from './pages/productos/pages/tuercasegurogolpe/tuercasegurogolpe.component';
import { Tuerca2hComponent } from './pages/productos/pages/tuerca2h/tuerca2h.component';
import { TuercaallencrComponent } from './pages/productos/pages/tuercaallencr/tuercaallencr.component';
import { TuercaanclajemComponent } from './pages/productos/pages/tuercaanclajem/tuercaanclajem.component';
import { TuercaartilleraComponent } from './pages/productos/pages/tuercaartillera/tuercaartillera.component';
import { Tuercab7Component } from './pages/productos/pages/tuercab7/tuercab7.component';
import { TuercabraComponent } from './pages/productos/pages/tuercabra/tuercabra.component';
import { TuercabrarComponent } from './pages/productos/pages/tuercabrar/tuercabrar.component';
import { TuercaceComponent } from './pages/productos/pages/tuercace/tuercace.component';
import { TuercacedComponent } from './pages/productos/pages/tuercaced/tuercaced.component';
import { TuercaceiComponent } from './pages/productos/pages/tuercacei/tuercacei.component';
import { TuercacfdComponent } from './pages/productos/pages/tuercacfd/tuercacfd.component';
import { TuercacfiComponent } from './pages/productos/pages/tuercacfi/tuercacfi.component';
import { TuercacbreComponent } from './pages/productos/pages/tuercacbre/tuercacbre.component';
import { TuercacbrfComponent } from './pages/productos/pages/tuercacbrf/tuercacbrf.component';
import { TuercacseComponent } from './pages/productos/pages/tuercacse/tuercacse.component';
import { TuercacsfComponent } from './pages/productos/pages/tuercacsf/tuercacsf.component';
import { TuercacefComponent } from './pages/productos/pages/tuercacef/tuercacef.component';
import { TuercacfComponent } from './pages/productos/pages/tuercacf/tuercacf.component';
import { TuercaclreComponent } from './pages/productos/pages/tuercaclre/tuercaclre.component';
import { TuercaclrfComponent } from './pages/productos/pages/tuercaclrf/tuercaclrf.component';
import { TuercacltreComponent } from './pages/productos/pages/tuercacltre/tuercacltre.component';
import { TuercacltrfComponent } from './pages/productos/pages/tuercacltrf/tuercacltrf.component';
import { TuercacreComponent } from './pages/productos/pages/tuercacre/tuercacre.component';
import { TuercacrfComponent } from './pages/productos/pages/tuercacrf/tuercacrf.component';
import { TuercacrfiComponent } from './pages/productos/pages/tuercacrfi/tuercacrfi.component';
import { TuercacroComponent } from './pages/productos/pages/tuercacro/tuercacro.component';
import { TuercacilindricaabComponent } from './pages/productos/pages/tuercacilindricaab/tuercacilindricaab.component';
import { TuercacilindricaacrComponent } from './pages/productos/pages/tuercacilindricaacr/tuercacilindricaacr.component';
import { TuercacilindricaalComponent } from './pages/productos/pages/tuercacilindricaal/tuercacilindricaal.component';
import { TuercacilindricaastrilladaComponent } from './pages/productos/pages/tuercacilindricaastrillada/tuercacilindricaastrillada.component';
import { TuercacuadradaComponent } from './pages/productos/pages/tuercacuadrada/tuercacuadrada.component';
import { TuercabronceComponent } from './pages/productos/pages/tuercabronce/tuercabronce.component';
import { TuercacapiruchoComponent } from './pages/productos/pages/tuercacapirucho/tuercacapirucho.component';
import { TuercachuchoComponent } from './pages/productos/pages/tuercachucho/tuercachucho.component';
import { TuercacapiruchoiComponent } from './pages/productos/pages/tuercacapiruchoi/tuercacapiruchoi.component';
import { TuercachuchotuboComponent } from './pages/productos/pages/tuercachuchotubo/tuercachuchotubo.component';
import { TuercachuchoiComponent } from './pages/productos/pages/tuercachuchoi/tuercachuchoi.component';
import { TuercachucholujoComponent } from './pages/productos/pages/tuercachucholujo/tuercachucholujo.component';
import { TuercachucholujoiComponent } from './pages/productos/pages/tuercachucholujoi/tuercachucholujoi.component';
import { TuercachucholujotrComponent } from './pages/productos/pages/tuercachucholujotr/tuercachucholujotr.component';
import { TuercacoplaComponent } from './pages/productos/pages/tuercacopla/tuercacopla.component';
import { TuercacoplaiComponent } from './pages/productos/pages/tuercacoplai/tuercacoplai.component';
import { TuercacoronaComponent } from './pages/productos/pages/tuercacorona/tuercacorona.component';
import { TuercacoronaeComponent } from './pages/productos/pages/tuercacoronae/tuercacoronae.component';
import { TuercacoronafComponent } from './pages/productos/pages/tuercacoronaf/tuercacoronaf.component';
import { TuercacoronaoComponent } from './pages/productos/pages/tuercacoronao/tuercacoronao.component';
import { TuercainsercionComponent } from './pages/productos/pages/tuercainsercion/tuercainsercion.component';
import { TuercainsercionmComponent } from './pages/productos/pages/tuercainsercionm/tuercainsercionm.component';
import { TuercalanaComponent } from './pages/productos/pages/tuercalana/tuercalana.component';
import { TuercamariposaComponent } from './pages/productos/pages/tuercamariposa/tuercamariposa.component';
import { TuercamariposainoxComponent } from './pages/productos/pages/tuercamariposainox/tuercamariposainox.component';
import { TuercamariposafComponent } from './pages/productos/pages/tuercamariposaf/tuercamariposaf.component';
import { TuercaplasticaComponent } from './pages/productos/pages/tuercaplastica/tuercaplastica.component';
import { TuercaseguridadrComponent } from './pages/productos/pages/tuercaseguridadr/tuercaseguridadr.component';
import { TuercaseguridadmreComponent } from './pages/productos/pages/tuercaseguridadmre/tuercaseguridadmre.component';
import { TuercaseguridadmrfComponent } from './pages/productos/pages/tuercaseguridadmrf/tuercaseguridadmrf.component';
import { TuercaseguridadpreComponent } from './pages/productos/pages/tuercaseguridadpre/tuercaseguridadpre.component';
import { TuercaseguridadprfComponent } from './pages/productos/pages/tuercaseguridadprf/tuercaseguridadprf.component';
import { TuercaseguridadcrfComponent } from './pages/productos/pages/tuercaseguridadcrf/tuercaseguridadcrf.component';
import { TuercaseguridadgaComponent } from './pages/productos/pages/tuercaseguridadga/tuercaseguridadga.component';
import { TuercaseguridadgaaComponent } from './pages/productos/pages/tuercaseguridadgaa/tuercaseguridadgaa.component';
import { TuercaseguridadgrComponent } from './pages/productos/pages/tuercaseguridadgr/tuercaseguridadgr.component';
import { TuercaseguridadgcComponent } from './pages/productos/pages/tuercaseguridadgc/tuercaseguridadgc.component';
import { TuercaseguridadgiComponent } from './pages/productos/pages/tuercaseguridadgi/tuercaseguridadgi.component';
import { TuercaseguridadgmComponent } from './pages/productos/pages/tuercaseguridadgm/tuercaseguridadgm.component';
import { TuercaseguridadgoComponent } from './pages/productos/pages/tuercaseguridadgo/tuercaseguridadgo.component';
import { TuercaseguridadmetalComponent } from './pages/productos/pages/tuercaseguridadmetal/tuercaseguridadmetal.component';
import { TuercaseguridadplasticaComponent } from './pages/productos/pages/tuercaseguridadplastica/tuercaseguridadplastica.component';
import { TuercaseguridadplasticainoxComponent } from './pages/productos/pages/tuercaseguridadplasticainox/tuercaseguridadplasticainox.component';
import { TuercaseguridadplasticafpComponent } from './pages/productos/pages/tuercaseguridadplasticafp/tuercaseguridadplasticafp.component';
import { TuercaseguridadgpComponent } from './pages/productos/pages/tuercaseguridadgp/tuercaseguridadgp.component';
import { TuercaseguridadgprComponent } from './pages/productos/pages/tuercaseguridadgpr/tuercaseguridadgpr.component';
import { TuercataponComponent } from './pages/productos/pages/tuercatapon/tuercatapon.component';
import { TuercataponinoxComponent } from './pages/productos/pages/tuercataponinox/tuercataponinox.component';
import { TuercahexagonalComponent } from './pages/productos/pages/tuercahexagonal/tuercahexagonal.component';
import { TuercahexagonalcaneradComponent } from './pages/productos/pages/tuercahexagonalcanerad/tuercahexagonalcanerad.component';
import { TuercahexagonalcaneraiComponent } from './pages/productos/pages/tuercahexagonalcanerai/tuercahexagonalcanerai.component';
import { TuercahexagonalranuraComponent } from './pages/productos/pages/tuercahexagonalranura/tuercahexagonalranura.component';
import { TuercahexagonalranurafComponent } from './pages/productos/pages/tuercahexagonalranuraf/tuercahexagonalranuraf.component';
import { TuercahexagonalcmComponent } from './pages/productos/pages/tuercahexagonalcm/tuercahexagonalcm.component';
import { TuercahexagonalaluminioComponent } from './pages/productos/pages/tuercahexagonalaluminio/tuercahexagonalaluminio.component';
import { TuercahexagonalalbronceComponent } from './pages/productos/pages/tuercahexagonalalbronce/tuercahexagonalalbronce.component';
import { TuercahexagonalbroncefComponent } from './pages/productos/pages/tuercahexagonalbroncef/tuercahexagonalbroncef.component';
import { TuercahexagonalcoronaeComponent } from './pages/productos/pages/tuercahexagonalcoronae/tuercahexagonalcoronae.component';
import { TuercahexagonalestructuralComponent } from './pages/productos/pages/tuercahexagonalestructural/tuercahexagonalestructural.component';
import { TuercahexagonalgalvanizadaComponent } from './pages/productos/pages/tuercahexagonalgalvanizada/tuercahexagonalgalvanizada.component';
import { Tuercahexagonalgalvanizadac4Component } from './pages/productos/pages/tuercahexagonalgalvanizadac4/tuercahexagonalgalvanizadac4.component';
import { TuercahexagonalgalvanizadacalienteComponent } from './pages/productos/pages/tuercahexagonalgalvanizadacaliente/tuercahexagonalgalvanizadacaliente.component';
import { Tuercahexagonalgalvanizadag2Component } from './pages/productos/pages/tuercahexagonalgalvanizadag2/tuercahexagonalgalvanizadag2.component';
import { Tuercahexagonalgalvanizadag5Component } from './pages/productos/pages/tuercahexagonalgalvanizadag5/tuercahexagonalgalvanizadag5.component';
import { Tuercahexagonalgrado2Component } from './pages/productos/pages/tuercahexagonalgrado2/tuercahexagonalgrado2.component';
import { Tuercahexagonalgrado5Component } from './pages/productos/pages/tuercahexagonalgrado5/tuercahexagonalgrado5.component';
import { TuercahexagonalinglesaComponent } from './pages/productos/pages/tuercahexagonalinglesa/tuercahexagonalinglesa.component';
import { TuercahexagonalinoxidableComponent } from './pages/productos/pages/tuercahexagonalinoxidable/tuercahexagonalinoxidable.component';
import { Tuercahexagonalinoxidable316Component } from './pages/productos/pages/tuercahexagonalinoxidable316/tuercahexagonalinoxidable316.component';
import { Tuercahexagonalinoxidablea4Component } from './pages/productos/pages/tuercahexagonalinoxidablea4/tuercahexagonalinoxidablea4.component';
import { TuercahexagonalinoxidablerfComponent } from './pages/productos/pages/tuercahexagonalinoxidablerf/tuercahexagonalinoxidablerf.component';
import { TuercahexagonalizquierdaComponent } from './pages/productos/pages/tuercahexagonalizquierda/tuercahexagonalizquierda.component';
import { TuercahexagonalizquierdarfComponent } from './pages/productos/pages/tuercahexagonalizquierdarf/tuercahexagonalizquierdarf.component';
import { TuercahexagonalpacheComponent } from './pages/productos/pages/tuercahexagonalpache/tuercahexagonalpache.component';
import { TuercahexagonalpacheinoxComponent } from './pages/productos/pages/tuercahexagonalpacheinox/tuercahexagonalpacheinox.component';
import { TuercahexagonalreComponent } from './pages/productos/pages/tuercahexagonalre/tuercahexagonalre.component';
import { TuercahexagonalrepComponent } from './pages/productos/pages/tuercahexagonalrep/tuercahexagonalrep.component';
import { TuercahexagonalrfComponent } from './pages/productos/pages/tuercahexagonalrf/tuercahexagonalrf.component';
import { TuercahexagonalrfgComponent } from './pages/productos/pages/tuercahexagonalrfg/tuercahexagonalrfg.component';
import { TuercahexagonalrfiComponent } from './pages/productos/pages/tuercahexagonalrfi/tuercahexagonalrfi.component';
import { TuercahexagonalrfpComponent } from './pages/productos/pages/tuercahexagonalrfp/tuercahexagonalrfp.component';
import { TuercahexagonaltropicalizadaComponent } from './pages/productos/pages/tuercahexagonaltropicalizada/tuercahexagonaltropicalizada.component';
import { TuercamuescaComponent } from './pages/productos/pages/tuercamuesca/tuercamuesca.component';
import { TuercapacheComponent } from './pages/productos/pages/tuercapache/tuercapache.component';
import { TuercapachefiComponent } from './pages/productos/pages/tuercapachefi/tuercapachefi.component';
import { TuercaseguridadmunonComponent } from './pages/productos/pages/tuercaseguridadmunon/tuercaseguridadmunon.component';
import { TuercataponallencComponent } from './pages/productos/pages/tuercataponallenc/tuercataponallenc.component';
import { TuercataponallencrComponent } from './pages/productos/pages/tuercataponallencr/tuercataponallencr.component';
import { TuercatransversalComponent } from './pages/productos/pages/tuercatransversal/tuercatransversal.component';
import { TuercatractorComponent } from './pages/productos/pages/tuercatractor/tuercatractor.component';
import { WashaComponent } from './pages/productos/pages/washa/washa.component';
import { WashaallenComponent } from './pages/productos/pages/washaallen/washaallen.component';
import { WashaalleniComponent } from './pages/productos/pages/washaalleni/washaalleni.component';
import { WashaconicaComponent } from './pages/productos/pages/washaconica/washaconica.component';
import { WashaaluminioComponent } from './pages/productos/pages/washaaluminio/washaaluminio.component';
import { WashabronceComponent } from './pages/productos/pages/washabronce/washabronce.component';
import { WashacomercialComponent } from './pages/productos/pages/washacomercial/washacomercial.component';
import { WashaconicaaComponent } from './pages/productos/pages/washaconicaa/washaconicaa.component';
import { WashaestrellacComponent } from './pages/productos/pages/washaestrellac/washaestrellac.component';
import { WashaestrellaeComponent } from './pages/productos/pages/washaestrellae/washaestrellae.component';
import { WashaestrellaeiComponent } from './pages/productos/pages/washaestrellaei/washaestrellaei.component';
import { WashaestrellaeimComponent } from './pages/productos/pages/washaestrellaeim/washaestrellaeim.component';
import { WashaestrellaeinternaComponent } from './pages/productos/pages/washaestrellaeinterna/washaestrellaeinterna.component';
import { WashagalvanizadaComponent } from './pages/productos/pages/washagalvanizada/washagalvanizada.component';
import { WashagalvanizadacComponent } from './pages/productos/pages/washagalvanizadac/washagalvanizadac.component';
import { WashagalvanizadamComponent } from './pages/productos/pages/washagalvanizadam/washagalvanizadam.component';
import { WashainoxidableComponent } from './pages/productos/pages/washainoxidable/washainoxidable.component';
import { Washainoxidable316Component } from './pages/productos/pages/washainoxidable316/washainoxidable316.component';
import { WashametricaComponent } from './pages/productos/pages/washametrica/washametrica.component';
import { WashatropicalizadaComponent } from './pages/productos/pages/washatropicalizada/washatropicalizada.component';
import { Hexagonalg5Component } from './pages/productos/pages/hexagonalg5/hexagonalg5.component';
import { Hexagonalg5gComponent } from './pages/productos/pages/hexagonalg5g/hexagonalg5g.component';
import { FrecirComponent } from './pages/productos/pages/frecir/frecir.component';
import { FrecirorComponent } from './pages/productos/pages/freciror/freciror.component';
import { FrecobComponent } from './pages/productos/pages/frecob/frecob.component';
import { FrecobdesComponent } from './pages/productos/pages/frecobdes/frecobdes.component';
import { FrecuComponent } from './pages/productos/pages/frecu/frecu.component';
import { FrecarComponent } from './pages/productos/pages/frecar/frecar.component';
import { AcecorComponent } from './pages/productos/pages/acecor/acecor.component';
import { AbaiComponent } from './pages/productos/pages/abai/abai.component';
import { AeciComponent } from './pages/productos/pages/aeci/aeci.component';
import { AgrcComponent } from './pages/productos/pages/agrc/agrc.component';
import { MtexcencComponent } from './pages/productos/pages/mtexcenc/mtexcenc.component';
import { Ma188Component } from './pages/productos/pages/ma188/ma188.component';
import { BrcenComponent } from './pages/productos/pages/brcen/brcen.component';
import { Burilc6dComponent } from './pages/productos/pages/burilc6d/burilc6d.component';
import { Burilcob8Component } from './pages/productos/pages/burilcob8/burilcob8.component';
import { FrecardesComponent } from './pages/productos/pages/frecardes/frecardes.component';
import { DtorxComponent } from './pages/productos/pages/dtorx/dtorx.component';
import { LlpsComponent } from './pages/productos/pages/llps/llps.component';
import { MapiComponent } from './pages/productos/pages/mapi/mapi.component';
import { AtgComponent } from './pages/productos/pages/atg/atg.component';
import { TacmeComponent } from './pages/productos/pages/tacme/tacme.component';
import { Lppb188Component } from './pages/productos/pages/lppb188/lppb188.component';
import { Lh4rpaComponent } from './pages/productos/pages/lh4rpa/lh4rpa.component';
import { MmcComponent } from './pages/productos/pages/mmc/mmc.component';
import { CarreraEspComponent } from './pages/carrera-esp/carrera-esp.component';
import { PeabaecComponent } from './pages/productos/pages/peabaec/peabaec.component';
import { AtdtComponent } from './pages/productos/pages/atdt/atdt.component';
import { AtdzComponent } from './pages/productos/pages/atdz/atdz.component';
import { LplsComponent } from './pages/productos/pages/lpls/lpls.component';
import { PuntacComponent } from './pages/productos/pages/puntac/puntac.component';
import { MagComponent } from './pages/productos/pages/mag/mag.component';
import { LhpbctComponent } from './pages/productos/pages/lhpbct/lhpbct.component';



@NgModule({
    declarations: [
        AppComponent,
        MenuComponent,
        ChatMessageComponent,
        SucursalesComponent,
        ContactenosComponent,
        ProductosComponent,
        NavbarMenuComponent,
        InicioComponent,
        LayoutComponent,
        //ChatInutComponent,
        QuienesSomosComponent,
        AllproductsComponent,
        BuscaRoscaComponent,
        PegamentosComponent,
        AllenComponent,
        TuercasComponent,
        WashasComponent,
        RoldanasComponent,
        HerramientasComponent,
        ResortesComponent,
        HexagonalmComponent,
        AllenmComponent,
        RoldanasmComponent,
        WashasmComponent,
        TuercasmComponent,
        BuscaRoscamComponent,
        SegurosComponent,
        PinesComponent,
        EspecialidadesComponent,
        AllComponent,
        ServiciosComponent,
        NtiendaComponent,
        VideosComponent,
        NproductoComponent,
        CarreraComponent,
        EspecialedadesmComponent,
        AutomotrizmComponent,
        DialogoComponent,
        DesarmadormComponent,
        DesarmadorComponent,
        FijacionComponent,
        AutomotrizComponent,
        TorxComponent,
        PaginacionComponent,
        BuscarComponent,
        AllenpacheiComponent,
        AllenpacherComponent,
        AllenplanoComponent,
        AllencComponent,
        Allenc12Component,
        Allencrc12Component,
        Allenc8Component,
        Allencrc8Component,
        AllenciComponent,
        AllencrciComponent,
        AllencfComponent,
        Allencf12Component,
        Allencf8Component,
        AllencfiComponent,
        AllenchComponent,
        AllenchfComponent,
        AllenmpComponent,
        AnclajecComponent,
        AnclajegComponent,
        AnclajeiComponent,
        AnilloajusteComponent,
        BarragcComponent,
        Barrab7Component,
        BarragComponent,
        BarraiComponent,
        BarramgComponent,
        Barram10Component,
        Barram8Component,
        BarramiComponent,
        BernierComponent,
        BerniereComponent,
        BirloComponent,
        AllencbsComponent,
        BrocalComponent,
        BrocalrComponent,
        BrocacComponent,
        BrocarComponent,
        BrocarrComponent,
        BrocarcComponent,
        BrocaUComponent,
        BrocacoComponent,
        CabezamariposaComponent,
        CabezaquesoComponent,
        CabezaquesoiComponent,
        Allenplano8Component,
        AllenplanoaComponent,
        CabezaplanaComponent,
        CabezaplanaBComponent,
        CabezaplanapComponent,
        CabezaplanapiComponent,
        AllenplanoinoxComponent,
        CabezaplanaifComponent,
        CabezaredondaComponent,
        CabezaredondabComponent,
        CabezaredondaiComponent,
        CabezaredondaifComponent,
        CajaplasticaComponent,
        CalibradorroscaComponent,
        CapiruchoComponent,
        CapiruchoiComponent,
        CastigadorallenComponent,
        CastigadoralleniComponent,
        CastigadorallenfComponent,
        CementooaComponent,
        CementoopaComponent,
        CementoopbComponent,
        CementoopbaComponent,
        CementopasqComponent,
        CementopalaComponent,
        ChavetaComponent,
        ChavetamComponent,
        ChavetamiComponent,
        ChuchocablComponent,
        ChuchocabpComponent,
        CincelesComponent,
        ConicoaComponent,
        ConoaComponent,
        ConomComponent,
        CopaesComponent,
        CopapbComponent,
        CunacComponent,
        CunaiComponent,
        CunamComponent,
        CunamlComponent,
        EmpaquenComponent,
        EmpaqueniComponent,
        EmpaquepComponent,
        Esparragoa2Component,
        Esparragoa4Component,
        Esparragoa8Component,
        EsparragoasComponent,
        EsparragoasiComponent,
        EsparragoasfComponent,
        EsparragoasoComponent,
        EsparragoclComponent,
        EsparragocaneroComponent,
        EsparragocsComponent,
        EsparragocsfComponent,
        EsparragoceComponent,
        EsparragocfComponent,
        EsparragodpComponent,
        EsparragodpfoComponent,
        EsparragodpooComponent,
        EsparragoexComponent,
        EsparragoexasComponent,
        EsparragoexasiComponent,
        EsparragoexdComponent,
        EsparragoexdpdComponent,
        EsparragoexdpdaComponent,
        EsparragoexdpiComponent,
        EsparragoexdpsiComponent,
        EsparragoexsdComponent,
        EsparragodpffComponent,
        EsparragoexsmdComponent,
        EsparragomdpfoComponent,
        EsparragomreComponent,
        EsparragomsmComponent,
        EsparragosmComponent,
        EsparragoasexComponent,
        EstufacpComponent,
        Estufacp12Component,
        EstufacprfComponent,
        EstufarComponent,
        EstufarcComponent,
        EstufarnComponent,
        EstufarrfComponent,
        ExtractorameComponent,
        ExtrctoruComponent,
        FerruleameComponent,
        FijadorespComponent,
        FijadormrComponent,
        FijadortpComponent,
        GrapasComponent,
        GrapascuadradasComponent,
        GraseramComponent,
        GraseramiComponent,
        GraseramreComponent,
        GraseramreiComponent,
        GraserarcComponent,
        GraserarciComponent,
        GraserarfComponent,
        GraserarfiComponent,
        GrifosaComponent,
        GrifosmComponent,
        HerramientainsertoComponent,
        HerramientamultiComponent,
        HerramientapiComponent,
        HexagonalagricolaComponent,
        Hexagonalc10Component,
        Hexagonalc10rcComponent,
        Hexagonalc4Component,
        Hexagonalc8Component,
        Hexagonalc8rcComponent,
        HexagonalaluminioComponent,
        HexagonalbronceComponent,
        HexagonalplasticoComponent,
        HexagonalgComponent,
        HexagonalgcComponent,
        Hexagonalg5cComponent,
        HexagonalgrcComponent,
        Hexagonalg2rcComponent,
        Hexagonalg2rcgComponent,
        Hexagonalg5comerComponent,
        Hexagonalg5rcgComponent,
        Hexagonalg8cComponent,
        Hexagonalg8ptComponent,
        HexagonaliComponent,
        Hexagonalinox316Component,
        Hexagonalinoxa4Component,
        Hexagonalinoxa4rcComponent,
        HexagonalinoxrcComponent,
        HexagonalinoxrfComponent,
        HexagonalptComponent,
        HexagonalrceComponent,
        HexagonalreComponent,
        HexagonalrecpComponent,
        HexagonalrfcpComponent,
        Hexagonalrfc10Component,
        Hexagonalrfc8Component,
        Hexagonalrfg5Component,
        Hexagonalrfg5cComponent,
        Hexagonalrfg8Component,
        HexagonalringlesaComponent,
        HexagonalrizquierdaComponent,
        Hexagonalrpfc10Component,
        HexagonaltaponComponent,
        HexagonaltropicalizadoComponent,
        Hexagonaltropicalizadog5Component,
        HexagonaltropicalizadorcComponent,
        HitchpinComponent,
        InsertoamecoilComponent,
        InsertoautorosComponent,
        JuegoextractoresComponent,
        JuegoseguroseComponent,
        JuegosegurosiComponent,
        JuegosegurosieComponent,
        KitamecoilComponent,
        KitamecoilexComponent,
        KitamecoilmComponent,
        LlaveesparragoseguridadComponent,
        LaminaconicoComponent,
        LaminahexagonalComponent,
        LaminahexagonalinoxComponent,
        LaminahexagonalcastigaderaComponent,
        LaminahexagonalconcretoComponent,
        LaminahexagonalpbComponent,
        LaminahexagonalpbcComponent,
        LaminahexagonalpbiComponent,
        LaminahexagonalpbpComponent,
        LaminahexagonalspComponent,
        LaminaplanacinoxComponent,
        LaminaplanapcromadoComponent,
        LaminaplanapinoxComponent,
        LaminaplanapnegroComponent,
        LaminaplanappbComponent,
        LaminaplanaprComponent,
        LaminaplanaptgComponent,
        LaminaplanocComponent,
        LaminaplanopComponent,
        LaminaredondapcComponent,
        LaminaredondapnComponent,
        LaminaredondaqpbComponent,
        LaminaredondocComponent,
        LaminaredondociComponent,
        LaminaredondocombinadoComponent,
        LaminaredondopComponent,
        LaminaredondopiComponent,
        LaminaredondoppbComponent,
        LaminaredondoppbiComponent,
        LaminaredondotgComponent,
        LiquidoantisobadoComponent,
        LlaveallenComponent,
        LlaveallenseguridadComponent,
        LlaveallenesfericaComponent,
        LlavetorxComponent,
        LlavecolaComponent,
        LubricantealimenticioComponent,
        LubricantegeneralComponent,
        LubricanteinoxidableComponent,
        LubricantemarinoComponent,
        MachuelobspComponent,
        MachuelonptComponent,
        Machuelomecanico15Component,
        Machuelomecanico35Component,
        MachuelometricoexComponent,
        MachuelopinoxComponent,
        MachueloamecoilComponent,
        MachuelosComponent,
        ManeralmachueloComponent,
        ManeraltarrajaComponent,
        MaquinaplanaComponent,
        MaquinaredondaComponent,
        MaquinaredondabComponent,
        MueladelanteraComponent,
        MuelatraseraComponent,
        OringbunanComponent,
        OringvitonComponent,
        PinconicoComponent,
        PindowelComponent,
        PintensionComponent,
        PintensionamericanoComponent,
        PintensioninoxidableComponent,
        PinzasegurosComponent,
        ProtectorComponent,
        Punta1Component,
        Punta3Component,
        RemachealuminioComponent,
        RemachehierroComponent,
        RemachepopComponent,
        Punta2Component,
        RemachepopcbComponent,
        RemachepopcnComponent,
        RemachepopctComponent,
        RemachepopaaComponent,
        RemachepopinoxidableComponent,
        ResorteconpataComponent,
        ResortecompresionComponent,
        ResorteestirarComponent,
        ResorteslComponent,
        RoldanabiseladaComponent,
        RoldanacnaturalComponent,
        RoldanacomercialComponent,
        RoldanaconcavaComponent,
        Roldanaajuste01Component,
        Roldanaajuste03Component,
        Roldanaajuste05Component,
        Roldanaajuste1Component,
        RoldanaaluminioComponent,
        RoldanaaluminiosellarComponent,
        Roldanaanilloinox316Component,
        RoldanacobreComponent,
        RoldanalujoComponent,
        RoldanaplasticoComponent,
        Roldanaseguridad1Component,
        Roldanaseguridad2Component,
        Roldanaseguridad4Component,
        Roldanaseguridad5Component,
        Roldanaseguridad6Component,
        RoldanatapiceriaComponent,
        RoldanaestructuralComponent,
        RoldanafenderComponent,
        RoldanafenderinoxComponent,
        RoldanagalvanizadaComponent,
        RoldanagalvanizadacalienteComponent,
        RoldanagalvanizadaanilloComponent,
        RoldanagruesasujecionComponent,
        RoldanainoxidableComponent,
        Roldanainoxidable316Component,
        Roldanainoxidablea4Component,
        RoldanainoxidableanilloComponent,
        RoldananaturalComponent,
        RoldanaonduladaComponent,
        RoldanaonduladatropicalizadaComponent,
        RoldanatropicalizadaComponent,
        RoldanabronceComponent,
        SeguroaranaComponent,
        SeguroclipComponent,
        SeguroclipinoxComponent,
        SegurosujecionranuraComponent,
        SegurosujecionanilloComponent,
        SeguroexternoComponent,
        SeguroexternoinoxComponent,
        SegurointernoComponent,
        SegurointernoinoxComponent,
        SegurocajaComponent,
        SierraComponent,
        SilicongrisComponent,
        SiliconnegroComponent,
        SiliconrojaComponent,
        SilicontransparenteComponent,
        SoldadurafrioComponent,
        SoldaduratransparenteComponent,
        SuperglueComponent,
        TablaComponent,
        TablayesoComponent,
        TablayesocornetaComponent,
        TablayesocornetadacrometComponent,
        TablayesocornetadobleroscaComponent,
        TablayesoobleapbComponent,
        TablayesoobleapbaComponent,
        TablayesoplanarecortadaComponent,
        TablayesoredondaComponent,
        TablayesoredondaoComponent,
        TablayesopbpqComponent,
        TablayesopbrComponent,
        TablayesopbComponent,
        TablayesopbgComponent,
        TablayesorpbComponent,
        TablayesorpbgComponent,
        TablayesorrComponent,
        TablayesorrnComponent,
        TablayesorrpbComponent,
        TablayesorrpbnComponent,
        TablayesotComponent,
        TaponallenComponent,
        TaponallenbspComponent,
        TaponallennptComponent,
        TaponhexagonalComponent,
        TarrajahssgComponent,
        TarrajasComponent,
        TarugoexpansionComponent,
        TarugoexpansiondobleComponent,
        TarugofisherComponent,
        TarugofishercomercialComponent,
        TarugoplasticoComponent,
        TeflonliquidoComponent,
        TermometrodigitalComponent,
        TipospaxComponent,
        TipospaxnegroComponent,
        TornilloestufohexagonalComponent,
        TornilloestufohexagonalfinoComponent,
        TornillocuadradoComponent,
        TornilloplanoseguridadComponent,
        TornillocarroceroComponent,
        TornillocarrocerogalvanizadoComponent,
        Tornillocarrocerog5Component,
        TornillocarroceroinoxComponent,
        TornillocentroComponent,
        TornillocentrometricoComponent,
        TornilloelevadorComponent,
        TornilloelevadorpuntasComponent,
        TornilloelevadorgalvanizadoComponent,
        TornilloelevadorinoxComponent,
        TornilloestructuralComponent,
        TornilloestdComponent,
        TornillocangilonComponent,
        TornillomaderaplanoComponent,
        TornillomaderaojoComponent,
        TornillomaderapaComponent,
        TornillomaderarrComponent,
        TornillomaderarpaComponent,
        TornillomuebleacComponent,
        TornillomuebleatpComponent,
        TornillomuebledpComponent,
        TornillomuebledpgComponent,
        Tornillorastrag5Component,
        TornillopdrcComponent,
        TornilloprisionerocuadradoComponent,
        TornillotirabuzonComponent,
        TornillotirabuzoninoxComponent,
        Tornillotorx12ladosComponent,
        Tornillotorx12ladosfinoComponent,
        TorxcilindricogComponent,
        TorxbotonComponent,
        TorxplanoComponent,
        TuercasegurogolpeComponent,
        Tuerca2hComponent,
        TuercaallencrComponent,
        TuercaanclajemComponent,
        TuercaartilleraComponent,
        Tuercab7Component,
        TuercabraComponent,
        TuercabrarComponent,
        TuercaceComponent,
        TuercacedComponent,
        TuercaceiComponent,
        TuercacfdComponent,
        TuercacfiComponent,
        TuercacbreComponent,
        TuercacbrfComponent,
        TuercacseComponent,
        TuercacsfComponent,
        TuercacefComponent,
        TuercacfComponent,
        TuercaclreComponent,
        TuercaclrfComponent,
        TuercacltreComponent,
        TuercacltrfComponent,
        TuercacreComponent,
        TuercacrfComponent,
        TuercacrfiComponent,
        TuercacroComponent,
        TuercacilindricaabComponent,
        TuercacilindricaacrComponent,
        TuercacilindricaalComponent,
        TuercacilindricaastrilladaComponent,
        TuercacuadradaComponent,
        TuercabronceComponent,
        TuercacapiruchoComponent,
        TuercachuchoComponent,
        TuercacapiruchoiComponent,
        TuercachuchotuboComponent,
        TuercachuchoiComponent,
        TuercachucholujoComponent,
        TuercachucholujoiComponent,
        TuercachucholujotrComponent,
        TuercacoplaComponent,
        TuercacoplaiComponent,
        TuercacoronaComponent,
        TuercacoronaeComponent,
        TuercacoronafComponent,
        TuercacoronaoComponent,
        TuercainsercionComponent,
        TuercainsercionmComponent,
        TuercalanaComponent,
        TuercamariposaComponent,
        TuercamariposainoxComponent,
        TuercamariposafComponent,
        TuercaplasticaComponent,
        TuercaseguridadrComponent,
        TuercaseguridadmreComponent,
        TuercaseguridadmrfComponent,
        TuercaseguridadpreComponent,
        TuercaseguridadprfComponent,
        TuercaseguridadcrfComponent,
        TuercaseguridadgaComponent,
        TuercaseguridadgaaComponent,
        TuercaseguridadgrComponent,
        TuercaseguridadgcComponent,
        TuercaseguridadgiComponent,
        TuercaseguridadgmComponent,
        TuercaseguridadgoComponent,
        TuercaseguridadmetalComponent,
        TuercaseguridadplasticaComponent,
        TuercaseguridadplasticainoxComponent,
        TuercaseguridadplasticafpComponent,
        TuercaseguridadgpComponent,
        TuercaseguridadgprComponent,
        TuercataponComponent,
        TuercataponinoxComponent,
        TuercahexagonalComponent,
        TuercahexagonalcaneradComponent,
        TuercahexagonalcaneraiComponent,
        TuercahexagonalranuraComponent,
        TuercahexagonalranurafComponent,
        TuercahexagonalcmComponent,
        TuercahexagonalaluminioComponent,
        TuercahexagonalalbronceComponent,
        TuercahexagonalbroncefComponent,
        TuercahexagonalcoronaeComponent,
        TuercahexagonalestructuralComponent,
        TuercahexagonalgalvanizadaComponent,
        Tuercahexagonalgalvanizadac4Component,
        TuercahexagonalgalvanizadacalienteComponent,
        Tuercahexagonalgalvanizadag2Component,
        Tuercahexagonalgalvanizadag5Component,
        Tuercahexagonalgrado2Component,
        Tuercahexagonalgrado5Component,
        TuercahexagonalinglesaComponent,
        TuercahexagonalinoxidableComponent,
        Tuercahexagonalinoxidable316Component,
        Tuercahexagonalinoxidablea4Component,
        TuercahexagonalinoxidablerfComponent,
        TuercahexagonalizquierdaComponent,
        TuercahexagonalizquierdarfComponent,
        TuercahexagonalpacheComponent,
        TuercahexagonalpacheinoxComponent,
        TuercahexagonalreComponent,
        TuercahexagonalrepComponent,
        TuercahexagonalrfComponent,
        TuercahexagonalrfgComponent,
        TuercahexagonalrfiComponent,
        TuercahexagonalrfpComponent,
        TuercahexagonaltropicalizadaComponent,
        TuercamuescaComponent,
        TuercapacheComponent,
        TuercapachefiComponent,
        TuercaseguridadmunonComponent,
        TuercataponallencComponent,
        TuercataponallencrComponent,
        TuercatransversalComponent,
        TuercatractorComponent,
        WashaComponent,
        WashaallenComponent,
        WashaalleniComponent,
        WashaconicaComponent,
        WashaaluminioComponent,
        WashabronceComponent,
        WashacomercialComponent,
        WashaconicaaComponent,
        WashaestrellacComponent,
        WashaestrellaeComponent,
        WashaestrellaeiComponent,
        WashaestrellaeimComponent,
        WashaestrellaeinternaComponent,
        WashagalvanizadaComponent,
        WashagalvanizadacComponent,
        WashagalvanizadamComponent,
        WashainoxidableComponent,
        Washainoxidable316Component,
        WashametricaComponent,
        WashatropicalizadaComponent,
        Hexagonalg5Component,
        Hexagonalg5gComponent,
        FrecirComponent,
        FrecirorComponent,
        FrecobComponent,
        FrecobdesComponent,
        FrecuComponent,
        FrecarComponent,
        AcecorComponent,
        AbaiComponent,
        AeciComponent,
        AgrcComponent,
        MtexcencComponent,
        Ma188Component,
        BrcenComponent,
        Burilc6dComponent,
        Burilcob8Component,
        FrecardesComponent,
        DtorxComponent,
        LlpsComponent,
        MapiComponent,
        AtgComponent,
        TacmeComponent,
        Lppb188Component,
        Lh4rpaComponent,
        MmcComponent,
        CarreraEspComponent,
        PeabaecComponent,
        AtdtComponent,
        AtdzComponent,
        LplsComponent,
        PuntacComponent,
        MagComponent,
        LhpbctComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        AlertModule.forRoot(),
        ReactiveFormsModule,
        FormsModule,
        NgxLoadingModule,
        //AngularFireModule.initializeApp(environment),
        //AngularFirestoreModule,
        //AngularFireStorageModule,
        //AngularFireAuthModule,
        HttpClientModule,
        NoopAnimationsModule,
        MatSliderModule,
        MatDialogModule,
        MatButtonModule
    ],
    providers: [
        AlertService,
        AuthService,
        AuthGuard,
        //ChatroomService,
        MessageService,
        { provide: LocationStrategy, useClass: HashLocationStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
