import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tuercacltre',
  templateUrl: './tuercacltre.component.html',
  styleUrls: ['./tuercacltre.component.scss']
})
export class TuercacltreComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) {
    
    this.title.setTitle('Tuerca Chucho Lujo Tubo Rosca Extrafina  - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'tuerca chucho lujo tubo rosca extrafina'}     
    ])

  }

   mostrar(){
    this.dialogo.mostrarDialogo();
  }


  ngOnInit(): void {
  }

}
