import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-allench',
  templateUrl: './allench.component.html',
  styleUrls: ['./allench.component.scss']
})
export class AllenchComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 
   
    this.title.setTitle('Tornillo Allen Con Hombro Clase 12.9 - La Casa Del Tornillo S.A.');

    this.meta.addTags([
      {name: 'keywords', content: 'tornillo allen con hombro clase 12.9'}
    ]);
  }
 
  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
