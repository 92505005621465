import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-segurointernoinox',
  templateUrl: './segurointernoinox.component.html',
  styleUrls: ['./segurointernoinox.component.scss']
})
export class SegurointernoinoxComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) {

    this.title.setTitle('Seguro Interno Para Agujero Inoxidable - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'seguro interno para agujero inoxidable'}     
    ]);

   }

   mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
