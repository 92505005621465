import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tuercahexagonalizquierdarf',
  templateUrl: './tuercahexagonalizquierdarf.component.html',
  styleUrls: ['./tuercahexagonalizquierdarf.component.scss']
})
export class TuercahexagonalizquierdarfComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Tuerca Hexagonal Izquierda Rosca Fina - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'tuerca hexagonal izquierda rosca fina'}     
    ])

  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }


  ngOnInit(): void {
  }

}
