import { AlertService } from './services/alert.service'
import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { Alert } from './classes/alert';
import { Subscription } from 'rxjs';
import { LoadingService } from './services/loading.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

declare var gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public alerts: Array<Alert> = [];
  public loading: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private alertService: AlertService,
    private loadingService: LoadingService,
    private router: Router
  ) {

    const navendevents$ = this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd)
    );

    navendevents$.subscribe((event: NavigationEnd) => {
      if(isPlatformBrowser(this.platformId)){
        gtag('config', 'UA-47714970-1', {
          'page_path' : event.urlAfterRedirects
        });
      }
    })

  }

  ngOnInit(){
    if(isPlatformBrowser(this.platformId)){
    this.subscriptions.push (
      this.alertService.alerts.subscribe(alert => {
      this.alerts.push(alert);
      })
    )

    this.subscriptions.push(
      this.loadingService.isloading.subscribe(isLoading => {
        this.loading = isLoading; 
      })
    )
    }
  }

  ngOnDestroy() {
    if(isPlatformBrowser(this.platformId)){
    this.subscriptions.forEach(sub => sub.unsubscribe());
    }
  }
}
