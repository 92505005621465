import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-laminaconico',
  templateUrl: './laminaconico.component.html',
  styleUrls: ['./laminaconico.component.scss']
})
export class LaminaconicoComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Tornillo Lamina Conico - La Casa Del Tornillo S.A.');
   
    this.meta.addTags([
      {name: 'keywords', content: 'tornillo lamina conico'}
    ]);

  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
