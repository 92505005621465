import { Component, OnInit } from '@angular/core';
import { MessageService } from './../../services/message.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-ntienda',
  templateUrl: './ntienda.component.html',
  styleUrls: ['./ntienda.component.scss']
})
export class NtiendaComponent implements OnInit {



  coment: string;
  verficoment: string;
  list1: any;
  list2: any;
  dep: string;
  pais: string;
  asunto: string;
  comentario: boolean;
  form: boolean;

  constructor(
    private meta: Meta,
    private title: Title,
    public _MessageService: MessageService
  ) {


    this.title.setTitle('Nueva Tienda - La Casa Del Tornillo S.A.');

    this.meta.addTags([
      {name: 'keywords', content: 'la casa del tornillo tienda nueva'}
    ]);

    this.list1 = "Selecciona Departamento";
    this.list2 = "Selecciona País";
    this.comentario = false;
    this.form = true;
    this.asunto = "err";
    this.verficoment = "err";
  }

  submit1(){
    //console.log((this.coment);

    if (this.list1 != "Selecciona Departamento"){
    switch(this.list1){
      case '1':
        this.dep = "Alta Verapaz";
        break;

      case '2':
        this.dep = "Baja Verapaz";
        break;

      case '3':
        this.dep = "Chimaltenango";
        break;

      case '4':
        this.dep = "Chiquimula";
        break;

      case '5':
        this.dep = "El Progreso";
        break;

      case '6':
        this.dep = "Escuintla";
        break;

      case '7':
        this.dep = "Guatemala";
        break;

      case '8':
        this.dep = "Huhuetenango";
        break;

      case '9':
        this.dep = "Izabal";
        break;

      case '10':
        this.dep = "Jalapa";
        break;

      case '11':
        this.dep = "Jutiapa";
        break;

      case '12':
        this.dep = "Petén";
        break;

      case '13':
        this.dep = "Quetzaltenango";
        break;

      case '14':
        this.dep = "Quiché";
        break;

      case '15':
        this.dep = "Retalhuleu";
        break;

      case '16':
        this.dep = "Sacatepequez";
        break;

      case '17':
        this.dep = "San Marcos";
        break;

      case '18':
        this.dep = "Santa Rosa";
        break;

      case '19':
        this.dep = "Sololá";
        break;

      case '20':
        this.dep = "Suchitepéquez";
        break;

      case '21':
        this.dep = "Totonicapán";
        break;

      case '22':
        this.dep = "Zacapa";
        break;
    }
     this.asunto = this.dep;
    //console.log((this.dep);
    }

    if (this.list2 != "Selecciona País"){
    switch(this.list2){
      case '1':
        this.pais = "Costa Rica";
        break;

      case '2':
        this.pais = "El Salvador";
        break;

      case '3':
        this.pais = "Honduras";
        break;

      case '4':
        this.pais = "México";
        break;

      case '5':
        this.pais = "Nicaragua";
        break;

      case '6':
        this.pais = "Panamá";
        break
    }
      this.asunto = this.pais;
    //console.log((this.pais);
    }

    if (this.asunto=="err" || this.coment==null){
      alert("Debe seleccionar uno de los campos de País o Departamento y debe llenar el campo de comentario, Gracias!!");
    }else{

    const mensaje = this.coment;
    const asunt = this.asunto;
    const form = {
      mensaje: mensaje,
      asunto: asunt,
      email: "informacion@gmail.com"
    }
    const email = "informacion@casator.com";
    this._MessageService.sendMessage(form).subscribe(() => {
      'success'
    });

    this.form = false;
    this.comentario = true;
    }
  }

  ngOnInit(): void {
  }

}
