import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-allenchf',
  templateUrl: './allenchf.component.html',
  styleUrls: ['./allenchf.component.scss']
})
export class AllenchfComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 
    
    this.title.setTitle('Tornillo Allen Con Hombro Rosca Fina - La Casa Del Tornillo S.A.');

    this.meta.addTags([
      {name: 'keywords', content: 'tornillo allen con hombro rosca fina'}
    ]);
  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
