import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-broca-u',
  templateUrl: './broca-u.component.html',
  styleUrls: ['./broca-u.component.scss']
})
export class BrocaUComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) {

    this.title.setTitle('Broca Universal - La Casa Del Tornillo S.A.');
    
    this.meta.addTags([
      {name: 'keywords', content: 'broca universal'}
    ]);
   }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
