import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-seguroarana',
  templateUrl: './seguroarana.component.html',
  styleUrls: ['./seguroarana.component.scss']
})
export class SeguroaranaComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Seguro Araña - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'seguro araña'}     
    ]);

  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
