import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-cajaplastica',
  templateUrl: './cajaplastica.component.html',
  styleUrls: ['./cajaplastica.component.scss']
})
export class CajaplasticaComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Caja Plastica - La Casa Del Tornillo S.A.');
  
    this.meta.addTags([
      {name: 'keywords', content: 'caja plastica'}
    ]);
  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }
 
  ngOnInit(): void {
  }

}
