import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-washacomercial',
  templateUrl: './washacomercial.component.html',
  styleUrls: ['./washacomercial.component.scss']
})
export class WashacomercialComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Washa De Presion Comercial - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'washa de presion comercial'}     
    ])

  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
