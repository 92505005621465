import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.scss']
})
export class ServiciosComponent implements OnInit {


  constructor(
    public chat : MenuComponent,
    private meta: Meta, 
    private title: Title
  ) {
    
    this.title.setTitle('Servicios - La Casa Del Tornillo S.A.');
   
    this.meta.addTags([
      {name: 'keywords', content: 'servicios la casa del tornillo'}
    ]);

  }

  ngOnInit(): void {

  }
  
}
