import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-roldanagruesasujecion',
  templateUrl: './roldanagruesasujecion.component.html',
  styleUrls: ['./roldanagruesasujecion.component.scss']
})
export class RoldanagruesasujecionComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Roldana Gruesa de Sujecion - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'Roldana gruesa de sujecion'}     
    ]);

  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
