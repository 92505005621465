import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-brocalr',
  templateUrl: './brocalr.component.html',
  styleUrls: ['./brocalr.component.scss']
})
export class BrocalrComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) {

    this.title.setTitle('Broca Laminada Con Raiz 1/2 - La Casa Del Tornillo S.A.');
  
    this.meta.addTags([
      {name: 'keywords', content: 'broca laminada con raiz 1/2'}
    ]);
   }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
