import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { ProductosComponent } from './../../productos.component';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-roldanas',
  templateUrl: './roldanas.component.html',
  styleUrls: ['./roldanas.component.scss']
})
export class RoldanasComponent implements OnInit {

  public productosnombre = [];
  numero: any;
  a: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public dialogo : ProductosComponent
  ) { 
    this.numero = 0;
  }

  Mostrar(){
    this.dialogo.mostrarDialogo();
  }

  prueba(){
    if(isPlatformBrowser(this.platformId)){
    const productos = document.getElementsByName("roldana");
    const lista = document.getElementById("todoroldana");
    const tamaño = productos.length;
    productos.forEach(nprod => {

      this.productosnombre[this.numero] = nprod.textContent;
      this.numero = this.numero + 1;
    });
    this.productosnombre.sort();
    for (this.a=0; this.a<=tamaño; this.a++){
      productos.forEach(element => {
        const nodo = element.textContent.indexOf(this.productosnombre[this.a]);
        if (nodo != -1){
          lista.appendChild(element);
        }
      });
    }
  } 
  }

  ngAfterViewInit(): void {
    this.prueba();
  }

  ngOnInit(): void {
  }

}
