import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tarrajahssg',
  templateUrl: './tarrajahssg.component.html',
  styleUrls: ['./tarrajahssg.component.scss']
})
export class TarrajahssgComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) {

    this.title.setTitle('Tarraja HSS-G - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'tarraja hss-g'}     
    ])

   }


   mostrar(){
    this.dialogo.mostrarDialogo();
  }


  ngOnInit(): void {
  }

}
