import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-allencf12',
  templateUrl: './allencf12.component.html',
  styleUrls: ['./allencf12.component.scss']
})
export class Allencf12Component implements OnInit {

  constructor( public dialogo: MenuComponent, private meta: Meta, private title: Title) { 
    
    this.title.setTitle('Tornillo Allen Cilindrico Rosca Fina clase 12.9 - La Casa Del Tornillo S.A.');

    this.meta.addTags([
      {name: 'keywords', content: 'tornillo allen cilindrico rosca fina clase 12.9'}
    ]);
  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
