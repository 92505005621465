import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tuercacrfi',
  templateUrl: './tuercacrfi.component.html',
  styleUrls: ['./tuercacrfi.component.scss']
})
export class TuercacrfiComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) {

    this.title.setTitle('Tuerca Chucho Rosca Fina Izquierdo  - La Casa Del Tornillo S.A.');

    this.meta.addTags([
    {name: 'keywords', content: 'tuerca chucho rosca fina izquierdo'}     
    ])

   }

   mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
