import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PrincipalService {

  constructor(private _http: HttpClient) { }

  getimg(){
    return this._http.get('https://www.casator.com/admincasator/services/principal/imgpaginicial.php')
  }
}
