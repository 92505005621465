import { Component, OnInit } from '@angular/core';
import { MenuComponent } from './../../../menu/menu.component';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-copapb',
  templateUrl: './copapb.component.html',
  styleUrls: ['./copapb.component.scss']
})
export class CopapbComponent implements OnInit {

  constructor(public dialogo: MenuComponent, private meta: Meta, private title: Title) { 

    this.title.setTitle('Copa Hexagonal Para Barreno - La Casa Del Tornillo S.A.');
    
    this.meta.addTags([
      {name: 'keywords', content: 'copa hexagonal para barreno'}
    ]);
  }

  mostrar(){
    this.dialogo.mostrarDialogo();
  }

  ngOnInit(): void {
  }

}
